import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent (){

    var subheading = classNames (classes.subheadingcontent)
    var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    return(

        <div>

            <br/>
            <div  className={classes.divcontentblock}>


                <div className = {contentClass}>
                    <p className = {titleClass} data-attribute="title">Psychotherapy</p>
                    <br/>
                    <p>Psychotherapy involves therapy which addresses the problems, aims to develop awareness, and makes long-term positive changes. 
                        It is different from other forms of therapy, such as psychiatry and psychology.</p>
                    <br/><br/>
                    <p>The service of a trained psychotherapist can profoundly impact individuals and help them navigate challenging life situations, 
                        such as divorce, the demise of loved ones, work/school stress or career change, or job loss. 
                        Psychotherapy provides insights into how to manage various mental health issues. 
                        The sessions empower people to lead healthy and fulfilling lives.</p>
                
                    <br/><br/>
                    <p className={subheading}> What does psychotherapy provide?</p>
                    <br/>
                    <p className={subtitle}> See your thoughts from a different perspective</p>
                    <p> Psychotherapy allows you to see your problems from a new perspective. 
                        We may have beliefs that inhibit us from taking action—sharing your views with another person may give you a new way of thinking about your problems. 
                        Sometimes, this provides fresh insight that the worries or anxieties are not founded on the actual cause.</p>
                    <br/>
                    <p className={subtitle}>Empathy</p>
                    <p>Empathy is healing, walking along the same path and being non-judgemental. 
                        Empathy helps us to feel understood and validated. This is different from sympathy. Through psychotherapy, we can experience empathy in a relationship with a psychotherapist and evaluate our options to work this out with a trained professional.</p>
                    <br/>
                    <p className={subtitle}> Cathartic Experience</p>
                    <p>Letting out the feelings you have bottled inside can be a cathartic experience. 
                        Many people find that they are relieved when their thoughts and emotions pent up are let go. 
                        These reduce the burden off their shoulder, which they have been holding onto for a long time. 
                        This resulted in a cathartic experience.</p>
                    <br/>
                    <p className={subtitle}> Better Self-Awareness</p>
                    <p>Examining your emotions with a trained professional help you see yourself from the outside, 
                        resulting in a sense of higher awareness. As we processed this during the sessions, 
                        this can bring immense benefits that will enhance self-esteem, confidence and improve inner peace within yourself.</p>
                    <br/>
                    <p className={subtitle}>Guidance in Finding Purpose</p>
                    <p>This question is unique to every individual. Some may have lost their self-identity in an existential crisis. Through exploration of beliefs, 
                        values and interests, psychotherapy may help you rediscover your meaning and purpose in life.</p>
                    <br/>
                    <p className={subtitle}>Provide a Safe Environment for Vent Issues </p>
                    <p>What better way to vent your issues than psychotherapy (or counselling)? 
                        Sometimes we want to let go of things bothering us, but we fear what others think of us. 
                        Psychotherapy provides a confidential and non-judgemental avenue to release your pent-up emotions in a safe environment. 
                        Your friends, relatives, employers or anyone will not know the discussions in the sessions. 
                        Confidentiality builds a sacred bond of trust between you and the psychotherapist.</p>
                    
                    <br/><br/><br/><br/>    

                    
    
                </div>
            </div>
        </div>
    )
}

export default function CounsellingReason(){



    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}

