import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import TextTime from '../../../containers/TransitText/texttransition'

import CallMe from '../../CallMe/callme'

function pageContent (){

    var testimonialText = classNames(classes.testimonials ,classes.italic)
    var subheading = classNames (classes.bioheading)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    var signature = classNames (classes.signature)
    var testimoniallegend = classNames (classes.legendtext)



    return(

        <div className={classes.divcontentblock}>

            <div className = {contentClass}>
                <br/><br/><br/>
                


                <div>
                    <p className = {titleClass} data-attribute="title">He did not start out as a psychotherapist</p>
                    <br/>


                    <p>Matt stayed in the UK for a few years before moving back to his home country Singapore. 
                        He has worked in the US and Japan but relocated to this beautiful island. In his past gigs, 
                        he worked in several startups overseas and remotely, 
                        allowing him to experience multiple cultures and LGBTQ and discover that everyone is connected yet has distinct views.
</p>
                
                    <br/><br/>
                    <p className={subheading}>He is an active volunteer</p>
                    <p>Matt has volunteered for more than two decades. He started as a volunteer tutor for young children and delinquent youth.
                         He is also an elderly befriender, paying regular visits to check their emotional levels. 
                        He has also been a school counsellor volunteer, which helps the students in their career guidance.</p>
                    <p>Ever since helping out regularly in a suicide crisis centre in 2008, he started to be interested in counselling. 
                        He became more aware of residents' mental stresses in their relationship, work, financial, or life transition. 
                        Volunteering in a psychiatric hospital gave him a depth of understanding of the patient's daily challenges. </p>
                    
                    <br/>
                    <p className={subheading}>He is a trained counsellor</p>
                   
                        <p>
                        He studied psychology at the College of Allied Educators as a Postgraduate in Counselling and Psychology and the Master in Counselling (Advanced) at Swinburne University of Technology (Australia), which the <a target ="_blank" href='http://sacsingapore.org/' rel="noreferrer">Singapore Association of Counselling</a> recognizes. 
                        However, he began his formal counselling education at James Cook University a decade ago. Continuous learning and upgrading in psychology and counselling modalities are his main goals to be a better mental health professional, 
                        serving his clients with a broader customized approach. 
                        He is actively collaborating with other counsellors and psychologists to gain better insights into this field.
                        </p>
                    
                    <br/>
                    <p>Matt uses an integrative approach to therapy, drawing on Person-Centered Therapy (PCT), Motivational Interviewing (MI), 
                        Solution Focused Brief Therapy (SFBT), Choice Theory & Reality Therapy (CTRT) together with Cognitive Behavioural Therapy (CBT). He may sometimes include mindfulness as part of the session. Matt is a certified mindfulness coach.</p>
                    <br/>
                    <p>His approach to therapy is to co-create with the client the vision they wish to achieve in a non-judgemental and respectful manner. 
                        Building a deep bond with each client is the key to bringing deeper awareness and effectiveness to each client. </p>  

                    <br/>
                    <p>He teaches mindfulness over the weekend and enjoys jogging regularly at park connector. 
                        He spends quiet time in the library and has an alternative life as a local foodie.</p>      
                    

                    <div>
                        <br/><br/><br/>
                        <p className={subheading}>Testimonials</p>
                        
                        <div className = {testimonialText}>
                            <TextTime/>
                        </div>
                        
                        <br/>
                        <p className={testimoniallegend}>* All names and ages are changed </p>

                    </div>
                    <br/>
                    <div>
                        <p className={signature}>Matt Tay</p>
                        <br/>
                    </div>
                    <div>
                        <br/><br/>
                        <CallMe/>

                    </div>

                    <br/><br/><br/><br/>    

                </div>
            </div>
        </div>
    )

}


export default function bio_counsel1(){


    return (
        <div>
            <BasePageTemplate content={pageContent()}/>
        </div>

    )

}
