import axios from 'axios'

const API_KEY = process.env.REACT_APP_CONTACTFORM_KEY


/*
    swap the functions in nov
*/
function detectWakeUp(){


    return new Promise ((resolve)=>{

        resolve (true)
    })
    
    

}

function detectWakeUp_DISABLE(){

    const api = '/1.0'
    const header = '/therapy'
    const url = process.env.REACT_APP_CONTACTFORM_URL

    return new Promise ((resolve)=>{
        axios.get(url +api+header + '/wakeup' ).then ((info)=>{


            // this.setState({api_wakeup:true})
    
            // return true
            resolve (true)
        }).catch((error)=>{
    
    
            // this.setState({api_wakeup:false})
            // return false
            resolve (false)
        })

    })


    
}

function sendEmail (message, fromaddr,content,subject, successMessage, errorNotStatus200, errorMessage){


    return new Promise ((resolve)=>{

        resolve (false)
    })
    
    
}

function sendEmail_DISABLE (message, fromaddr,content,subject, successMessage, errorNotStatus200, errorMessage){
    const api = '/1.0'
    const header = '/therapy'
    const url = process.env.REACT_APP_CONTACTFORM_URL

    let data = {
        message: message,
        fromaddr : fromaddr,
        content : content,
        subject : subject,
        key : API_KEY
        
    }

    // console.log (data)

    return new Promise ((resolve,reject)=>{

        axios.post (url +api+header + '/sendemailpost', {},{params:data}).then((infor)=>{

            // console.log ("test fake email ")
            // console.log (infor)
            if (infor.data.status!==200){
                // alert (errorNotStatus200)
                reject (false)
            }else{
    
                // alert (successMessage)

                resolve (true)
                // this.setState({refresh: true})
            }
    
            
            //this.setState({refresh: true})
        }).catch ((err)=>{
            // console.log ("ERROR!")
            // console.log (err)
            //alert (errorMessage)
            reject(false)
        })
    })

 
}


export default {detectWakeUp,sendEmail }