import React, {Component} from 'react'
import classes from './page.module.css'
import Home from '../IndividualPage/Home/home'

class Page extends Component{

    render() {

        return (

            <div className = {classes.background} >
                
                <Home/>
            </div>
        )
    }

}

export default Page