// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page_background__xaBCH {\n\n\n    \n}", "",{"version":3,"sources":["webpack://src/components/page/page.module.css"],"names":[],"mappings":"AAAA;;;;AAIA","sourcesContent":[".background {\n\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "page_background__xaBCH"
};
export default ___CSS_LOADER_EXPORT___;
