
import React from "react";
import classes from '../../css/main.module.css'
export default function Copyright (){

    function year (){

        // return "2022"
        return (new Date().getFullYear())

    }
    return (

        <div  data-attribute="copyright">
            <br/>
            <p className={classes.copyrighttext}> Copyright ©{year()} <a href="/">The Talk Therapy</a></p>
            <p className={classes.copyrightminortext}>Created With Love by HiveWorks</p>
        </div>
    )

}