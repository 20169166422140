
import React, { useState} from 'react'
import TextTransition from "react-text-transition";

/*
function transition (n){

    const text = ["HAHAHAHAHA",
                    "Durian"]

    return text[n]

}
*/


export default function TransitionText (){


    const TEXTS= [
        'Matt is a sincere counsellor who helps me to discover my strengths. He give me courage to move on - Janet*, 35*',
        'I decided to seek assistance from Matt when I was addicted to gambling. I was not confidence if this is for me. Matt is patent listener who did not judge me. Over period of time, I got rid of my habit with his help. I manage to rekindle my relationship with my wife - Sam*, 45*',
        'I wish there are are more queer listeners like you - John*, 23*',
        'Matt is a nurturing and his caring counsellor who helps me to rediscover my purpose. He helped me to sequence my thoughts and sort out my feeling. The sessions has been invaluable and I look forward to continue my journey together - Jacqueline*, 28*'

    ];

    const [number, setNumber]       = useState(0);
	const [oldNumber, setOldNumber] = useState(0);
	const [index, setIndex]         = useState(0);

	React.useEffect(() => {
		const intervalId = setInterval(() => {
			setNumber((oNumber) => {
				setOldNumber(oNumber);
				return Math.round(Math.random() * 100000);
			});
			setIndex((i) => i + 1);
		}, 4000);
		return () => clearInterval(intervalId);
	}, []);

    return (
        <div>

            <p><TextTransition
                            text={ TEXTS[index % TEXTS.length] }
                            delay={ 100 }
                            direction={ number > oldNumber ? "up" : "down" }
                            
                            
                    
            /></p>

        </div>
        
    )
}