
var ACT_data = require ('./data/act.json')
var MI_data = require ('./data/mi.json')
var CardType = require ('./cardtype.js')

function readData (cardtype){
    
    var max=9, maxcards ;
    var returnData=[]

    
    if (cardtype===CardType.ACT){

        maxcards = ACT_data.length

        for (var i=0;i<=max; i++){

            returnData.push(ACT_data[getRandomInt(maxcards)])
    
        }

    }else if (cardtype ===CardType.MI){
        maxcards=MI_data.length

        for (var x=0;x<=max; x++){

            returnData.push(MI_data[getRandomInt(maxcards)])
    
        }

    }
    // const max=35
    // console.log (ACT_data.length)
    // console.log (ACT_data[1])
    // console.log ("Random number :" + getRandomInt(max))

   


    return returnData

}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

module.exports.readData=readData