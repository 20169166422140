
import React , {useState,useReducer, useLayoutEffect} from 'react'
import {ReactSVG} from 'react-svg'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/feelingwheel_logo.jpeg'

import IMAGE1 from '../Activities/resource/FeelingWheel.svg'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

const downloadlink="https://u.pcloud.link/publink/show?code=kZC9cwXZcYa80hzGui01uM28iSxu2Flfb77X"

function PageContent (){

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [width, setWidth]   = useState(window.innerWidth);
    const [, setHeight] = useState(window.innerHeight);
    const [ImageWidth, SetImageWidth] = useState (0)
    const [Refresh, SetRefresh] = useState (false)
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        forceUpdate();
    }

    var titleClass = classNames(classes.title , classes.center)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    useLayoutEffect(() => {

        SetRefresh(false)
        
        window.addEventListener("resize", updateDimensions);
        SetSize()

        function SetSize(){

            /*
            if (width < 500){
    
                // console.log ("less than 600")
                SetImageWidth('data-attribute="svg"; display:block; width: 300px; height:300px')
    
            }else if (width < 600){
    
                // console.log ("less than 1000")
                SetImageWidth('data-attribute="svg"; display: block; width: 500px; height:500px')
            }
            else if (width < 1000){
    
                // console.log ("less than 1000")
                SetImageWidth('data-attribute="svg"; display: block; width: 800px; height:800px')
            }else if (width < 1500){
    
                // console.log ("less than 1500")
                SetImageWidth('data-attribute="svg";  display: block; width: 1300px; height:1300px')
            }else {
    
                // console.log ("greater than 1500")
                SetImageWidth('data-attribute="svg"; display: block; width: 1800px; height:1800px')
            }
            */
            var tempWidth = width-200
            SetImageWidth('data-attribute="svg"; display: block; margin: auto; width: ' + tempWidth + 'px; height:' + tempWidth + 'px')

            SetRefresh(true)
            

            
        }

        return () => window.removeEventListener("resize", updateDimensions);


    }, [width]);

    return (


            <div  className={classes.divcontentblock}>


                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source <a href='https://unsplash.com/photos/auEPahZjT40' target='__blank' rel="noreferrer">Tenyard</a></p>
                    <p className={titleClass} data-attribute="title"> Emotion Wheel</p>
                    <p>Please adhere to additional instructions from your counsellor before you start on this activity.</p>
                  
                    <br/>
                    <p>There are 34,000 emotions that a human can experience. We can navigate the turbulent of feelings without getting lost with the Emotion Wheel.</p>
                    <br/>
                    <p>Increase your emotional intelligence and awareness with this tool. The wheel contains primary emotions that serve as a foundation for all others.</p>

                    <br/>
                  
                    <div>
                    {Refresh ? 

                            <div>
                                <div>
                                    <ReactSVG
                                        afterInjection={(error, svg) => {
                                            if (error) {
                                            //console.error(error)
                                            return
                                            }
                                            //console.log(svg)
                                        }}
                                        beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name')
                                            svg.setAttribute('style', ImageWidth)
                                        }}
                                        className="wrapper-class-name"
                                        evalScripts="always"
                                        fallback={() => <span>Error!</span>}
                                        loading={() => <span>Loading</span>}
                                        onClick={() => {
                                            //console.log('wrapper onClick')
                                        }}
                                        renumerateIRIElements={false}
                                        src={IMAGE1}
                                        useRequestCache={false}
                                        wrapper="span"
                                        />
                                    </div>
                                    
                                    <div>
                                        <p className={creditClass}> Published with Permission from Geoffrey Roberts.</p>
                                    </div>

                                    <div>
                                        <p><a href={downloadlink} target="_blank" rel="noreferrer">Download</a> for personal use only.</p>
                                    </div>
                            </div>


                            :
                            null
                            }

                    </div>
                 

                </div>
                


                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const FeelingWheel =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}


export default FeelingWheel