import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import TextTime from '../../../containers/TransitText/texttransition'

import CallMe from '../../CallMe/callme'

function pageContent (){

    var testimonialText = classNames(classes.testimonials ,classes.italic)
    var subheading = classNames (classes.bioheading)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    var signature = classNames (classes.signature)
    var testimoniallegend = classNames (classes.legendtext)



    return(

        <div className={classes.divcontentblock}>

            <div className = {contentClass}>
                <br/><br/><br/>
                


                <div>
                    <p className = {titleClass} data-attribute="title">Jonathon</p>
                    <br/>


                    <p>Jonathon is a caring psychotherapist with extensive experience managing people in the banking sector in his previous career. 
                        He can empathize with individuals whilst retaining an objective and realistic approach to their treatment and therapy.
                    </p>
                    <br/>
                    <p>
                    He believes in taking an integrative or eclectic approach to combining the best counselling theories in his practice.
                    He creates a non-judgmental and comfortable environment for his clients in a non-directive way. 
                    He believes family relationships are an essential factor in psychological health by addressing issues as individuals and as people in relationships.
                    </p>
                    <br/>
                    <p>
                    Jonathon focus has been on couples, families and individuals. He specialises in depression, anger, abuse, addiction, single parents, youth, suicide and LGBTQ+.
                    </p>
                    <br/><br/>
                    <p className={subheading}>Single Parent and Animal lover</p>
                    <p>He helps in the ministry and a local animal shelter in his free time. He has been bringing up his wonderful children single-handedly and understands the struggle of single parenting.</p>
                    
                    
                    <br/>
                    <p className={subheading}>An experienced psychotherapist with an oversea exposure </p>
                    <p>Jonathon has been in this field for 15 years, starting in Hong Kong as a youth counsellor and now as a private practice in Singapore.</p>
                    
                    <div>
                        <p className={signature}>Jonathon</p>
                        <br/>
                    </div>
                    <div>
                        <br/><br/>
                        <CallMe/>


                    </div>

                    <br/><br/><br/><br/>    

                </div>
            </div>
        </div>
    )

}

export default function bio_counsel1(){


    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}
