import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/festivecny.jpeg'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent(){
    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)

    return (

        <div  className={classes.divcontentblock}>
             <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <br/>
                    <p className={titleClass} > Struggling over popular festive season </p>
                    <p className={titleClass} > (Lunar New Year)  </p>
                    
                    <br/><br/><br/>

                    <p>Lunar New Year is this week. It is meant to be a time of joy with celebrations, red envelopes, gifts and qualify 
                        family time. Unfortunately for many, this period is a struggle. 
                        The sense of loneliness intensifies during the popular festive season when everyone else is happy.</p>
                    <br/>
                    <p>The absence of family members — because of estrangement or death — may cause Chinese New Year to be an excruciating and lonely season rather than a time of celebration. 
                        Once bottled up, feelings of profound loneliness, sorrow and regret could rise to the fore, leading to depression and suicidal thoughts.</p>
                    <br/>
                    <p>Even if people surround you, you may feel detached from your family and friends both socially and emotionally. 
                        As your feelings of loneliness deepen, you may also feel other emotions such as sadness, shame, disappointment, rejection and isolation.</p>
                    <br/>
                    <p>According to National Healthcare Group study (2017) in Singapore, loneliness affects about six in every 100 people in Singapore, 
                        and this has a higher prevalence among young adults and seniors.</p>
                    <br/>
                    <p>Loneliness is not unique in Singapore. It is global. Loneliness has been associated with increased physical and mental health risks, including poorer sleep, dementia, depression, anxiety, heart disease, increased mortality rates, 
                        and social issues like unemployment.</p>
                    <br/><br/>
                    <p>How do you deal with loneliness and stay connected, especially during this festive season?</p>
                    <br/>
                    <p>This are some of the ways and it is not exhaustive.</p>
                    <br/>
                    <p  className={classes.bulletpoints}>1. Be kind to yourself and plan ahead, such as planning a day for yourself to relax</p> 
                    <p  className={classes.bulletpoints}>2. Reach out to your friends and family such as calling them, or visiting them</p>  
                    <p  className={classes.bulletpoints}>3. Volunteer in your local charity for a cause that you are devoted so that you can find more meaningful time</p> 
                    <p  className={classes.bulletpoints}>4. Attend community events or visit places that have the Lunar New Year celebrations, to give the festive vibes</p> 
                    <p  className={classes.bulletpoints}>5. You are not alone in your feelings — many have struggled with this even before the pandemic</p>  
                    <p  className={classes.bulletpoints}>6. You don’t have to do this alone. There are helpline for mental health if you require help</p> 
                    
                    <br/> <br/>
                    <p>Remember, you are not alone. Many others struggle during this period. If you are unable to cope, drop us a message at <a href='/contact'>Contact</a> for an appointment.</p> 

            </div>
        </div>
    )


}


const CNYArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default CNYArticle