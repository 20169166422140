
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-andrea-piacquadio-3772511.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>When we are not assertive, we can lose confidence and self-esteem. This will more likely make us less assertive in future. The inability to ask may come from irrational belief systems or fear. 
                Sometimes it may be our fear of avoiding making requests due to fear of creating tension or discomfort.</p>
            <br/><br/>
            <p>The lack of assertiveness can be a problem when building relationships or communicating with friends, coworkers or family members.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Assertiveness'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/woman-in-discussing-a-lesson-plan-3772511/'} imagelogo={imagelogo} credit={'Andrea Piacquadio'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}