import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/attentionbasic_logo.jpeg'
import YoutubePage from '../../YoutubePage/youtube'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    return (


            <div  className={classes.divcontentblock}>


                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source <a href=' https://unsplash.com/photos/votwQUV9ts4' target='__blank' rel="noreferrer">Nadine Shaabana</a></p>
                    <p className={titleClass} data-attribute="title"> Developing Attention Strength (Part 2)</p>
                    <p>Please adhere to additional instructions from your counsellor before you start on this activity.</p>
                    <p>Keep your eyes open throughout the exercise and focus your gaze on a spot on the wall. In this exercise you will become aware of your focus of attention and strengthen your control over it. 


                    </p>
                    <br/>
                    <p>The goal of this technique is not to distract you from upsetting thoughts or feelings. 
                    </p>
                    <br/>
                    <p>They are actually likely to occur during the exercise. It is important to just let those experiences take care of themselves and view them as passing events in your body and mind. 
                        Your main goal is to focus on the sound and let everything else be just as it is. </p>
                    <br/>
                    <p>In this audio, there are sounds of hammering, rain drops, bird chirping, clocking ticking, barking dogs and wind chimes</p>

                    <br/>
                    <p>I will be guiding you instruction on to focus on rain , then move on to clock then the wind etc. </p>
                    <br/>
                    <p>This audio is around 5 minute 23 seconds . Focus on my instruction and nothing else. If your mind shifted and got distracted, bring it back again to the sound of the object 
                        which I instructed .</p>
                    <br/><br/><br/>
                    <p >Pay attention to this 5 minute plus video.</p>
                    <p><a target = "_blank" rel="noreferrer" href = 'https://youtu.be/9X4PGwLQheU'>Audio Here</a></p>
                    <div className = {classes.youtube}>
                        <YoutubePage id={"9X4PGwLQheU"}/>
                    </div>
                

                 

                </div>
                


                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle