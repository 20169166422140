import React from 'react'
//import classes from './privacy.module.css'

import classes from '../../css/main.module.css'
import classNames from 'classnames'
import MenuNavBar from '../navbar/MenuNavigationBar'
// import SideBar from '../../components/HamburgerMenu/hamburgermenu'
import Copyright from '../../components/Copyright/copyright'
import Footer from '../../components/Footer/footer'
// import NavSideItem from '../../components/NavSide/navside'
import SocialMedia from '../../components/SocialMedia/socialmedia'

export default function ServicePage(props){
    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    // var contentClass = classNames (classes.divcontent)
    var titleClass = classNames(classes.title)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)

    return (

        <div>
            {/* <div data-attribute="title">
                {props.title}
            </div> */}

            <div data-attribute="topnav">
                <MenuNavBar/>
            </div>

            <div className={classes.divcontentblock}>

                <div className = {contentClass}>
                    <br/><br/><br/>
                    <p className = {titleClass}  data-attribute="title"> {props.title}</p>
                    <img src = {props.imagelogo} className = {classes.imgcaption} alt=""/>
                    <p className={creditClass}> Image credit :<a target="_blank" rel="noreferrer" href={props.urlcredit}>{props.credit}</a> </p>

                    <p>{props.content}</p>

                    <br/><br/><br/><br/>    
                          
                            
                    <Footer/>
                    <br/><br/>
                    <SocialMedia/>
                    <Copyright/>
                </div>
            </div>
        </div>
    )
}