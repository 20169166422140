import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/lovingkindness_caption.jpeg'
import audioSourceLong from './resource/lovingkindness-long.m4a'
import audioSourceShort from './resource/lovingkindness-short.m4a'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    const subheading = classNames (classes.subheadingcontent,  classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    return (


            <div  className={classes.divcontentblock}>


                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source <a href='https://unsplash.com/photos/b3eaH1hguOA' target='_blank' rel="noreferrer" >Jeppe Hove Jensen </a> </p>
                    <p className={titleClass} data-attribute="title"> Loving Kindness </p>
                    <p>Loving kindness exercise is a popular self-care technique that can be used to boost well-being and reduce stress. Those who regular practice loving kindness
                        are able to increase their capacity for forgiveness, connection to others and self , self-acceptance and much more. 

                    </p>
                    <br/>
                    <p>This technique involves some practise. It may sound strange or unfamiliar at first , you will be asking to send kindness to yourself and to others. It involves
                        repeated short verses to yourself while you are focusing on your breathing. However, getting use to sending positive vibes to yourself, you may start to see a change
                        within. There are no magical pills in here. It is a commonly use technique for calming down , management of anxiety and anger , and conflict.
                    </p>
                    <br/><br/><br/>


                    <p className={subheading}>Steps to loving kindness</p>
                    <p  className={classes.bulletpoints}>1. <span className = {classes.italic}>Sit comfortably on a chair, with your both feet on the floor. Allow your hands to rest on your lap or on the arms of the chair. </span></p>
                    <p  className={classes.bulletpoints}>2. <span className = {classes.italic}>Lean comfortably against the back rest. </span></p>
                    <p  className={classes.bulletpoints}> 3. <span className = {classes.italic}> I will ask you to focus on your breathe and listen to my voice </span></p>
                    <p  className={classes.bulletpoints}> 4. <span className = {classes.italic}> Visualize kindness flowing in the targeted area. Example if I say
                     focus on your right palm,
                    visualize that the kindness is coming out of your right palm. Give appreciation and gratitude to your right palm. Visualize there is a comfort, warmth and peace in the palm (or any
                    target which we are practising). For most parts, I will repeat the verse three times. 
                     </span></p>
                     <p  className={classes.bulletpoints}> 5. <span className = {classes.italic}> It is alright if you miss out a sentence. Focus on what you can at the present moment. It takes some practise.
                     No one is perfect at this!
                      </span></p>
   
                    <p  className={classes.bulletpoints}>6. <span className = {classes.italic}>If you are in a hurry, you may consider the shorter 7 minute audio. For a full loving kindness practise, there is a 14 minutes audio. </span></p>
                    <p  className={classes.bulletpoints}>7. <span className = {classes.italic}>Play the audio, close your eyes and listen to the instructions. </span></p>

                    <br/><br/><br/><br/>
                    <div>
                    <p>Full Version</p>
                        <audio  controls controlsList="nodownload" autostart="0" src={audioSourceLong} />
                        <p className={classes.creditcontent}>14 minutes audio</p>
                    </div>

                    <br/><br/><br/><br/>
                    <div>
                       <p>Shorter Version</p>
                        <audio  controls controlsList="nodownload" autostart="0" src={audioSourceShort} />
                        <p className={classes.creditcontent}>7 minutes audio</p>
                    </div>

                </div>
                


                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const LovingKindness =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default LovingKindness