import React from 'react'
import classes from '../../../css/main.module.css'
import classCSS from './suicide.module.css'
import classNames from 'classnames'

//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'



//Image
import Flag_algeria from './resource/algeria.png'
import Flag_agentina from './resource/agentina.png'
import Flag_armenia from './resource/armenia.png'
import Flag_australia from './resource/australia.png'
import Flag_austria from './resource/austria.png'
import Flag_azerbaijan from './resource/azerbaijan.png'
import Flag_bahamas from './resource/bahamas.png'
import Flag_baharain from './resource/baharain.png'
import Flag_bangladesh from './resource/bangladesh.png'
import Flag_barbados from './resource/barbados.png'
import Flag_bazil from './resource/bazil.png'
import Flag_belarus from './resource/belarus.png'
import Flag_belgium from './resource/belgium.png'
import Flag_bolivia from './resource/bolivia.png'
import Flag_bosniaherzegovina from './resource/bosniaherzegovina.png'
import Flag_botswana from './resource/botswana.png'
import Flag_brunei from './resource/brunei.png'
import Flag_bulgaria from './resource/bulgaria.png'
import Flag_canada from './resource/canada.png'
import Flag_chile from './resource/chile.png'
import Flag_china from './resource/china.png'
import Flag_colombia from './resource/colombia.png'
import Flag_coastarica from './resource/costarica.png'
import Flag_croatia from './resource/croatia.png'
import Flag_cuba from './resource/cuba.png'
import Flag_cyprus from './resource/cyprus.png'
import Flag_czech from './resource/czech.png'
import Flag_denmark from './resource/denmark.png'
import Flag_ecuador from './resource/ecuador.png'
import Flag_egypt from './resource/egypt.png'
import Flag_estonia from './resource/estonia.png'
import Flag_fiji from './resource/fiji.png'
import Flag_finland from './resource/finland.png'
import Flag_france from './resource/france.png'
import Flag_germany from './resource/germany.png'
import Flag_ghana from './resource/ghana.png'
import Flag_greece from './resource/greece.png'
import Flag_greenland from './resource/greenland.png'
import Flag_guyana from './resource/guyana.png'
import Flag_hongkong from './resource/hongkong.png'
import Flag_hungary from './resource/hungary.png'
import Flag_iceland from './resource/iceland.png'
import Flag_india from './resource/india.png'
import Flag_indonesia from './resource/indonesia.png'
import Flag_iran from './resource/iran.png'
import Flag_ireland from './resource/ireland.png'
import Flag_israel from './resource/israel.png'
import Flag_italy from './resource/italy.png'
import Flag_japan from './resource/japan.png'
import Flag_jordan from './resource/jordan.png'
import Flag_kenya from './resource/kenya.png'
import Flag_koreasouth from './resource/koreasouth.png'
import Flag_kosovo from './resource/kosovo.png'
import Flag_latvia from './resource/latvia.png'
import Flag_lebanon from './resource/lebanon.png'
import Flag_liberia from './resource/liberia.png'
import Flag_lithuania from './resource/lithuania.png'
import Flag_luxembourg from './resource/luxembourg.png'
import Flag_malaysia from './resource/malaysia.png'
import Flag_malta from './resource/malta.png'
import Flag_mauritius from './resource/mauritius.png'
import Flag_mexico from './resource/mexico.png'
import Flag_morocco from './resource/morocco.png'
import Flag_netherlands from './resource/netherlands.png'
import Flag_newzealand from './resource/newzealand.png'
import Flag_norway from './resource/norway.png'
import Flag_philippines from './resource/philippines.png'
import Flag_poland from './resource/poland.png'
import Flag_portugal from './resource/portugal.png'
import Flag_romania from './resource/romania.png'
import Flag_russia from './resource/russia.png'
import Flag_serbia from './resource/serbia.png'
import Flag_singapore from './resource/singapore.png'
import Flag_slovakia from './resource/slovakia.png'
import Flag_slovenia from './resource/slovenia.png'
import Flag_southafrica from './resource/southafrica.png'
import Flag_spain from './resource/spain.png'
import Flag_srilanka from './resource/srilanka.png'
import Flag_stvincent from './resource/stvincent.png'
import Flag_sudan from './resource/sudan.png'
import Flag_sweden from './resource/sweden.png'
import Flag_switzerland from './resource/switzerland.png'
import Flag_taiwan from './resource/taiwan.png'
import Flag_thailand from './resource/thailand.png'
import Flag_tonga from './resource/tonga.png'
import Flag_trinidadtobago from './resource/trinidadtobago.png'
import Flag_turkey from './resource/turkey.png'
import Flag_uk from './resource/uk.png'
import Flag_ukraine from './resource/ukraine.png'
import Flag_unitedarabemirates from './resource/unitedarabemirates.png'
import Flag_usa from './resource/usa.png'
import Flag_uruguay from './resource/uruguay.png'

function BreakLineFlag(){

    return (

        <div>
            <br/>
        </div>
    )
}
function NextLine(){

    return (
        <div>
            <br/><br/>
        </div>
    )
}


function Content(){
    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    var ulClass = classNames(classCSS.ultype)
    var contactClass = classNames (classes.italic)
    var countryNameClass = classNames (classes.subheadingcontent, classes.underline)
    var VWODetailsClass = classNames (classes.bulletpoints)
    var VWOOTherDetailsClass = classNames (classes.italic)
    var flagClass = classNames (classes.imgflag)
    var seperatorClass = classNames (classes.hrsolid)
    var highlightClass = classNames (classes.bold)


    return (
        <div  className={classes.divcontentblock}>
            <div className = {contentClass}>
                <p className = {titleClass} data-attribute="title">Worldwide Suicide and Crisis Hotlines</p>

                <div>
                    <ul className={ulClass}>
                        <li>
                            <p className={countryNameClass}>Algeria</p>
                            
                            <img className= {flagClass} src={Flag_algeria} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency (Police and Ambulances)</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:34342">34342</a> and <a href="tel:43">43</a></p>
                            
                            {NextLine()}
                            <p className={VWODetailsClass}>Suicide Hotline Algeria</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:00213983200058">0021-3983-2000-58</a></p>

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Agentina</p>
                            <img className= {flagClass} src={Flag_agentina} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a>  </p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Centro de Asistencia al Suicida</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:135">135</a> or  <a href="tel:52751135">5275-1135</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>SOS Un Amigo Anonimo</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:47838888">4783–8888</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>


                        <li>
                            <p className={countryNameClass}>Armenia</p>
                            <img className= {flagClass} src={Flag_armenia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Numbers</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span> <a href="tel:112">112</a> and <a href="tel:911">911</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Trust Social Work and Sociological Research Centre</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel: </span><a href="tel:538194">538194</a> or <a href="tel: 538197"> 538197</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Australia</p>
                            <img className= {flagClass} src={Flag_australia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:000">000</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.lifeline.org.au/" target="_blank" rel="noreferrer">Lifeline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:131114">13-11-14</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://kidshelpline.com.au/" target="_blank" rel="noreferrer">Kids Helpline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:1800551800">1800-55-1800</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.suicidecallbackservice.org.au/" target="_blank" rel="noreferrer">Suicide Call Back Service</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel: 1300659467">1300-659-467</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.beyondblue.org.au/" target="_blank" rel="noreferrer">Beyond Blue</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:1300224636">1300-22-4636</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://mensline.org.au/" target="_blank" rel="noreferrer">MensLine Australia </a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:1300789978">1300-78-99-78</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://suicidepreventionpathways.org.au/" target="_blank" rel="noreferrer">Talk Suicide </a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:1800008255">1800-008-255</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Austria</p>
                            <img className= {flagClass} src={Flag_austria} alt="flag"/>
                            {BreakLineFlag()}

                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.telefonseelsorge.at/" target="_blank" rel="noreferrer">Telefonseelsorge</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:142">142</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.rataufdraht.at/" target="_blank" rel="noreferrer">Rat auf Draht</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:147">147</a></p>


                            <hr className={seperatorClass}/>
                        </li>



                        <li>
                            <p className={countryNameClass}>Azerbaijan</p>
                            <img className= {flagClass} src={Flag_azerbaijan} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Official Youth Crisis Hotline by Initiative for Development</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span> <a href="tel:5106636">510-66-36</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>The Bahamas</p>
                            <img className= {flagClass} src={Flag_bahamas} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>National Suicide Hotline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel: 3222763"> 322-2763</a></p>
                         
       
                            <hr className={seperatorClass}/>
                        </li>
                        <li>
                            <p className={countryNameClass}>Baharain</p>
                            <img className= {flagClass} src={Flag_baharain} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a></p>
                           

       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Bangladesh</p>
                            <img className= {flagClass} src={Flag_bangladesh} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://shuni.org/" target="_blank" rel="noreferrer">Kaan Pete Roi </a></p>
                            <p className={VWOOTherDetailsClass}>Emotional support helpline in Bangladesh whose mission is to alleviate feelings of despair, isolation, distress, 
                            and suicidal feelings among members of the community, through confidential listening. The helpline is intended for suicide prevention and the promotion of mental health </p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Barbados</p>
                            <img className= {flagClass} src={Flag_barbados} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:221">221</a> for police emergency or <a href="tel:511">511</a> for ambulance emergency or <a href="tel:311">311</a> for fire emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Samaritans of Barbados</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:2464299999">(246) 4299999</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Belarus</p>
                            <img className= {flagClass} src={Flag_belarus} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or <a href="tel:102">102</a> for police emergency or <a href="tel:103">103</a> for ambulance emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}>For victims of violence at home</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:88011008801">8-801-100-8-801</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>For children</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:8011001611"> 801-100-1611</a></p>
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Belgium</p>
                            <img className= {flagClass} src={Flag_belgium} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.zelfmoord1813.be/chat-met-zelfmoordlijn-1813" target="_blank" rel="noreferrer">Zelfmoordlijn 1813</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1813">1813</a></p>
                            {NextLine()}
                            
                            <p className={VWODetailsClass}><a href="https://www.preventionsuicide.be" target="_blank" rel="noreferrer">Stichting Centre de Prévention du Suicide </a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:080032123">080032123</a></p>
                            {NextLine()}

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Bosnia and Herzegovina</p>
                            <img className= {flagClass} src={Flag_bosniaherzegovina} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:122">122</a> for police emergency or <a href="tel:123">123</a> for fire emergency or <a href="tel:124">124</a> for national emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.centarsrce.org/" target="_blank" rel="noreferrer">Centar Srce</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800300303">0800-300303 </a></p>
                         
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Bolivia</p>
                            <img className= {flagClass} src={Flag_bolivia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Teléfono de la Esperanza</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0059144254242">(00-591-4) 4-25-42-42</a> or <a href="tel:75288084">75288084</a> in Cochabamba and La Paz </p>
                            {NextLine()}
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Botswana</p>
                            <img className= {flagClass} src={Flag_botswana} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>National Lifeline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:3911270">3911270</a></p>
                         
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Brazil</p>
                            <img className= {flagClass} src={Flag_bazil} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:188">188</a> or <a href="tel:192">192</a> for ambulance emergency or <a href="tel:190">190</a> for fire emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.cvv.org.br/" target="_blank" rel="noreferrer">Centro de Valorização da Vida</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:188">188</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Brunei</p>
                            <img className= {flagClass} src={Flag_brunei} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:991">991</a> for ambulance emergency or <a href="tel:993">993</a> for police emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}>National Suicide Hotline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:145">145</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Bulgaria</p>
                            <img className= {flagClass} src={Flag_bulgaria} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.redcross.bg/activities/activities5/telefon.html" target="_blank" rel="noreferrer">Bulgarian Red Cross</a></p>
                           
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Canada</p>
                            <img className= {flagClass} src={Flag_canada} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://kidshelpphone.ca/" target="_blank" rel="noreferrer">Kids Help Phone</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.crisisservicescanada.ca/" target="_blank" rel="noreferrer">Canada Suicide Prevention Service</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:18334564566"> 1-833-456-4566</a> or <a href="tel:45645">45645</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.crisistextline.ca/" target="_blank" rel="noreferrer">Crisis Text Line</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Text:</span>   <a href="tel:686868">686868</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.translifeline.org/" target="_blank" rel="noreferrer">Trans Lifeline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:18773306366">1-877-330-6366</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://suicideprevention.ca" target="_blank" rel="noreferrer">Canadian Association for Suicide Prevention</a></p>

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Chile</p>
                            <img className= {flagClass} src={Flag_chile} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>Ministerio de Salud</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:6003607777">600-360-7777</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Fundacion Vinculos</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:56222442533">5622-2442-533</a> or <a href="tel:56982481971">5698-2481-971</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://gob.cl/saludablemente/" target="_blank" rel="noreferrer">Saludable Mente</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://todomejora.org/apoyo/" target="_blank" rel="noreferrer">Todo Mejora</a></p>
                            {NextLine()}
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>China</p>
                            <img className= {flagClass} src={Flag_china} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:110">110</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.crisis.org.cn/" target="_blank" rel="noreferrer">Beijing Suicide Research and Prevention Center</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:8008101117">800-810-1117</a> or <a href="tel:01082951332"> 010-8295-1332</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.lifelinechina.org/" target="_blank" rel="noreferrer">Lifeline China</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:4008211215"> 400-821-1215</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.smhc.org.cn/" target="_blank" rel="noreferrer">Shanghai Mental Health Center</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:02164387250">021–64387250</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.gzcrisis.com/" target="_blank" rel="noreferrer">Guangzhou Crisis Research and Intervention Center</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:02081899120">020-81899120</a> or <a href="tel:020123205">020-12320-5</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.szknyy.com/" target="_blank" rel="noreferrer">Shenzhen Mental Health Center</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:075525629459">0755-25629459</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.hz7hospital.com/" target="_blank" rel="noreferrer">Mental Health Center of School of Medicine of Zhejiang University</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>   <a href="tel:057185029595">0571-85029595</a></p>
                            {NextLine()}
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Colombia</p>
                            <img className= {flagClass} src={Flag_colombia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:123">123</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Support for Depression, Suicide, Drug Abuse , Alcholism</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:106">106</a></p>
                         
       
                            <hr className={seperatorClass}/>
                        </li>
                        
                        <li>
                            <p className={countryNameClass}>Costa Rica</p>
                            <img className= {flagClass} src={Flag_coastarica} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}><a href="https://psicologiacr.com/aqui-estoy/" target="_blank" rel="noreferrer">Lunes a viernes</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:50622723774">(506) 2272-3774</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Croatia</p>
                            <img className= {flagClass} src={Flag_croatia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.plavi-telefon.hr/" target="_blank" rel="noreferrer">Plavi Telefon</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:4833888">48-33-888</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Cuba</p>
                            <img className= {flagClass} src={Flag_cuba} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:188">106</a> for police emergency or <a href="tel:104">104</a> for ambulance emergency or <a href="tel:105">105</a> for fire emergency</p>

       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Cyprus</p>
                            <img className= {flagClass} src={Flag_cyprus} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or <a href="tel:199">199</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.cyprussamaritans.org/" target="_blank" rel="noreferrer">Cyprus Samaritans</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:80007773">8000-7773</a></p>
       
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Czech Republic</p>
                            <img className= {flagClass} src={Flag_czech} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or <a href="tel:105">105</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.linkabezpeci.cz/" target="_blank" rel="noreferrer">For kids and students under 26 years old</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116111">116-111</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Blueline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:608902410">608-902-410</a> or <a href="tel:731197477">731-197-477</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}>National Medical Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:155">155</a></p>
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Demark</p>
                            <img className= {flagClass} src={Flag_denmark} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.livslinien.dk" target="_blank" rel="noreferrer">Livslinien</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:70201201">70-201-201</a></p>

       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Ecauador</p>
                            <img className= {flagClass} src={Flag_ecuador} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.telefonoamigo.org/" target="_blank" rel="noreferrer">Teléfono Amigo</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:022906060">(02) 2906060</a> or <a href="tel:022906030">(02) 290-6030</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Egypt</p>
                            <img className= {flagClass} src={Flag_egypt} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:122">122</a> for police emergency or <a href="tel:123">123</a> for health emergency or <a href="tel:126">126</a> for foreigners emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://befrienderscairo.com/" target="_blank" rel="noreferrer">BeFrienders in Cairo</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:7621602">762-1602</a> or <a href="tel:7621603">762-1603</a> or <a href="tel:7622381">762-2381</a></p>
       
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Estonia</p>
                            <img className= {flagClass} src={Flag_estonia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.eluliin.ee/" target="_blank" rel="noreferrer">Eluliin</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:6558088">655-8088</a> or <a href="tel:6555688">655-5688</a></p>
       
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Fiji</p>
                            <img className= {flagClass} src={Flag_fiji} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:917">917</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.lifelinefiji.com/" target="_blank" rel="noreferrer">Lifeline Fiji</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:132454"> 132454</a></p>
                            {NextLine()}
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Finland</p>
                            <img className= {flagClass} src={Flag_finland} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://mieli.fi" target="_blank" rel="noreferrer">Finnish Association for Mental Health</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:010195202">010-195-202</a> or <a href="tel:0941350501"> (09) 4135-0501</a></p>
       
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>France</p>
                            <img className= {flagClass} src={Flag_france} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.filsantejeunes.com/" target="_blank" rel="noreferrer">Fil santé jeunes</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800235236">0800-235-236</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://suicideecoute.pads.fr/accueil" target="_blank" rel="noreferrer">Suicide écoute</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0145394000">01-45-39-40-00</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://sos-suicide-phenix.org/" target="_blank" rel="noreferrer">SOS Suicide Phénix</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0140444645">01-40-44-46-45</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.sos-amitie.com/" target="_blank" rel="noreferrer">SOS Amitié</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0972394050"> 09-72-39-40-50</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.croix-rouge.fr/Nos-actions/Action-sociale/Ecoute-acces-aux-droits/Croix-Rouge-Ecoute-service-de-soutien-psychologique-par-telephone" target="_blank" rel="noreferrer">La Croix Rouge Ecoute</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800858858">0-800-858-858</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Germany</p>
                            <img className= {flagClass} src={Flag_germany} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> for fire and ambulance emergency or <a href="tel:110">110</a> for police emergency </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.telefonseelsorge.de/" target="_blank" rel="noreferrer">Telefonseelsorge</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:08001110111">0800-111-0-111</a> or <a href="tel:08001110222">0800-111-0-222</a> or <a href="tel:116123">116-123</a> </p>
                       
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Ghana</p>
                            <img className= {flagClass} src={Flag_ghana} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>National Lifeline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:233244471279"> 2332-444-71279</a></p>
                       
       
                            <hr className={seperatorClass}/>
                        </li>

                        

                        <li>
                            <p className={countryNameClass}>Greece</p>
                            <img className= {flagClass} src={Flag_greece} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> for National emergency or <a href="tel:100">100</a> for Police emergency or <a href="tel:199">199</a>  for Fire emergency or <a href="tel:166">166</a> for Ambulance emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://suicide-help.gr/" target="_blank" rel="noreferrer">Suicide Hotline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1018">1018</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.lifelinehellas.gr/" target="_blank" rel="noreferrer">SOS Lifeline for the elderly</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1065">1065</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://cyberalert.gr/" target="_blank" rel="noreferrer">Cyber Crime Division</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:11188">11188</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.hamogelo.gr/gr/en/sos-1056/" target="_blank" rel="noreferrer">National Helpline for Children</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1056">1056</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.isotita.gr/en/home/" target="_blank" rel="noreferrer">General Secretariat for Family Policy and Gender Equality</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1056">15900</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Open support line for the LGBT community</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:11528">11528</a></p>
                        
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Greenland</p>
                            <img className= {flagClass} src={Flag_greenland} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:134">134</a> </p>
                            {NextLine()}
       
                            <hr className={seperatorClass}/>
                        </li>
                        

                        <li>
                            <p className={countryNameClass}>Guyana</p>
                            <img className= {flagClass} src={Flag_guyana} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Inter-agency Suicide Prevention Help Line </p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:2230001">223–0001</a> or <a href="tel:2230009">223–0009</a> or <a href="tel:2230818">223–0818</a> or <a href="tel:6007896">600-7896</a> or <a href="tel:6234444">623-4444</a></p>
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Hong Kong</p>
                            <img className= {flagClass} src={Flag_hongkong} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://samaritans.org.hk/" target="_blank" rel="noreferrer">The Samaritans Hong Kong</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:28960000">2896-0000</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.sbhk.org.hk/" target="_blank" rel="noreferrer">The Samaritan Befrienders Hong Kong</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:23892222">23892222</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Hungary</p>
                            <img className= {flagClass} src={Flag_hungary} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://sos116-123.hu/" target="_blank" rel="noreferrer">LESZ</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116123">116-123</a> or <a href="tel:0680810600">06-80-810-600</a> or <a href= "mailto: sos116123@gmail.com">email</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://kek-vonal.hu/" target="_blank" rel="noreferrer">Blue Line</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116111">116-111</a></p>
                       
       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Iceland</p>
                            <img className= {flagClass} src={Flag_iceland} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.raudikrossinn.is/hvad-gerum-vid/hjalparsiminn-1717/hvad-gerum-vid-3" target="_blank" rel="noreferrer">Hjálparsími Rauða Krossins (Suicide Helpline)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1717">1717</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>India</p>
                            <img className= {flagClass} src={Flag_india} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://samaritansmumbai.org/" target="_blank" rel="noreferrer">Samaritans Mumbai</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+918422984528">+91-8422984528</a> or <a href="tel:+918422984529">+91 8422984529</a> or <a href="tel:+918422984530">+91 8422984530</a> or <a href= "mailto: samaritans.helpline@gmail.com">email</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.mitramfoundation.org/" target="_blank" rel="noreferrer">Mitram Foundation</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:918025722573">+91-80-2572 2573</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.aasra.info/" target="_blank" rel="noreferrer">AASRA</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+912227546669">+91-22-27546669</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://befriendersindia.net/helpline-details.php/" target="_blank" rel="noreferrer">Befrienders India</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+914424640050">+91-44-24640050</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.snehaindia.org/" target="_blank" rel="noreferrer">Sneha India</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+914424640050">+91-44-24640050</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="www.lifelinefoundation.in" target="_blank" rel="noreferrer">Lifeline Foundation (Kolkata)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+919088030303">+91-9088030303</a> or <a href="tel:03340447437">03340447437</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Indonesia</p>
                            <img className= {flagClass} src={Flag_indonesia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.kemkes.go.id/" target="_blank" rel="noreferrer">Kementerian Kesehatan</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1500454">150-0454</a></p>
          
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Iran</p>
                            <img className= {flagClass} src={Flag_iran} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:110">110</a> or <a href="tel:115">115</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>The Iran National Organization of Well-Being</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1480">1480</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>


                        <li>
                            <p className={countryNameClass}>Ireland</p>
                            <img className= {flagClass} src={Flag_ireland} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.samaritans.org/" target="_blank" rel="noreferrer">Samaritans / Freephone</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116123">116-123</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://text50808.ie/" target="_blank" rel="noreferrer">50808</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Text:</span>  <a href="sms:50808">50808</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Israel</p>
                            <img className= {flagClass} src={Flag_israel} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:100">100</a> or <a href="tel:101">101</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.eran.org.il/" target="_blank" rel="noreferrer">Eran Suicide Line</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1201">1201</a> or <a href="tel:97298891333">972-9-8891333</a> or  <a href="tel:0768844400">076-88444-00</a></p>
                            
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Italy</p>
                            <img className= {flagClass} src={Flag_italy} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.prevenireilsuicidio.it/" target="_blank" rel="noreferrer">Servizio per la Prevenzione del Suicidio (SPS)</a></p>

                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.samaritansonlus.org/" target="_blank" rel="noreferrer">Samaritans - ONLUS</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:800860022">800-86-00-22</a> or <a href="tel:0677208977">06-77208977</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.telefonoamico.it/" target="_blank" rel="noreferrer">Telefono Amico</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:199284284">199284284</a> or <a href="tel:0223272327">02-2327-2327</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        
                        <li>
                            <p className={countryNameClass}>Japan</p>
                            <img className= {flagClass} src={Flag_japan} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:110">110</a> or <a href="tel:119">119</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://telljp.com/lifeline/" target="_blank" rel="noreferrer">TELL</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0357740992">03-5774-0992</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.spc-osaka.org/" target="_blank" rel="noreferrer">Befrienders Worldwide Osaka Suicide Prevent Center</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0662604343">06-6260-4343</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Jordon</p>
                            <img className= {flagClass} src={Flag_jordan} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.jordanriver.jo/en/programs/protecting-children/110-families-children" target="_blank" rel="noreferrer">Helpline For Families & Children</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:110">110</a></p>
 
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>South Korea</p>
                            <img className= {flagClass} src={Flag_koreasouth} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or <a href="tel:119">119</a>  </p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Medical Emergency for foreigners in Seoul</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1339">1339</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.counsel24.com/" target="_blank" rel="noreferrer">Counsel24</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:15662525">1566-2525</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Lifeline Korea</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:15889191">1588-9191</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Mental Health Center Crisis Counseling</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:15770199">1577-0199</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Ministry of Health & Welfare Call Center</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:129">129</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        

                        <li>
                            <p className={countryNameClass}>Kenya</p>
                            <img className= {flagClass} src={Flag_kenya} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.befrienderskenya.org/" target="_blank" rel="noreferrer">Befrienders Kenya</a> or <a href= "mailto: befrienderskenya@gmail.com">email</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+254722178177">+254-722-178-177</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://knh.or.ke/index.php/mental-health/" target="_blank" rel="noreferrer">Mental Health Department – Kenyatta Hospital</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+254203000378">+254-20-3000378</a> or <a href="tel:+254 20 2051323">+254-20-2051323</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Kosovo</p>
                            <img className= {flagClass} src={Flag_kosovo} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>Suicide Prevention Hotline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:080012345">080012345</a></p>
                           
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Latvia</p>
                            <img className= {flagClass} src={Flag_latvia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:113">113</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.skalbes.lv/" target="_blank" rel="noreferrer">Skalbes</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+37167222922">+371-67222922</a> or <a href="tel:+37127722292">+371-27722292</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        
                        <li>
                            <p className={countryNameClass}>Lebanon</p>
                            <img className= {flagClass} src={Flag_lebanon} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}><a href="https://embracelebanon.org/embrace-lifeline/" target="_blank" rel="noreferrer">Suicide Hotline Lebanon (Embrace)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1564">1564</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Liberia</p>
                            <img className= {flagClass} src={Flag_liberia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.lifelinenetwork.org/places/liberia/" target="_blank" rel="noreferrer">Lifeline Liberia</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:6534308">6534308</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Lithuania</p>
                            <img className= {flagClass} src={Flag_lithuania} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}><a href="https://www.viltieslinija.lt/" target="_blank" rel="noreferrer">Vilties Linija</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116123">116-123</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.vaikulinija.lt/" target="_blank" rel="noreferrer">Vaikų liniją (Childline)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116111">116-111</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.jaunimolinija.lt/" target="_blank" rel="noreferrer">Jaunimo linija (Youth Line)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:880028888">8-800-28888</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        
                        <li>
                            <p className={countryNameClass}>Luxembourg</p>
                            <img className= {flagClass} src={Flag_luxembourg} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://454545.lu/" target="_blank" rel="noreferrer">454545.lu</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+352454545">+352-45-45-45</a></p>
   
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Malaysia</p>
                            <img className= {flagClass} src={Flag_malaysia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.befrienders.org.my/" target="_blank" rel="noreferrer">Befrienders</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0379568144">03-79568144</a> or <a href="tel:0379568145">03-79568145</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Malta</p>
                            <img className= {flagClass} src={Flag_malta} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://fsws.gov.mt/en/appogg/Pages/support-line-179.aspx" target="_blank" rel="noreferrer">Appogg Supportline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:179">179</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        
                        <li>
                            <p className={countryNameClass}>Mauritius</p>
                            <img className= {flagClass} src={Flag_mauritius} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or  <a href="tel:114">114</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.befrienders.org/directory?country=Mauritius" target="_blank" rel="noreferrer">Befrienders Mauritius</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+2308009393">+230-800-93-93</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Mexico</p>
                            <img className= {flagClass} src={Flag_mexico} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.saptel.org.mx/index.html" target="_blank" rel="noreferrer">SAPTEL</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:5552598121">(55) 5259-8121</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.uam.mx/lineauam/lineauam_chat.htm" target="_blank" rel="noreferrer">UAM (Lunes a Viernes)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:555804644">(55) 5804-644</a> or <a href="tel:5558044879">(55) 5804-4879</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://inprf.gob.mx/" target="_blank" rel="noreferrer">Instituto Nacional de Psiquiatria</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:555655-3080">(55) 5655-3080</a> or <a href="tel:800953-1704">(800) 953-1704</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Morocco</p>
                            <img className= {flagClass} src={Flag_morocco} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}><a href="https://www.sourire2reda.org/" target="_blank" rel="noreferrer">Sourire de Reda (Befrienders Casablanca)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+212522874740">+212 (5) 22-87-47-40</a> or <a href="tel:+212662589570">+212 (6) 62-58-95-70</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        
                        <li>
                            <p className={countryNameClass}>Netherlands</p>
                            <img className= {flagClass} src={Flag_netherlands} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.113.nl/ik-denk-aan-zelfmoord/hulplijn" target="_blank" rel="noreferrer">Stichting</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:28960000">0800-0113</a></p>

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>New Zealand</p>
                            <img className= {flagClass} src={Flag_newzealand} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:111">111</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.1737.org.nz" target="_blank" rel="noreferrer">1737</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:28960000">2896-0000</a> or text <a href="sms:1737">1737</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.lifeline.org.nz/" target="_blank" rel="noreferrer">Lifeline Aotearoa</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:095222999">09-5222-999</a> or <a href="tel:0800543354">0800-543-354</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.youthline.co.nz/" target="_blank" rel="noreferrer">Youthline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel: 0800376633"> 0800-376-633</a> or text <a href="sms:234">234</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://thelowdown.co.nz/" target="_blank" rel="noreferrer">The Lowdown</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800111757">0800-111-757</a> or text <a href="sms:5626">5626</a></p>

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Norway</p>
                            <img className= {flagClass} src={Flag_norway} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or  <a href="tel:113">113</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://mentalhelse.no/fa-hjelp/hjelpetelefonen" target="_blank" rel="noreferrer">Mental Helse</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116123">116-123</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.kirkens-sos.no/telefon" target="_blank" rel="noreferrer">Kirkens SOS</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:22400040">22-40-00-40</a> or <a  href="https://www.soschat.no/" target="_blank" rel="noreferrer">chat</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Philippines</p>
                            <img className= {flagClass} src={Flag_philippines} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.ncmh.gov.ph/" target="_blank" rel="noreferrer">National Center for Mental Health</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:8727027989USAP8727">(8727) (02) 7989-USAP (8727)</a> or <a href="tel:0917899USAP8727">0917 899 USAP (8727)</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.ngf-hope.org/contact-us/" target="_blank" rel="noreferrer">The Natasha Goulbourn Foundation</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:028804HOPE4673">(02) 8804-HOPE (4673)</a> or <a href="tel:0917558HOPE4673">0917-558 HOPE (4673)</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.ngf-hope.org/contact-us/" target="_blank" rel="noreferrer">Manila Lifeline Centre</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0288969191">(02) 8896-9191</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.in-touch.org/" target="_blank" rel="noreferrer">In Touch Community Services</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0288937603">(02) 8893-7603</a> or <a href="tel:09178001123">0917-800-1123</a> or <a href="tel:09228938944">0922-893-8944</a></p>
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Poland</p>
                            <img className= {flagClass} src={Flag_poland} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://telefonzaufania.org/" target="_blank" rel="noreferrer">Olsztynski Telefon Zaufania 'Anonimowy Przyjaciel</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:8919288">89-19288</a> or <a href="tel:895270000">89-527-00-00</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://fdds.pl/jak-pomagamy.html#jak-pomagamy" target="_blank" rel="noreferrer">Foundation 'Dajemy Dzieciom Siłę</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116111">116-111</a> </p>

                        </li>

                        <li>
                            <p className={countryNameClass}>Portugal</p>
                            <img className= {flagClass} src={Flag_portugal} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.vozdeapoio.pt/" target="_blank" rel="noreferrer">Voz de Apoio</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:506070225506070">50-60-70-225-50-60-70</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.sosvozamiga.org/" target="_blank" rel="noreferrer">Sos Voz Amiga</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:544545213544545">544-545-213-544-545</a> or  <a href="tel:802669912802669">802-669-912-802-669</a> or  <a href="tel:524660963524660">524-660-963-524-660</a> or  <a href="tel:209899800209899">209-899-800-209-899</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://sosestudante.pt/" target="_blank" rel="noreferrer">SOS Estudante</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:246060915246060">246-060-915-246-060</a> or  <a href="tel:554545969554545">554-545-969-554-545</a> or  <a href="tel:484020239484020">484-020-239-484-020</a> </p>
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Romania</p>
                            <img className= {flagClass} src={Flag_romania} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.antisuicid.ro/" target="_blank" rel="noreferrer">Alianţa Română de Prevenţie a Suicidului</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800801200">0800-801-200</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.preveniresuicid.eu/pagina-principala/" target="_blank" rel="noreferrer">Asociația de Suicidologie</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800080100">0800-080-100</a> or  <a href="tel:116123">116-123</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>TelVerde antidepresie</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800080100">0800-080-100</a> or  <a href="tel:0800080020">0800-0800-20</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://deprehub.ro/helpline/" target="_blank" rel="noreferrer">Helpline anti-anxietate</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0374456420">0374-456-420</a> </p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Russia</p>
                            <img className= {flagClass} src={Flag_russia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or <a href="tel:051">051</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Samaritans (Cherepovets)</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0078202577577">007 (8202) 577-577</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Suicide Helpline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:4956253101">(495) 625-3101</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://migsovet.ru/" target="_blank" rel="noreferrer">Free psychological help online</a></p>
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Serbia</p>
                            <img className= {flagClass} src={Flag_serbia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:194">194</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.centarsrce.org/" target="_blank" rel="noreferrer">SRCE Novi Sad</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+381216623393">(+381) 21-6623-393</a> or  <a href="tel:+0800300303">0800-300-303</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Dr Laza Lazarević Clinic for Suicide Prevention</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0117777000">011-7777-000</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Singapore</p>
                            <img className= {flagClass} src={Flag_singapore} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a> for police emergency or  <a href="tel:995">995</a> for ambulance emergency </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.sos.org.sg/" target="_blank" rel="noreferrer">Samaritans of Singapore</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:18002214444">1800-221-4444</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.imh.com.sg/contact-us/" target="_blank" rel="noreferrer">Institute of Mental Health (Singapore)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:63892000">6389-2000</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.samhealth.org.sg/" target="_blank" rel="noreferrer">Singapore Association of Mental Health</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:18002837019">1800-283-7019</a></p>
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Slovakia</p>
                            <img className= {flagClass} src={Flag_slovakia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Linka dôvery (Prešov)</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:2051">2051</a> / <a href="tel:000 7731 000">000-7731-000</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://ipcko.sk/" target="_blank" rel="noreferrer">IPčko.sk</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Slovenia</p>
                            <img className= {flagClass} src={Flag_slovenia} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.telefon-samarijan.si/" target="_blank" rel="noreferrer">Zaupni telefon Samarijan in Sopotnik</a></p>
                            
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.e-tom.si/" target="_blank" rel="noreferrer">TOM – telefon za otroke in mladostnike</a></p>
                     
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.psih-klinika.si/koristne-informacije/klic-v-dusevni-stiski/" target="_blank" rel="noreferrer">Klic v duševni stiski</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:(01)520-99-00">(01) 520-99-00</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.drustvo-zenska-svetovalnica.si/o-nas" target="_blank" rel="noreferrer">Ženska svetovalnica – krizni center</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:+38631233211">+386-31-233-211</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>South Africa</p>
                            <img className= {flagClass} src={Flag_southafrica} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:10111">10111</a> for police emergency or <a href="tel:10177">10177</a> for ambulance emergency</p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://triangle.org.za/about/" target="_blank" rel="noreferrer">The Triangle project</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0217126699">(021) 712 6699</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>Suicide Crisis Line</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800567567">0800-567-567</a> or text <a href="sms:31393">31393</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Spain</p>
                            <img className= {flagClass} src={Flag_spain} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="hhttp://www.telefonodelaesperanza.org/" target="_blank" rel="noreferrer">Teléfono de la Esperanza</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:717003717">717-003-717</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Sri Lanka</p>
                            <img className= {flagClass} src={Flag_srilanka} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}><a href="https://srilankasumithrayo.lk/" target="_blank" rel="noreferrer">Sri Lanka Sumithrayo - Bandarawela</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0110572222662">011-057-2222662</a></p>
                   
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Saint Vincent and the Grenadines</p>
                            <img className= {flagClass} src={Flag_stvincent} alt="flag"/>
                            {BreakLineFlag()}
            
                            <p className={VWODetailsClass}>The Samaritans, St. Vincent</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:7844561044">(784) 456-1044</a></p>

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Sudan</p>
                            <img className= {flagClass} src={Flag_sudan} alt="flag"/>
                            {BreakLineFlag()}
                
                            <p className={VWODetailsClass}>Befrienders Khartoum</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:24911555253">(249) 11-555-253</a></p>
                 
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Sweden</p>
                            <img className= {flagClass} src={Flag_sweden} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://mind.se/hitta-hjalp/sjalvmordslinjen/" target="_blank" rel="noreferrer">Självmordslinjen (Suicide prevention hotline)</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.bris.se/" target="_blank" rel="noreferrer">BRIS - Barnens rätt i samhället (Children's right in society)</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116111">116-111</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Switzerland</p>
                            <img className= {flagClass} src={Flag_switzerland} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.143.ch/" target="_blank" rel="noreferrer">Die dargebotene Hand </a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:143">143</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Taiwan</p>
                            <img className= {flagClass} src={Flag_taiwan} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:119">119</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.mohw.gov.tw/cp-16-48244-1.html" target="_blank" rel="noreferrer">MOHW Suicide Prevention Line</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1925">1925</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.life1995.org.tw/" target="_blank" rel="noreferrer">Lifeline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1995">1995</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>
                        
                        <li>
                            <p className={countryNameClass}>Thailand</p>
                            <img className= {flagClass} src={Flag_thailand} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1554">1554</a> for ambulance emergency </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.samaritansthai.com/" target="_blank" rel="noreferrer">Samaritans of Thailand</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:027136793">(02) 713-6793</a> or English <a href="tel:027136791">(02) 713-6791</a></p>

                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Tonga</p>
                            <img className= {flagClass} src={Flag_tonga} alt="flag"/>
                            {BreakLineFlag()}

                            <p className={VWODetailsClass}>Lifeline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:23000">23000</a></p>
            
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Trinidad and Tobago</p>
                            <img className= {flagClass} src={Flag_trinidadtobago} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>Lifeline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:8686452800">(868) 645-2800</a></p>
            
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Turkey</p>
                            <img className= {flagClass} src={Flag_turkey} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> </p>

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Ukraine</p>
                            <img className= {flagClass} src={Flag_ukraine} alt="flag"/>
                            {BreakLineFlag()}
                        
                            <p className={VWODetailsClass}>Lifeline</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:7333">7333</a></p>
            
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>United Arab Emirates</p>
                            <img className= {flagClass} src={Flag_unitedarabemirates} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:112">112</a> or <a href="tel:911">911</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>For Indian Expats</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:80046342">800-46342</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}>National Committee for the Promotion of Mental Health</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:920033360">920033360</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>United Kingdom</p>
                            <img className= {flagClass} src={Flag_uk} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:999">999</a> or <a href="tel:112">112</a> </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.spbristol.org/nsphuk" target="_blank" rel="noreferrer">National Suicide Prevention Helpline UK</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:08006895652">0800-689-5652</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.samaritans.org/" target="_blank" rel="noreferrer">Samaritans</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:116123">116-123</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.thecalmzone.net/" target="_blank" rel="noreferrer">Campaign Against Living Miserably</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:0800585858">0800-58-58-58</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://giveusashout.org/" target="_blank" rel="noreferrer">Shout</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="sms:85258">85258</a></p>
                       
                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>United States</p>
                            <img className= {flagClass} src={Flag_usa} alt="flag"/>
                            {BreakLineFlag()}
                            <p className={VWODetailsClass}>National Emergency Number</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:911">911</a> or <a href="tel:211">211</a> for emergency referrals to social and community services </p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://suicidepreventionlifeline.org/" target="_blank" rel="noreferrer">National Suicide Prevention Lifeline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:18002738255"> 1-800-273-8255</a> or <a href="tel:988">988</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.veteranscrisisline.net/" target="_blank" rel="noreferrer">Veterans Crisis Line</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1-8002738255">1-800-273-8255</a> followed by <span className={highlightClass}>Pressing 1</span></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.imalive.org/" target="_blank" rel="noreferrer">IMAlive Crisis Chatine</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.crisistextline.org/" target="_blank" rel="noreferrer">Crisis Text Line</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="sms:741741">741741</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="http://www.samaritansusa.org/" target="_blank" rel="noreferrer">Samaritans USA </a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:1800273TALK.">1 (800) 273-TALK.</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://www.thetrevorproject.org/" target="_blank" rel="noreferrer">The Trevor Project</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:18664887386">1-866-488-7386</a> or <a href="https://www.thetrevorproject.org/get-help-now/" target="_blank" rel="noreferrer">chat</a> or text <a href="sms:678678">678-678</a></p>
                            {NextLine()}
                            <p className={VWODetailsClass}><a href="https://translifeline.org/" target="_blank" rel="noreferrer">The Trans Lifeline</a></p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:18775658860">1-877-565-8860</a></p>

                            <hr className={seperatorClass}/>
                        </li>

                        <li>
                            <p className={countryNameClass}>Uruguay</p>
                            <img className= {flagClass} src={Flag_uruguay} alt="flag"/>
                            {BreakLineFlag()}
                
                            <p className={VWODetailsClass}>Linea de Vida 24hrs</p>
                            <p className={VWODetailsClass}> <span className={contactClass}>Tel:</span>  <a href="tel:08000767">08000767</a></p>
                  
                            <hr className={seperatorClass}/>
                        </li>


                    </ul>

                </div>
            </div>
        </div>
    )
}

export default function FAQ() {

    return (
        <div>
            <BasePageTemplate content={Content()}/>

        </div>

    )
    
}
