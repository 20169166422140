import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/musclerelax_caption.jpeg'
import YoutubePage from '../../YoutubePage/youtube'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent(){
    var titleClass = classNames(classes.title , classes.center)
    const subheading = classNames (classes.subheadingcontent,  classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)

    return (
            <div  className={classes.divcontentblock}>


            {/* <div className = {classes.textcenter}> */}
            <div  className = {contentClass}>
                <br/><br/>
                <img src = {captionimg} className = {classes.imgcaption} alt="" ></img>
                <p className={creditClass}> Image Source <a href='https://unsplash.com/photos/_6HzPU9Hyfg' target='_blank' rel="noreferrer" >Zac Durant </a></p>
                <br/><br/><br/>
                <p className={titleClass} data-attribute="title"> Progressive Muscle Relaxation </p>
                <p>Progressive muscle relaxation is a method that helps to relieve tension. In progressive muscle relaxation, you tense a group of muscles as you
                    breathe in, and you relax from them as you breathe out. You can work on your muscle groups in a certain order. When your body is relaed, you will 
                    feel anxious.
                </p>
                <br/><br/><br/>
                <p className={subheading}>Steps to relax your muscle</p>
                <p  className={classes.bulletpoints}>1. <span className = {classes.italic}>Sit comfortably on a chair, with your both feet on the floor. Allow your hands to rest on your lap or on the arms of the chair. </span></p>
                <p  className={classes.bulletpoints}>2. <span className = {classes.italic}>Lean comfortably against the back rest. </span></p>
                <p  className={classes.bulletpoints}> 3. <span className = {classes.italic}> I will ask you to tighten a specific muscle group for a few seconds and then I will ask you to relax that muscle group.
                If tensing that particlar muscle hurts, you may skip that part or reduce the tensing of the muscle. </span></p>
                <p  className={classes.bulletpoints}>4. <span className = {classes.italic}>Play the video, close your eyes and listen to the instructions. </span></p>

                <br/><br/><br/>
                <p >This 14 minute video will guide you to relax your muscle groups progressively.</p>
                <p><a target = "_blank" href = 'https://youtu.be/5nF-lA7_6GA' rel="noreferrer">Audio Here</a></p>
                <div className = {classes.youtube}>
                    <YoutubePage id={"5nF-lA7_6GA"}/>
                </div>
                




            </div>



            <br/><br/><br/><br/>    

            </div>
    )


}


const MuscleRelaxationExercise =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default MuscleRelaxationExercise