import React from 'react'
//import classes from './privacy.module.css'
import classes from '../../css/main.module.css'
import classNames from 'classnames'

import MenuNavBar from '../navbar/MenuNavigationBar'

// import SideBar from '../../components/HamburgerMenu/hamburgermenu'
import Copyright from '../../components/Copyright/copyright'
import Footer from '../../components/Footer/footer'
// import NavSideItem from '../../components/NavSide/navside'
import SocialMedia from '../../components/SocialMedia/socialmedia'

import "../../css/sidebar_styles.css"

export default function ServicePage(props){
    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    // var contentClass = classNames (classes.divcontent)
    // var titleClass = classNames(classes.title)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    // var creditClass = classNames(classes.creditcontent)

    return (

        <div>
            <div data-attribute="title">
                {props.title}
            </div>

            <div data-attribute="topnav">
                <MenuNavBar/>
            </div>
            <div>
                {/* <p className = {titleClass}> {props.title}</p> */}

                <div data-attribute="content">

                    {props.content}

                <div className={classes.divcontentblock}> 
                    <div className={contentClass}>  
                        
                        <Footer/>
                        <br/><br/>
                        <SocialMedia/>
                        <Copyright/>
                    </div>
                </div> 
            </div>
        </div>
        </div>
    )
}