import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/suicide_logo.jpg'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function PageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    // var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source <a href='https://unsplash.com/photos/LQGQbssFs-M' target='_blank' rel='noreferrer'>Nick Fewings</a> </p>
                    
                    <p className={titleClass} > Dealing with Suicide Thoughts </p>
                    <br/><br/><br/>

                   
                    <p>Suicide is taking your own life due to
                         stressful life situations. Whether you are considering suicide 
                         or you know someone who is feeling suicidal, watch out for suicide warning signs and how to reach 
                         out for immediate help and professional treatment. You may be saving someone’s life or your own.

                    </p>
                    
                    <br/><br/><br/>
                    <p className={subheading}>Symptoms of Suicide Warnings</p>
                    <p>The list below is not comprehensive. Individual suicide symptoms may vary</p>
                    <br/>
                    <p  className={classes.bulletpoints}>1. <span className = {classes.italic}>Making statements like “I wish I am not here” or “I wish I were dead” or “I wish I am gone”</span></p> 
                    <p  className={classes.bulletpoints}>2. <span className = {classes.italic}>Sudden change in behavior </span></p>  
                    <p  className={classes.bulletpoints}>3. <span className = {classes.italic}>Mood swings</span> </p> 
                    <p  className={classes.bulletpoints}>4. <span className = {classes.italic}>Withdraw from social contact, no longer want to be contact and want to be left alone </span> </p> 
                    <p  className={classes.bulletpoints}>5. <span className = {classes.italic}>Preoccupied with death or dying </span> </p> 
                    <p  className={classes.bulletpoints}>6. <span className = {classes.italic}>Preoccupied with thoughts of harming self with drugs , alcohol , poisoning, stabbing </span> </p> 
                    <p  className={classes.bulletpoints}>7. <span className = {classes.italic}>Increase risk behavior </span> </p> 
                    <p  className={classes.bulletpoints}>8. <span className = {classes.italic}>Making a will suddenly or giving treasured possessions away </span> </p> 
                    <p  className={classes.bulletpoints}>9. <span className = {classes.italic}>Personality changes such as anxiety, low mood </span> </p> 

                 
                    <br/><br/><br/>
                    <p className={subheading}>Risk factors</p>
                    <p>The risk of suicide is higher in some of the situations</p>
                   
                    <br/>
                    <p>There are many types of emotions, and some emotional pain may overwhelm us and lead us to suicidal thoughts. The intense emotional pain may distort our thinking and narrow 
                        our perspective, making it harder to see possible solutions or seek help.</p>
                    <br/>
                    <p>Suicide crisis is temporary. Our emotion and thoughts fluctuate – they change. It is 
                        important to realize that the crisis is usually temporary. </p>

                    <br/><br/><br/>   
                    <p className={subheading}>What to do when you are facing suicidal thoughts</p> 
                    <p  className={classes.bulletpoints}>When you have decision fatigue, you may</p>
                    <br/>
                    <p  className={classes.bulletpoints}>&#8226;  Try to get through today rather than focus on the future</p>
                    <p  className={classes.bulletpoints}>&#8226;  Find someone whom you trust that you can talk to about your feelings</p>
                    <p  className={classes.bulletpoints}>&#8226;  Contact a health professional such as a counsellor, medical doctor</p>
                    <p  className={classes.bulletpoints}>&#8226;  Admit yourself into Accident and Emergency if you are in real danger of taking your own life</p>

               
                    <br/><br/><br/>  
                    <p className={subheading}>Go to Somewhere Safe</p> 
                    <br/>
                    <p>If you are feeling suicidal, go to somewhere that is safe for you. Stay away from the dangerous places (high rise buildings) or tools 
                        (drugs, alcohol, weapons) which you intend to harm yourself with. An example of safe place is</p>
                    <br/>
                    <p  className={classes.bulletpoints}>&#8226;  Hospital</p>
                    <p  className={classes.bulletpoints}>&#8226;  Crisis center</p>
                    <p  className={classes.bulletpoints}>&#8226;  Friend’s house</p>
                    <p  className={classes.bulletpoints}>&#8226;  Mental health or medical center</p>

                    <br/><br/><br/>  
                    <p className={subheading}>Don’t make a decision today</p> 
                    <br/>
                    <p>You do not need to act on your thoughts right now. Your option of taking your life away is not taken away from you. You 
                        can make this decision tomorrow or another day.</p>
                    <br/>
                    <p>
                    Try to focus to get through today, and not dwell on the future. You may feel that you are struggling today, 
                    but you will be able to cope better in a few days’ time. 
                    </p>
                    <br/><br/><br/>  
                    <p className={subheading}>If you are thinking of killing yourself…</p> 
                    <br/>
                    <p>If you have suicidal thoughts, it is important to promise to yourself that you will not do anything until you seek help. Be sure to talk to someone if you are having trouble coping with 
                        suicidal thoughts. Seek mental health professional or a local crisis helpline.</p>
                    <br/>
                    <p>Many people have experienced suicidal thoughts and survived. You are not alone in this.</p>
                    <br/><br/>
                    <p><span className={classes.italic}>Give yourself time to change, but remember to seek help.</span> </p>
                    <br/><br/>
                    <p><span className={classes.italic}>Give yourself time to change, but remember to seek help.</span> </p>
                    <br/>
                    <p>The list of suicide crisis hotlines is in  <a href='/suicide'>here</a>.</p>
                    
                    
                    




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const DecisionFatigueArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}


export default DecisionFatigueArticle