
function ArticleItemList(){

    const items = [
      { name: 'article', label: 'Articles', link : "/articles" },
      { name: 'depression', label: 'Alleviating Depressive Symptoms', link : "/articles/depressionovercome" },
      { name: 'bystander', label: 'Bystander Effect', link: "/articles/bystandereffect"},
      { name: 'dentalphobia', label: 'Dental Fear', link : "/articles/dentalfear" },
      { name: 'existential', label: 'Existential Crisis', link:'/articles/existential'},
      { name: 'hormones', label: 'Happy Hormones', link : "/articles/happyhormones" },
      { name: 'loneliness', label: 'Loneliness', link : "/articles/loneliness" },
      { name: 'steppingaway', label: 'Stepping Away', link : "/articles/steppingaway" },
      { name: 'jobsearch', label: 'Stress of Job Search', link : "/articles/joblost" },
      { name: 'cny', label: 'Struggle Over Festive Season (Lunar New Year)', link : "/articles/festivecny" },
      { name: 'sayno', label: 'Why And How We Should Learn To Say No', link : "/articles/sayno" },     
      

      
    ]
      return items
  }


export default  ArticleItemList