function SideBarItemList(){

    const items = [
  
      {category:"home", name: 'home', label: 'Home', link : "/" },
      { category:"about", name: 'AboutMe', label: 'About', link:'/therapist1'},
      {category:"counselling",  name: 'counselling', label: 'Why Counselling', link : "/counsel-reason" },
      { category:"counselling", name: 'service', label: 'Services', link: "/service"},
      { category:"articles", name: 'article', label: 'Articles', link : "/articles" },
      {category:"counselling",  name: 'register', label: 'Register', link : "/input-procedures" },
      {category:"counselling",  name: 'privacy', label: 'Privacy Policy', link : "/privacy" },
      { category:"faq", name: 'faq', label: 'FAQ', link : "/faq" },
      { category:"counselling", name: 'charge' , label: 'Charges', link: "/charge"},
      {category:"contact", name: 'contact', label: 'Contact', link : "/contact" },
      
    ]
      return items
  }


export default  SideBarItemList