
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-mental-health-america-(mha)-5543181.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>A small amount of stress may be beneficial and healthy to us. 
                Some positive pressure, such as setting a reasonable academic target or incremental sports improvement, plays a motivation to improve.</p>
            <br/><br/>
            <p> Excessive or long-term stress takes a toll on the body and has long-lasting effects. Common signs are high blood pressure, weight changes, and loss of sleep. 
                They can impact our emotions, such easily irritability, moodiness or frustration. We may have difficulty concentrating too.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Stress'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/man-holding-his-head-sitting-outside-5543181/'} imagelogo={imagelogo} credit={'Mental Health America'}/>
            
            <br/><br/><br/>
         

        </div>
    )
}