
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-karolina-grabowska-4195410.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Career indecision is the inability to specify an educational or occupational choice. 
                It can include focal problems, such as lack of information, or a combination of issues, such as choice anxiety and trait indecision.</p>


        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Career Indecision'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/opened-notebook-and-silver-pen-on-desk-4195410/'} imagelogo={imagelogo} credit={'Karolina Grabowska'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}