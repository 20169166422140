
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-andrea-piacquadio-3812739.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Anger is a natural and automatic response when we feel rejected, threatened or experience loss. 
                Anger can be a good thing. It can give you a way to express negative feelings, for example, or motivate you to find solutions to problems.</p>
            <br/><br/>
            <p> However, excessive anger can cause problems such as our health. Our blood pressure may increase, or we are unable to think straight. 
                Extreme anger may cause us to behave out of our norm and harm our physical and mental well-being.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Anger'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/woman-in-gray-tank-top-3812739/'} imagelogo={imagelogo} credit={'Andrea Piacquadio'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}