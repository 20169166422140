
import TableGenerate from './generatetable/generatetable'
import Characteristic from './data/strength_data'
import  StateCell from './data/strength_statecell'
import DataDescription from './data/strength_description'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import classNames from 'classnames'


import classes from '../../../css/main.module.css'


function PageContent(){


    var titleClass = classNames (classes.title)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var emailsubject = "Characteristic Strength Cards"
    return(

        <div className={classes.divcontentblock}>

            <div className={contentClass}>

                <p className={titleClass} data-attribute="title">Successful Changes Characteristic</p>
                <TableGenerate datasource={Characteristic.data} title={"hello"} datastate={StateCell.statedata} datatooltip={DataDescription.data} emailsubject={emailsubject}/>

            </div>

        </div>
    )

}


const Strength =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}

export default Strength