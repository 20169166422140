
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-brett-jordan-6140527.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Procrastination is delaying or putting off tasks until the last minute or past their deadline. 
                We may assume procrastination is simply a matter of willpower, but it is not.</p>
            <br/><br/>
            <p>There are various reasons why we procrastinate. Our anxiety, fear, or other negative emotions may cause us to delay our actions unnecessarily. 
                Other factors may make it challenging for us to exert self-control.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Procrastination'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/wood-typography-business-design-6140527/'} imagelogo={imagelogo} credit={'Brett Jordan'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}