

function randomInteger (){

    return (Math.floor(Math.random()*1000))

}


// console.log (randomInteger ())


module.exports.randomInteger=randomInteger