import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/screentime_logo.jpeg'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function PageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source : Unknown </p>
                    
                    <p className={titleClass} > Screen Time  </p>

                    <br/><br/><br/>
                  
                   
                    <p>It is common for many of us to spend a long time at the screen. According to eMarketer , US adults spend 3 hours 43 minutes on the mobile devices per day. 
                        That is like 50 days per year. In another study by RescueTime, we spend an average of 4.5 hours on our mobile device daily. Most people will check their phones on an 
                        average of 58 times daily, of which about 30 times are during working hours.</p>

                    <br/><br/><br/>
                    <p className={subheading}>How does this impact us ?</p>
                    <p>We are compulsive checking our phone frequently which may be hazardous to our productivity and interfere our activities. 
                        American Psychological Association found that this multitasking switching activities of mobile contains a high cost. By comparing how long it takes for people to get everything done, 
                        the psychologists can measure the cost in time for switching tasks. They also assess how different aspects of the tasks, such as complexity or familiarity, incur any extra time cost from switching. 
                        For younger participants in the study, they took longer to switch between more complex tasks. 
                        Time costs were also greater when participants switched to tasks that were relatively unfamiliar. The speed to switch is faster when they are familiar with the task.</p>
                    <br/>
                    <p>Many articles and research about the negativity of excessive screen time have been written. Some of the negative effects are :</p>

                    <br/>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>1. Obesity </span>- Too much time engaging in your mobile or computers increase sedentary lifestyle. This increases the risk factor for obesity when we sit down for too many hours to view the screen.</p> 
                    <p  className={classes.bulletpoints}><span className={classes.italic}>2. Sleep or insomnia </span>- The light emitting from electronic devices (that includes your monitor) interferes with our brain's sleep cycle. Watching a movie, playing a game or surfing social media just before sleeping can sometimes interfere with our ability to fall asleep quickly.</p>  
                    <p  className={classes.bulletpoints}><span className={classes.italic}>3. Anxiety and Depression </span>- The excessive amount of time spent on the screen can aggravate our mental well-being. In several science journals, it is found that screen time increases the likelihood of suicidal thoughts and depression.</p> 
                    <p  className={classes.bulletpoints}><span className={classes.italic}>4. Impaired socialising skills </span>- Although social media helps us to connect with our friends, but digital devices and its activity are largely solitary activity. We do not develop our real-life interactions when we are preoccupied with what is happening on the screen. This may increase aggressive behaviours when one is exposed to violent media especially for younger children.</p> 
                    <p  className={classes.bulletpoints}><span className={classes.italic}>5. Delayed learning in younger children </span>- Younger children learn better by physically exploring their surroundings and social interactions physically. When they are constraint to excessive screen time (including television), the alteration of the brain's structure can impact their learning abilities. In a research on young children, there is a delay learning by as much as 50% higher for every 30 minutes spent on watching TV , even for educational programs.</p> 
                    
                    <br/><br/><br/>  
                    <p className={subheading}>Screen Addiction</p>
                    <p>Addiction can be in many forms - they create biochemical consequences which result craving towards a reward. Our brain continuously seek to be stimulated by the 
                        pursuit of pleasurable activities in an addiction. The pleasurable activities will secrete a hormones call dopamine, one of the "feel good" hormones. 
                        The mind will experience a sense of euphoria which is the result of dopamine.</p>
                    <br/>
                    <p>The problem is that the body becomes desensitised to this feeling over time and seek for higher intensity. This is how addiction is developed, 
                        including screen addiction. Addiction may occur at any stage of life - an elderly can fall victim, similar to a toddler. 
                        Frequent exposure of a screen may be enough to trigger a neurochemical cascade of reactions. This may seem minor at first, 
                        but it will snowball if left unmitigated over time, leading to adverse consequences and lack of control. The end result is an addiction which may adversely impact our life.</p>
                    <br/><br/><br/>   
                    <p className={subheading}>Ways to fight screen addiction</p> 
                    <p>Getting rid of this habit may seem impossible for some. However, there are some steps that can reduce or eradicate your addiction.</p>
 
                    <br/>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>1. Limit what is on your home screen </span> - This includes your desktop. Put all the apps including games , social media away from your eyes. 
                        You can do this by hiding the icons in your desktop or putting them in the second or third page of your home screen in mobile. </p>
                  
                    <p  className={classes.bulletpoints}><span className={classes.italic}>2. Remove social media applications and restrict messaging </span> - This includes Linkedin , Facebook , Instagram , TikTok , Snapchat, 
                    Reddit and other social media. Social media can be very addictive. Remove the apps off from your mobile. When you have the urge to visit the social media platform but unable to access the app easily (if it had been uninstalled), 
                    pay attention for a few minutes where this compulsion is coming from. You will be surprised that you have discover something new about yourself.</p>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>3. Limit the time you can access your device - digital free time </span> - This is effective when you lay down certain time to be offline, 
                    for example, turn off the screen after 8pm. You can do activities that does not involve looking at the screen.</p>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>4. Take up a new physical activities such as sport or workout</span> - Sport is a good way to displace several bad habits. 
                    Pick a workout and do it when you are most likely to spend your time on screen after work, for example, bring your dog out, go to the gym, organise a team sport such as football, basketball, etc. 
                    For those who enjoy solo sports; jogging, swimming, trekking or cycling is one of the many activities that can take your mind off when the itch comes.</p>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>5. Turn off all notifications on your computer and mobile</span> - Turn off those distractions to reduce your prying eyes from the temptation.</p>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>6. Go on a digital-off social media (or any digital activities that you decide) retreat regularly </span>- I do this "turn-off Linkedin retreat" once a week every month. You can decide what to do in this retreat. It can be a 1-day , or 2-day or even a week. 
                    It will recharge your mind tremendously after the retreat - you feel restful and have a new perspective.</p>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>7. Devote 10 minutes to meditation or mindful silent activities</span> - Give yourselves at least 10 minutes of mindful activities. 
                    One way is to do breathing exercise or do sitting meditation. One can even do mindful walking. The silent practices let one root to the inner self and feel at peace instead of rushing to connect. 
                    This quiet reflection and breathing exercise is an excellent practice to retrain our mind from the adverse habit.</p>

                    <br/><br/>
                    <p>Technology screams information at us all day, and we want to feel connected. This increases our craving to the screen It creates a cacophony in our mind. Instead of giving us balance in life, 
                        an excessive screen time brings discord to our mind and body. It affects our health and social activities. The ability to control our own screen time has now become an important skill, 
                        especially working from home has become 
                        a norm, and blur the line of personal and work life. It is crucial for our mental well-being to be able to reject the need for online connections and information.</p>
                    <br/>
                    <p>If you are still struggling from screen time addiction and like to join me for mindful activities, do reach out to <a href='/contact'>us</a> for an appointment.</p>
                    
                    
                    




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const DecisionFatigueArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}


export default DecisionFatigueArticle