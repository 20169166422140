
function CounsellingItemList(){

    const items = [
  

      {category:"counselling",  name: 'counselling', label: 'Why Pyschotherapy', link : "/counsel/reason" },
      { category:"counselling", name: 'charge' , label: 'Fees', link: "/counsel/fees"},
      {category:"counselling",  name: 'privacy', label: 'Privacy Policy', link : "/counsel/privacy" },
      { category:"counselling", name: 'register', label: 'Register for Session', link: "/counsel/register"},
      { category:"counselling", name: 'service', label: 'Services', link: "/services"},
      { category:"counselling", name: 'suicide', label: 'Suicide Crisis Hotlines', link: "/suicide"},
      { category:"counselling", name: 'activities' , label: 'Activities', link: "/activities"},

      
    ]
      return items
  }


export default  CounsellingItemList