import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/bystander-caption.jpeg'
import YoutubePage from '../../YoutubePage/youtube'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.divcontent, classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent,  classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    return (


            <div  className={classes.divcontentblock}>


                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source  <a href='https://www.mindful.org/the-science-of-the-bystander-effect/' target='_blank' rel='noreferrer'>Mindful.org</a></p>
                    <p className={titleClass} > Bystander Effect </p>
                    <p>Bystander effect happens when the event discourages individuals from interceding in a non-dangerous event. The more the bystanders the less likely help will be rendered.</p>
                    <br/>
                    <p>Bystander Effect came from the murder of Kitty Genovese. Thirty-eight of her neighbours witness her murder without intervening. Hence Social psychologists Bibb Latané and John Darley popularized this incident as “bystander effect”.</p>
                    <br/>
                    <p>This 2 minute experiment video shows bystander effect in school when someone lies motionless on the ground and there is no one to help.</p>
                    <p><a target = "_blank" href = 'https://youtu.be/JcowGVd6GqY' rel="noreferrer">Video Here</a></p>
                    <div className = {classes.youtube}>
                        <YoutubePage id={"JcowGVd6GqY"}/>
                    </div>
                    
                    <br/><br/><br/>
                    <p className={subheading}>Four Factors of Bystander Effects</p>
                    <p  className={classes.bulletpoints}>Below are four mechanisms responsible for bystander effect. They are</p>
                    <p  className={classes.bulletpoints}>1. <span className = {classes.italic}>Diffused responsibility</span> : The responsibility to act is reduced with more bystanders.</p> 
                    <p  className={classes.bulletpoints}>2. <span className = {classes.italic}>Social cues</span>: Individual takes cue from the audience. If they are inactive, this will inhibit action from the individual.</p>  
                    <p  className={classes.bulletpoints}>3. <span className = {classes.italic}>Self-awareness</span>: How the action is perceived in front of the audience.</p>
                    <p  className={classes.bulletpoints}>4. <span className = {classes.italic}>Blocking</span>: Multiple bystanders action may block individual from taking action.</p> 
                    <br/>
                    <p>Opinions have stated that people have become less empathetic on social media. Moreover, youths feel more excited recording events to gain peer approval instead of intervention. This inhibit further assistance for sexual assault. Being online reduces moral obligation which leads to more suicides of victims.</p>
                    <br/>
                    <p>There are numerous articles on the effects of Bystander. Some of these impact is prevalent in cyberspace social media or games. </p>

                    <br/><br/><br/>
                    <p className={subheading}>Impact on Cyberspace</p>
                    <p>Bystander effect is common on some of gaming, social media and virtual world platforms. When there was cyberbullying, many gamers would not intervene but took snapshot of the incidents and posted in social media. Technology itself magnify the issue. There are examples where bystanders are only keen in video recording and do nothing to help. People are at ease in recording than living in the experience. They do not respond to the incident timely. Sometimes bystanders themselves may joined in the cyberbullying.</p>
                    <br/>
                    <p>The bystander effect will become more predominant in future with technology transformation. The encouragement of aggregated likes in social media and games serves people to observe and upload their records with no obligation to intervene. Inaction has become a norm for many users and this may be even worse in future. The fear of being ostracised and excluded from society leads to inhibit help given. This may be much worse on internet as news spread faster.</p>
                    <br/><br/>
                    
                    <p className={subheading}>Examples of Bystander Effect in Cyberspace</p>
                    <p   className={classes.bulletpoints}>Below are four mechanisms responsible for bystander effect. They are</p>
                    <p  className={classes.bulletpoints}>1. Bullying in Linkedin / Facebook / Instagram or other social media where someone post an attack on a particular person with an ageist comment (or mock at the appearance) and the rest just keep silent.</p> 
                    <p  className={classes.bulletpoints}>2. In multiplayer computer game, the team members stand by not doing anything while a player show sexual aggression towards a female player, and video recording it to be posted in social media.</p>  
                    <p  className={classes.bulletpoints}>3. Virtual world where a newly registered player is harassed by a seasoned player, while the crowd stands and watch idly, take snapshot and post in Instagram (or other social media).</p>

                    <br/><br/><br/>
                    <p className={subheading}>Impacts on young children</p>
                    <p>There are numerous research shown that when a child observe the bystander effect, he may feel that this is a social norm, and potentially grow up as a bully. Bullying has been found when bystander effect is prominent in schools. Bullying involves repetitive aggression or harassment directed at victims who have less power than bullies. Bullying also includes bystanders who observe bullying and can assume a range of roles that include “reinforcers” (provide support to bullies), “outsiders” (remain uninvolved with bullying), and “defenders” (help or support the victim). Bullies like an audience and would want to be perceived as winners and the victim as losers. The behaviors of bystanders can have important effects on their peers. Bandura's socio-cognitive theory argues that self-efficacy for a particular activity or action (ie, their beliefs in their capacity to act successfully) is related to their motivation and behavior. In accordance with this theory, researchers have found that bystanders' beliefs in their social self-efficacy (ie self-efficacy for defending and perceived collective efficacy to stop peer aggression) were positively associated with defending behavior and negatively associated with passive behavior from bystanders.</p>
                    <br/>
                    <p>In some studies shown, children who practise active bystander effect have higher chances ended up been a bully when they grow up.</p>
                    <br/><br/>

                    <p className={subheading}>How do we reduce the Bystander Effect to our child</p>
                    <p>Like adults, children are subjected to major obstacle to helping one another. That's when being part of a group paralyse people from coming to aid of someone in need , and this happens to young children.</p>
                    <br/>
                    <p  className={classes.bulletpoints}>1. <span className = {classes.bold}>Teach the model of altruism and helping to the young child. </span>Show them that you have courage to take charge of the situation and help others in need.
            </p> 
                    <p  className={classes.bulletpoints}>2. <span className = {classes.bold}>Try not to worry about the consequences of helping. </span>We may be worrying about the risk when we intervene and it is normal that we think that way. However, do we want to spend the rest of our life regretting whether our actions have saved someone else from harm ?</p>  
                    <p  className={classes.bulletpoints}>3. <span className = {classes.bold}>If we are in trouble, pick out one person in the crowd. </span>This can be done by making eye contact with witness. There is a social connection of "we-ness" when we make eye contact with a stranger.</p>
                    <p  className={classes.bulletpoints}>4. <span className = {classes.bold}>Be aware and be the change. </span>The first step to any change is to be aware of the problem and the conditions that resulted in it. Knowledge is power in here. When we see are aware, the next step is to make a decision to stop the bullying.</p> 
                    <p  className={classes.bulletpoints}>5. <span className = {classes.bold}>Empower the child. </span>Praise the child when he is helping others on the spot. Remind him of his heroism and altruism that he is make a positive difference in his world.</p> 
                    <p  className={classes.bulletpoints}>6. <span className = {classes.bold}>Encourage the child to talk to a trusted adult like parents , teacher when they witness an event that they are a bystander or not. </span>Encourage the young child to talk about the actions, thoughts and feelings. It can be hard if a young child is expected to stand out against bully when the rest of his peers do not. Continuous encouragement is crucial here.</p> 
                    <br/>

                    <p>By taking a stand against Bystander Effects such as stopping bullying or helping others in need, our child can model our behaviour. In this way, the incident of been bullied and turning into bully himself/ herself can be reduced in school.

            </p>
                    <br/>




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle