/*
UNDER CONSTRUCTION ! TO BE DEVELOP WITH 
ContactForm
generatetable

*/


import SMTP from './smtp'


function submitEmail (message,fromaddr,content,subject, result){
    // console.log ("message :" + message + "\n " + "address : " + fromaddr + "\n" + "subject : " + subject)

    function ReturnData(){

        result="HAHA"
    }
    return new Promise ((resolve,reject)=>{

        SMTP.sendEmail(message,fromaddr,content,subject).then ((data)=>{

            // console.log(message)
            // alert (this.successMessage)
            // this.setState({refresh: true})
            ReturnData()
            resolve (true)
        }).catch ((error)=>{

            // alert (this.errorMessage)
            // console.log ("erroir " + error)
            reject (error)
        })
    })


}

// const ExportEmail = {

//     submitEmail
// }



export default submitEmail