import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import captionimg from './resource/depression.jpeg'
// import YoutubePage from '../../YoutubePage/youtube'


// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

function pageContent (){

    var titleClass = classNames(classes.title )
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source <a href='https://unsplash.com/photos/E-b_VNmtGJY'  target='_blank' rel='noreferrer'>Gabriel</a> </p>
                    
                    <p className={titleClass} > Alleviating Depressive Symptoms  </p>
                    <p className={italiccontentClass}>What is depression , how do we alleviate some of the symptoms ?</p>
                    <p>Depression is a mood disorder that gives a persistent feeling of sadness and loss of interest. 
                        There are several types of depressive disorder, such as when one is diagnosed with major depressive disorder, it may impact how you think, 
                        feel and behave which may lead to behavioural and emotional problems. 
                        The daily activities may seem to be filled with inability or trouble to work on mundane tasks, and sometimes there may be self-harming thoughts.</p>
                    <br/>
                    <p>Depression is not a bout of blues, neither is it a snap of finger that you can just make it disappear. 
                        Depression may involve long-term treatment. It can be treated with medication, psychotherapy or both.</p>
                    
                    <br/><br/>
                    <p className={subheading}>Common types of depression</p>

                    <p  className={classes.bulletpoints}>1. <span className = {classes.italic}>Major Depression</span> : Classic depression where dark mood or loss of interest is consuming life, including pleasurable activities.</p> 
                    <p  className={classes.bulletpoints}>2. <span className = {classes.italic}>Persistent Depression Disorder</span>: Major depression that last more than 2 years but does not have the intensity of major depression.</p>  
                    <p  className={classes.bulletpoints}>3. <span className = {classes.italic}>Bipolar Depression Disorder</span>: Maniac induce depression where sufferers may go through high energy follow by depressive mood.</p>
                    <p  className={classes.bulletpoints}>4. <span className = {classes.italic}>Perinatal Depression</span>: Major and minor depressive mood that occur during the first 12 months after delivery.</p> 
                    <p  className={classes.bulletpoints}>5. <span className = {classes.italic}>PMDD</span>: Depression that is a severe form of premenstrual syndrome.</p>
                    <p  className={classes.bulletpoints}>6. <span className = {classes.italic}>Seasonal Affective Disorder</span>: Seasonal depression that is also known as winter depression due to lack of sunlight.</p> 
   
                    <br/>
                    <br/>
                    <p className={subheading}>Symptoms of Depression</p>
                    <p className={classes.bulletpoints}>This is not necessary indicative of depression. Depression disorder may have some of these symptoms.</p>
                    <p  className={classes.bulletpoints}>1. Depressed or irritable mood</p> 
                    <p  className={classes.bulletpoints}>2. Sleep problems (sleeping too much or too little)</p> 
                    <p  className={classes.bulletpoints}>3. Not interested in what you used to do and has low motivation</p> 
                    <p  className={classes.bulletpoints}>4. Excessive guilt or unrealistically low self-image</p> 
                    <p  className={classes.bulletpoints}>5. Low energy or disinterest in self-care (etc not brushing teeth or not showering anymore)</p> 
                    <p  className={classes.bulletpoints}>6. Concentration is deteriorating (etc at work or school)</p> 
                    <p  className={classes.bulletpoints}>7. Changes in appetite (etc eating too much or too little)</p> 
                    <p  className={classes.bulletpoints}>8. Agitation or severe anxiety/panic attacks</p> 
                    <p  className={classes.bulletpoints}>9. Suicidal thoughts or self-harm ( etc intentionally overdose medication)</p> 

                    <br/><br/>
                    
                
                
                    
                    <p className={subheading}>How do I know if I have depression ?</p>
                    <p>This is difficult as depression needs to go through diagnostic assessment by a trained personnel. Even when we may have depressive symptoms, 
                        it may not necessary mean that we have depression. Many people also confuse between depressive symptoms and sadness.</p>
                    <br/>
                    <p>Depression is sad, but it is more than that. It is a mental illness that involves several symptoms such as feeling exhausted all the time, 
                        no longer has any pleasurable activities in life, or thinking of ending it all. Even if we have these symptoms, 
                        it may not be depression. Sometimes it can be an extreme sadness where our love ones passed away - we may have mistaken it for depression.</p>
                    <br/>
                    <p>Regardless if it is a depression, or extreme sadness or mood affecting, 
                        it is important to seek professional help (counselling / psychiatrist) if it is impacting your daily lives. Sometimes, depression may involve medication.</p>

                    <br/><br/>
                    <p className={subheading}>Reducing depressive symptoms</p>
                    <p>Some of the below activities may help to reduce depressive symptoms. 
                        Every individual is unique in depressive symptoms and causation, so are the coping mechanisms. 
                        There is no one solution to reduce symptoms. Some of the examples (not exhaustive) are :</p>
                    <br/>
                    <p  className={classes.bulletpoints}>1. Take a walk especially in the morning. Give yourself some sunlight.</p> 
                    <p  className={classes.bulletpoints}>2. Solo exercise such as jogging, swimming, ridding a bike or trailing in the nearby park.</p> 
                    <p  className={classes.bulletpoints}>3. Gardening.</p> 
                    <p  className={classes.bulletpoints}>4. Reading your favourite book to distract yourself from unhappy thoughts.</p> 
                    <p  className={classes.bulletpoints}>5. Mindful breathing such as loving-kindness exercise.</p> 
                    <p  className={classes.bulletpoints}>6. Keep yourself busy with household chores.</p> 
                    <p  className={classes.bulletpoints}>7. Established a routine to keep yourself focus.</p> 
                    <p  className={classes.bulletpoints}>8. Take responsibility (etc taking care of pet, part time school / job) to have a sense of accomplishment.</p> 
                    <p  className={classes.bulletpoints}>9. Challenge negative views (etc beliefs that is harmful or negative).</p> 
                    <p  className={classes.bulletpoints}>10. Do something positively new (such as helping in a local charity community which you have never done before , or pick up a new sport or a new gene of music).</p> 
                    <p  className={classes.bulletpoints}>11. Create purpose or goals. Work towards it, hold on to it.</p> 
                    <p  className={classes.bulletpoints}>12. Eat healthily.</p> 
                    <br/><br/>
                    <p>If you suspect that you are suffering from depression, unable to cope with it, do seek help in your local community or from professional (psychotherapy / psychiatrist). 
                        You may also <a href="/contact">message me</a> if you want to book an appointment on coping with depression.</p>
                    <br/>
                    <p>Remember - it is OK <span className={classes.freebold}>not</span> to be OK. We are here for you.</p>

                    <br/>




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle