import React from 'react'
import {Link} from 'react-router-dom'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent (){

    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    
    return (
        <div>
            <br/>

            <div className={classes.divcontentblock}>
                
                
                <br/>

                <div className = {contentClass}>
                    <p className = {titleClass} data-attribute="title"> Articles</p>
                    <p className={classes.bulletpoints} >Loneliness (Jan 2023)<Link   to  ="/articles/loneliness"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} >Struggle Over Festive Season (Jan 2023)<Link   to  ="/articles/festivecny"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} >Why And How We Should Learn To Say No (Oct 2021) <Link   to  ="/articles/sayno"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Stepping Away (Aug 2021) <Link   to  ="/articles/steppingaway"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Dental Fear (May 2021) <Link   to  ="/articles/dentalfear"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Stress of Job Search (Mar 2021) <Link   to  ="/articles/joblost"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Alleviating Depression Symptoms (Feb 2021) <Link   to  ="/articles/depressionovercome"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Bystander Effect On Children (Dec 2020) <Link   to  ="/articles/bystandereffect"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Happy Hormones influence your happiness (Nov 2020) <Link   to  ="/articles/happyhormones"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Existential Crisis - What is it? (Oct 2020) <Link   to  ="/articles/existential"> Read </Link> </p>
                    <br/>


    



                    {/* To Be Added
                    <br/>
                    <p className={classes.bulletpoints} > Stepping Away <Link   to  ="/articles/steppingaway"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Man Box <Link   to  ="/articles/manbox"> Read </Link> </p> 
                    <br/>
                    <p className={classes.bulletpoints} > Decision Fatigue <Link   to  ="/articles/decisionfatigue"> Read </Link> </p> 
                    <br/>
                    <p className={classes.bulletpoints} > Dealing with Suicide Thoughts <Link   to  ="/articles/suicide"> Read </Link> </p> 
                    <br/>
                    <p className={classes.bulletpoints} > Screen Time <Link   to  ="/articles/screentime"> Read </Link> </p> 
                    <br/>
                    <p className={classes.bulletpoints} > Self-Care <Link   to  ="/articles/selfcare"> Read </Link> </p> 
                    */}


                    <br/><br/><br/><br/>      


                </div>    
            </div>
        </div>
    )
}

export default function  BlogPages() {

    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}
