
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-kat-jayne-568027.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Self-confidence is an individual’s trust in one’s abilities, capacities, judgments, or belief that they can successfully face day-to-day challenges and demands.</p>


        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Self-Confidence'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/adult-alone-anxious-black-and-white-568027/'} imagelogo={imagelogo} credit={'Kat Jayne'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}