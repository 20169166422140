import React from 'react'
import {Link} from 'react-router-dom'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent (){

    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    
    return (
        <div>
            <br/>

            <div className={classes.divcontentblock}>
                
                
                <br/>

                <div className = {contentClass}>
                    <p className = {titleClass} data-attribute="title"> Activities</p>
                    <br/>
                    <p className={classes.bulletpoints} > Attention Strength Development (Part 1)  <Link   to  ="/activity/attentionbasic"> Read </Link> </p>
  
                    <br/>
                    <p className={classes.bulletpoints} > Attention Strength Development (Part 2)  <Link   to  ="/activity/attentionadvance"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Characteristic Strength  <Link   to  ="/activity/strength"> Read </Link> </p>
                    <br/>
                    <p className={classes.bulletpoints} > Emotion Wheel  <Link   to  ="/activity/feelingwheel"> Read </Link> </p>
  
                    <br/>
                    <p className={classes.bulletpoints} > Loving Kindness  <Link   to  ="/activity/lovingkindness"> Read </Link> </p>
            
                    <br/>
                    <p className={classes.bulletpoints} > Progressive Muscle Relaxation <Link   to  ="/activity/musclerelax"> Read </Link> </p>
                   <br/>
                   <p className={classes.bulletpoints} > Values Cards  <Link   to  ="/activity/values"> Read </Link> </p>
                   <br/>
                   <p className={classes.bulletpoints} > Which Changes Impact You ?  <Link   to  ="/activity/discusschanges"> Read </Link> </p>
                   <br/>
                   <p className={classes.bulletpoints} > Which Of These Can We Talk About ?  <Link   to  ="/activity/discussvalue"> Read </Link> </p>
                    <br/>
                    <br/><br/><br/><br/>      


                </div>    
            </div>
        </div>
    )
}

export default function  BlogPages() {

    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}
