


 const listValueText = [

    "",
    "Acceptance",
    "Accuracy",
    "Achievement",
    "Adventure",

    "",
    "Attractiveness",
    "Authority",
    "Autonomy",
    "Beauty",

    "",
    "Caring",
    "Challenge",
    "Change",
    "Comfort",

    "",
    "Commitment",
    "Compassion",
    "Contribution",
    "Cooperation",

    "",
    "Courtesy",
    "Creativity",
    "Dependability",
    "Duty",

    "",
    "Ecology",
    "Excitement",
    "Faithfulness",
    "Fame",

    "",
    "Family",
    "Fitness",
    "Flexibility",
    "Forgiveness",

    "",
    "Friendship", 
    "Fun",
    "Generiosity",
    "Genuineness",

    "",
    "GodWill",
    "Growth",
    "Health",
    "Helpfulness",

    "",
    "Honesty",
    "Hope",
    "Humility",
    "Humor",

    "",
    "Independence",
    "Industry",
    "Inner Peace",
    "Intimacy",

    "",
    "Justice",
    "Knowledge",
    "Leisure",
    "Loved",

    "",
    "Loving",
    "Mastery",
    "Mindfulness",
    "Moderation",

    "",
    "Monogamy",
    "Non Conformity",
    "Nurturance",
    "Openness",

    "",
    "Order",
    "Passion",
    "Pleasure",
    "Popularity",

    "",
    "Power",
    "Purpose",
    "Rationality",
    "Realism",

    "",
    "Responsibility",
    "Risk",
    "Romance",
    "Safety",

    "",
    "Self-Acceptance",
    "Self-Control",
    "Self-Esteem",
    "Self-Knowledge",

    "",
    "Service",
    "Sexuality",
    "Simplicity",
    "Solitude",

    "",
    "Spirituality",
    "Stability",
    "Tolerance",
    "Tradition",

    "",
    "Virtue",
    "Wealth",
    "World Peace",
    "Others"

]


const listDescText = [
    "",
    "to be accepted as I am",
    "to be accurate in my opinions and beliefs",
    "to have important accomplishments",
    "to have new and exciting experiences",

    "",
    "to be physically attractive",
    "to be in charge of and responsible for others",
    "to be self-determined and independent",
    "to appreciate beauty around me",

    "",
    "to take care of others",
    "to take on difficult tasks and problems",
    "to have a life full of change and variety",
    "to have a pleasant and comfortable life",

    "",
    "to make enduring, meaningful commitments",
    "to feel and act on concern for others",
    "to make a lasting contribution in the world",
    "to work collaboratively with others",

    "",
    "to be considerate and polite toward others",
    "to have new and original ideas",
    "to be reliable and trustworthy",
    "to carry out my duties and obligations",

    "",
    "to live in harmony with the environment",
    "to have a life full of thrills and stimulation",
    "to be loyal and true in relationships",
    "to be known and recognized",

    "",
    "to have a happy, loving family",
    "to be physically fit and strong",
    "to adjust to new circumstances easily",
    "to be forgiving of others",

    "",
    "to have close, supportive friends",
    "to play and have fun",
    "to give what I have to others",
    "to act in a manner that is true to who I am",

    "",
    "to seek and obey the will of God",
    "to keep changing and growing",
    "to be physically well and healthy",
    "to be helpful to others",

    "",
    "to be honest and truthful",
    "to maintain a positive and optimistic outlook",
    "to be modest and unassuming",
    "to see the humorous side of myself and the world",

    "",
    "to be free from dependence on others",
    "to work hard and well at my life tasks",
    "to experience personal peace",
    "to share my innermost experiences with others",

    "",
    "to promote fair and equal treatment for all",
    "to learn and contribute valuable knowledge",
    "to take time to relax and enjoy",
    "to be loved by those close to me",

    "",
    "to give love to others",
    "to be competent in my everyday activities",
    "to live conscious and mindful of the present moment",
    "to avoid excesses and find a middle ground",

    "",
    "to have one close, loving relationship",
    "to question and challenge authority and norms",
    "to take care of and nurture others",
    "to be open to new experiences, ideas, and options",

    "",
    "to have a life that is well-ordered and organized",
    "to have deep feelings about ideas, activities, or people",
    "to feel good",
    "to be well-liked by many people",

    "",
    "to have control over others",
    "to have meaning and direction in my life",
    "to be guided by reason and logic",
    "to see and act realistically and practically",

    "",
    "to make and carry out responsible decisions",
    "to take risks and chances",
    "to have intense, exciting love in my life",
    "to be safe and secure",

    "",
    "to accept myself as I am",
    "to be disciplined in my own actions",
    "to feel good about myself",
    "to have a deep and honest understanding of myself",

    "",
    "to be of service to others",
    "to have an active and satisfying sex life",
    "to live life simply, with minimal needs",
    "to have time and space where I can be apart from others",

    "",
    "to grow and mature spiritually",
    "to have a life that stays fairly consistent",
    "to accept and respect those who differ from me",
    "to follow respected patterns of the past",

    "",
    "to live a morally pure and excellent life",
    "to have plenty of money",
    "to work to promote peace in the world",
    "other values not here"


]


var statedata = ({

    
    Acceptance:false,
    Accuracy:false,
    Achievement:false,
    Adventure:false,

    
    Attractiveness:false,
    Authority:false,
    Autonomy:false,
    Beauty:false,

    
    Caring:false,
    Challenge:false,
    Change:false,
    Comfort:false,

    
    Commitment:false,
    Compassion:false,
    Contribution:false,
    Cooperation:false,

    
    Courtesy:false,
    Creativity:false,
    Dependability:false,
    Duty:false,

    
    Ecology:false,
    Excitement:false,
    Faithfulness:false,
    Fame:false,

    
    Family:false,
    Fitness:false,
    Flexibility:false,
    Forgiveness:false,

    
    Friendship:false, 
    Fun:false,
    Generiosity:false,
    Genuineness:false,

    
    GodWill:false,
    Growth:false,
    Health:false,
    Helpfulness:false,

    
    Honesty:false,
    Hope:false,
    Humility:false,
    Humor:false,

    
    Independence:false,
    Industry:false,
    InnerPeace:false,
    Intimacy:false,

    
    Justice:false,
    Knowledge:false,
    Leisure:false,
    Loved:false,

    
    Loving:false,
    Mastery:false,
    Mindfulness:false,
    Moderation:false,

    
    Monogamy:false,
    NonConformity:false,
    Nurturance:false,
    Openness:false,

    
    Order:false,
    Passion:false,
    Pleasure:false,
    Popularity:false,

    
    Power:false,
    Purpose:false,
    Rationality:false,
    Realism:false,

    
    Responsibility:false,
    Risk:false,
    Romance:false,
    Safety:false,

    
    Acceptance:false,
    Control:false,
    Esteem:false,
    Knowledge:false,

    
    Service:false,
    Sexuality:false,
    Simplicity:false,
    Solitude:false,

    
    Spirituality:false,
    Stability:false,
    Tolerance:false,
    Tradition:false,

    
    Virtue:false,
    Wealth:false,
    WorldPeace:false,
    Others:false
})

export default {listDescText,listValueText,statedata}