import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent (){

    var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent)
    var titleClass = classNames(classes.title)

    return (

        <div>
            <br/>
            <div  className={classes.divcontentblock}>

                

                <div className = {contentClass}>
                    <p className = {titleClass} data-attribute="title">Confidentiality</p>

                    <br/>

                    
                    <p>Your privacy is important to us, and we comply with the <a href='https://sso.agc.gov.sg/Act/PDPA2012' target="_blank" rel="noreferrer">Personal Data Protection Act 2012 (PDPA)</a>, which governs our usage of information.</p>
                    <br/>
                    <p>Confidentiality is important to ensure sessions' effectiveness and develop trust. Trust is crucial in any session. 
                        Through this therapeutic relationship and trust, you will be more open to the feelings and problems, 
                        enabling the psychotherapist to provide the necessary help to cope with these issues.</p>
                    <p>This means the discussion in the sessions is not disclosed to anyone, 
                        that includes your spouse, partners, children, parents, superiors, coworkers, friends, school, workplace, strangers etc.</p>
                    <br/>
                    <p className = {subheading}>Exceptions</p>
                    
                    <p className={classes.bulletpointsTextLeft}>Confidentiality is enforced as long as you do not :</p>
        
                    <p className={classes.bulletpoints}>  1. Hurt yourself (etc., suicide).</p>
                    <p className={classes.bulletpoints}>  2. Harm someone, abuse a child or elderly.</p>
                
                    <br/><br/><br/><br/>    


                </div>
            </div>

        </div>
    )
}

export default function Privacy () {


    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}



