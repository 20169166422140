import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
  } from "react-accessible-accordion";

  // Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

function pageContent (){

    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent)
    var titleClass = classNames(classes.title)
    return (
    <div>

        <div  className={classes.divcontentblock}>
            <br/><br/>
            

            <div className = {contentClass}>
                <p className = {titleClass}>Frequently Asked Questions</p>
                <br/>
                
                <Accordion  allowMultipleExpanded>
                    <AccordionItem uuid={1}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who is suitable for psychotherapy/counselling?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Psychotherapy/counselling is for anyone who is facing difficulties in coping. 
                        Please refer to this <a href= "/counsel/reason">"Why Psychotherapy?"</a>.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={2}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What is the difference between psychotherapy and counselling?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Counselling focuses on short-term goals which involve solving immediate concerns, 
                        while psychotherapy focuses on both the long-term and short-term goals of the client’s issues.
                        
                        </p>
                        <p className={classes.faqcontent}>
                        Counselling is a type of therapeutic method that aids a person in overcoming unwanted emotions and behavioural habits. 
                        The fundamental goal of counselling is to enable the client reveal his concerns to a professional with extensive knowledge of the human mind,
                         so that the client can receive help in adapting to normal and efficient everyday routines.   
                        </p>
                        <p className={classes.faqcontent}>
                        Psychotherapy is a psychological developmental process that will assist the client in gaining a logical and clear understanding of their core attitudes, 
                        beliefs and recurrent emotions, patterns of thinking, behaviour, and personality, 
                        which may have resulted in specific challenges, poor quality of life, and interpersonal relationships.   
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={3}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What should I expect for the first session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        The first session is to learn more about you, your problems and your goals.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={4}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Will I be given advice?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        We are not there to advise, tell you what to do or judge you in any way. 
                        Psychotherapy aims to help you make decisions; only you know how you feel in any situation. 
                        The psychotherapist may sum up what he understands you have been saying so that he can help you to form a plan of action.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={5}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What does a psychotherapist do if he does not give me advice?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Listening and empathizing are part of psychotherapy while working together on overcoming problems, changing behaviours and increasing happiness. 
                        The psychotherapist also ensures you have clarified the problem areas on your terms and helps you to decide what steps you want to take. 
                        We will always let the session proceed at your pace.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem uuid={6}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What format do psychotherapy sessions take?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Generally, the first session is about building rapport and understanding your issues. 
                        We seek to understand your objectives and the reason for counselling. 
                        We can work together to devise a treatment plan that is best recommended for you moving forward. 
                        Sometimes a single session may be all you need; however, it will generally take several sessions to get to know you and advise you on the best coping strategies to help you achieve lasting sustainable outcomes.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>




                    <AccordionItem uuid={7}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How long is each session? 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        A session is 50 minutes long, and 10 minutes is for administrative purposes.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem uuid={8}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What if I don't feel comfortable with the session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Our rapport is crucial in therapy. You should feel comfortable working on the problems that are important to you. 
                        We can explore and discuss this while working together. 
                        You may also bring up the discomforting feelings to the psychotherapist during the session.
                        
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem uuid={9}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Do I need to turn on video during online session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        It is essential to observe non-verbal cues as part of the session. This is to ensure that we can provide better support and rapport.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={10}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What applications do you use for online session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Currently, <a target="_blank" href= "https://zoom.us/download" rel="noreferrer">Zoom</a> or  <a target="_blank" href= "https://www.skype.com/en/get-skype" rel="noreferrer">Skype</a>  are used. They can run on both your desktop machine and your mobile.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={11}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What happens if I lose connection over the live chat?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        If you lose connection with the live chat, please click on the web link in the email that we have sent out. You should be able to rejoin the session.
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem uuid={12}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Will my employer or school be informed that I am attending session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        If you attend the session on your own choice or a self-referral basis, the information will not be shared with anyone.
                        
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={13}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What happen if I see you in the public?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        There is no need to acknowledge one another unless you choose to do so. You can ignore the psychotherapist, and he will not think that you are being rude. 
                        You can say hello, and he will acknowledge you without bringing up any conversation from the session.
                        
                        </p>
                    </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem uuid={14}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How do I make a an appointment?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Please reach out under  <a href= "/contact">"Contact"</a>. </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    {/* <AccordionItem uuid={15}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the fees for each session ?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Please refer to <a href= "/counsel/fees">"fees"</a> for more information.
                         </p>
                    </AccordionItemPanel>
                    </AccordionItem> */}

                    <AccordionItem uuid={16}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What is the client's responsibility to make the sessions effective?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Success in this therapy takes two hands to clap, and you are responsible for making this a success.
                         </p><br/>
                         <p className={classes.faqcontent}>1. Make a serious effort to work cooperatively and actively on what is discussed during the session.</p>
                         <p className={classes.faqcontent}>2. Explain your problems and goals as clearly and accurately as possible.</p>
                         <p className={classes.faqcontent}>3. Attend scheduled sessions, as skipping sessions will slow your progress.</p>
                         <p className={classes.faqcontent}>4. Give forethought to what you want to discuss before each session.</p>
                         <p className={classes.faqcontent}>5. Inform if there is any significant change in your life.</p>
                         <p className={classes.faqcontent}>6. Discuss any negative thoughts or feelings you have about the therapy. Only then can we take action to improve the session and ensures
                          you get the help you need.
                         </p>

                         
                        
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={17}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How do I register for the first psychotherapy session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        Please refer to <a href= "/counsel/register">"Registering For A Session"</a> for more information.
                         </p>
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={18}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the top three mental health disorders in Singaporeans?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        The Singapore Mental Health Study (SMHS; 2011), conducted by the Institute of Mental Health (IMH) of Singapore of over 6600 persons. 
                        Using the figures published in a media release by IMH, the top three mental disorders are: </p>
                        <br/>
                        <p>Major Depression : 16.6%</p>
                        <p>Alcohol abuse : 13.2 % </p>
                        <p>Specific phobia : 12.5 % </p>

                        
                    </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid={19}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What if I am suicidal or having suicidal thoughts?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={classes.faqcontent}>
                        It is important to receive immediate professional intervention if you are having suicidal thoughts or planning to act on them. 
                        Please check yourself into the hospital Accident & Emergency (Singapore). You can also call: <br/><br/> 1. <a target="_blank" href= "https://www.sos.org.sg/" rel="noreferrer">Samaritans of Singapore (SOS)</a>  - 1800 221 4444 <br/> 
                        2. <a target="_blank" href= "https://www.imh.com.sg/Pages/Contact-Us.aspx" rel="noreferrer">IMH Emergency Helpline</a> - 6389 2222 <br/>
                        3. Emergency Medical Service - 995
                        <br/><br/>
                        Suicide crisis helplines contacts for other countries are in <a  href= "/suicide" rel="noreferrer">Worldwide Suicide and Crisis Hotlines</a>.<br/>
                         </p>


                         
                        
                    </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>


                <br/><br/><br/><br/>    

            </div>
        </div>
      </div>
    );
}

export default function FAQ() {

    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )
    
}
