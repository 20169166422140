import  {Component} from 'react'
// import axios from 'axios'
import classes from '../../../css/main.module.css'
import Input from '../../Input/input'
// import Button from '../../Button/button'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import CallMe from '../../CallMe/callme'
import { Redirect } from 'react-router-dom';
import classNames from 'classnames'

import SMTP from '../../../containers/server/smtp'
import EmailButton from '../../EmailButton/emailbutton'

import * as dataTypes from '../../../containers/inputFormType'





/*eslint no-useless-concat: "off"*/
class ContactForm extends Component{
    
    
    titleClass = classNames(classes.title)
    subheading = classNames (classes.bioheading)
    subtitle = classNames (classes.bold, classes.subtitle)
    contentClass = classNames (classes.divcontent, classes.defaultfont)
    signature = classNames (classes.signature)
    
    successMessage = "Thank you. I will contact you soon. "
    errorNotStatus200="There is an error sending the form. Please use the email instead."
    errorMessage = "There is an error sending the form. Please email directly to enquiry@thetalktherapy.com ."

    


    state = {

        inputDisplayState : false,
        inputValue : "",
        name : "",
        email : "",
        subject : "",
        content : "",
        refresh : false,
        api_wakeup : true

    }

    componentDidMount(){
        // this.detectWakeUp() 

        
        SMTP.detectWakeUp().then((data)=>{

            // console.log ("t :"+ data)
            this.setState({api_wakeup:true})
        }).catch ((error)=>{

            this.setState({api_wakeup:false})

        })
       


    }
    SetContentMessage(){


        // message={"Fake message"} email={this.state.email} content={'Name :' + this.state.name + '\n' + this.state.content} subject={this.state.subject}

        var ContentMessage={}
        
        // if (this.validateForm()){
        //     ContentMessage = {

        //     email:this.state.email,
        //     message: 'Fake message',
        //     content:'Name :' + this.state.name + '\n' + this.state.content,
        //     subject:this.state.subject
        //     }
        // }

        ContentMessage = {

            email:this.state.email,
            message: 'Fake message',
            content:'Name :' + this.state.name + '\n' + this.state.content,
            subject:this.state.subject
        }

        return ContentMessage
    }

    submitEmail(events){
        // let validateCondition = this.validateForm()

        var message_validation=""
        var ErrorSuccessMessage=""

        // console.log ("Result email :" + JSON.stringify(events))

        if (events.success){

            

            //ErrorSuccessMessage = "Email sent! " + events.message
            ErrorSuccessMessage = this.successMessage
            alert ("Email sent successfully")
            this.setState({refresh: true})

        }else {

            
            if (!events.validation.validation){
               
                if (!events.validation.email)
                    message_validation=message_validation + ` \n` + `Email error : Please fill in the correct email. Only valid email will be considered.`

                if (!events.validation.message)
                    message_validation= message_validation + ` \n` + "Message error : This field cannot be empty. Please fill it up"

                if (!events.validation.content)
                    message_validation= message_validation + ` \n` + "Description error : This field cannot be empty. Please fill it up"
                
                if (!events.validation.subject)
                    message_validation= message_validation + ` \n` + "Subject error : This field cannot be empty. Please fill it up"

                    ErrorSuccessMessage =   "I am not able to send the email. " + " \n" + "Please fill up correctly field below - \n" + message_validation 
            }else {

                    ErrorSuccessMessage = this.errorMessage
            }

            //ErrorSuccessMessage = "Email failed : " + events.message + " \n" + "Please fill up correctly below \n" + message_validation 
            alert (ErrorSuccessMessage)

        }


    }
    pageContent(){

        return (
    
            <div className={classes.divcontentblock}>


                <div className = {this.contentClass}>
                    {
                        (this.state.refresh===true)?
        
                        <Redirect to='/' />
        
                        :
                            <div >
                                <br/>
                                
                                <p className = {this.titleClass} data-attribute="title"> Contact</p>
                                <div>
                                    <p>For <span className={classes.bold}> <a href='/counsel/register'>registration</a></span> or enquiries that contain </p> 
                                    <p><span className={classes.bold}>confidentiality information</span>, </p>

                                    <p>please use the email (below) instead</p>
                                    <br/>
                                    <p><a href= "mailto: enquiry@thetalktherapy.com">enquiry@thetalktherapy.com</a></p>
                                    <br/>
                                    <p className={classes.creditcontent}>Please note we will <span className={classes.freebold}>only</span> respond to valid email address.</p>
                                    <br/><br/><br/>
                                </div>
                                
                                <div>
                                    {this.state.api_wakeup ?
                                    
                                        

                                        <div className = {classes.form} >
                                    
                                    
                                    
                                        


                                            <div >
                                                <label className = {classes.labelsForm}> Name</label> 
                                                <Input title={"How do I address you"} data={this.readName.bind((this))}/>
                                                

                                                <br/>
                                                <label className = {classes.labelsForm}> Email</label> 
                                                <Input title={"Email Address to reach you"} data={this.readEmail.bind((this))}/>

                                                <br/>
                                                <label className = {classes.labelsForm}> Subject</label> 
                                                <Input title={"Subject"} data={this.readSubject.bind((this))}/>

                                                <br/>
                                                <label className = {classes.labelsForm}> Descriptions</label> 
                                                <Input content={true} title={"What would you want to find out ?"} data={this.readContent.bind((this))}/>
                                                
                                                {/* {(!this.state.inputcreated) ?
                                                    this.setState({inputcreated : true})

                                                    :
                                                    null
                                                } */}
                                                

                                            </div>
                                                
                                                <div >
                                                    {/* <div>
                                                        <p className = {classes.emailAddressContact}> Email address  <a href= "mailto: enquiry@listening-ears.org">enquiry@listening-ears.org</a> </p>
                                                        {}
                                                        {this.state.inputcreated ?
                                                            <p> tess</p>

                                                            :
                                                            null
                                                        }
                                                        
                                                    </div> */}
                                                    
                                                   
                                                    {/* <Button title="Submit" type="FORM" click = {this.submit.bind(this)} /> */}
                                                    
                                                    <EmailButton title="Submit"  type="FORM" ContentMessage={this.SetContentMessage()} successMessage={this.successMessage} errorMessage={this.errormessage} sendresult={this.submitEmail.bind(this)}/>
                                                    
                                                    <br/><br/><br/><br/><br/>
                                                    

                                                </div>

                                                
                                            

                                        </div>

                                        :

                                        null
                                    }

                                </div>

                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/>                                      
                                    <div>
                                        
                                        <p className={classes.italic}>Alternatively you may consider an introductory call. This is a short call and you can ask questions. </p>
                                        <br/>
                                        <CallMe/>
                                    </div>
                                    <br/><br/><br/>
        
                            </div>
                
                }
                    
                </div>
                
            </div>
        )
    }



    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateForm (){

        

        this.setState({errorcode : ""})
        this.setState({errormessage : "bbbb"})

        if (this.checkBlank(this.state.name)===false){

            this.errormessage="You need to fill in your name."

            return false
        }

        if (this.checkBlank(this.state.email)===false){

            this.errormessage="You need to fill in your email."

            return false
        }

 

        if (this.validateEmail(this.state.email)===false){

            this.errormessage="Please fill in the correct email."

            return false
        }
        if (this.checkBlank(this.state.subject)===false){

            this.errormessage="You need to fill in the subject."

            return false
        }

        if (this.checkBlank(this.state.content)===false){


            this.errormessage="We need more info in the description."

            return false
        }

        return true


    }
    checkBlank (text){



        if (text.length ===0)
            return false
    }
    urlRead (urlData){
        this.setState({inputValue : urlData})

        // console.log ("urlData : " + urlData.target.value)

    }
    saveData (data, type){
        setTimeout(()=>{
            if (type === dataTypes.NAME ){
                
                    this.setState({name : data.target.value})
        
                    // console.log ("name : " + this.state.name)
                    
            }
            if (type === dataTypes.EMAIL ){
                
                this.setState({email : data.target.value})
    
                // console.log ("email : " + this.state.email)
                
            }   

            if (type === dataTypes.SUBJECT ){
                    
                this.setState({subject : data.target.value})

                // console.log ("subject : " + this.state.subject)
                
            }
            if (type === dataTypes.CONTENT ){
                    
                this.setState({content : data.target.value})

                // console.log ("content : " + this.state.content)
                
            }
        },100)
    }
    readName (data ){
        


        this.saveData (data, dataTypes.NAME) 

    }

    readSubject (data){

        this.saveData (data, dataTypes.SUBJECT) 
    }

    readEmail(data){

        this.saveData (data, dataTypes.EMAIL) 
    }

    readContent(data){

        this.saveData (data, dataTypes.CONTENT) 

        this.setState({inputcreated: true})
    }

    

    testClickButton (urldata){

        alert ("shit : " + this.state.inputValue)

        this.setState ({inputDisplayState:true})
    }



    submit(){

        this.setState ({inputDisplayState:true})

        // const api = '/1.0'
        // const header = '/therapy'
        // const url = process.env.REACT_APP_CONTACTFORM_URL

        // const urlstring = url + api + header + '/wakeup' 
        // console.log (urlstring)


        // const mykey = JSON.stringify({key : 'fake key lar!'})


        // axios.post (url + api + header + '/wakeup1', mykey).then ((info)=>{
        //     console.log ("Info of wakeup1 :" + JSON.stringify(info.data))

        // })
     
 

       
        // let data = {
        //     message: 'test fake email from react',
        //     fromaddr : this.state.email,
        //     content : 'Name :' + this.state.name + '\n' + this.state.content,
        //     subject : this.state.subject,
        //     key : this.API_KEY
        //     // key: "abc"
        // }


        let validateCondition = this.validateForm()

        if (!validateCondition){
            //alert ("Not fill up properly!")
            //alert ("Error " + this.state.errormessage)
            // alert ("Please fill up all the fields")
            alert (this.errormessage)
        } else{
            //alert ("content : " + JSON.stringify(this.state))
            var message='test fake email from react'
            var fromaddr=this.state.email
            var content='Name :' + this.state.name + '\n' + this.state.content
            var subject=this.state.subject

            SMTP.sendEmail(message,fromaddr,content,subject).then ((data)=>{

                // console.log(message)
                alert (this.successMessage)
                this.setState({refresh: true})
            }).catch ((error)=>{

                alert (this.errorMessage)
                // console.log ("erroir " + error)
            })

            // axios.post (url +api+header + '/sendemailpost', {},{params:data}).then((infor)=>{

            //     // console.log ("test fake email ")
            //     // console.log (infor)
            //     if (infor.data.status!=200){
            //         alert ("There is an error sending the form. Please use the email instead. - " + infor.data.status)
            //     }else{

            //         alert ("Thank you. I will contact you soon ")
            //         this.setState({refresh: true})
            //     }

                
            //     //this.setState({refresh: true})
            // }).catch ((err)=>{
            //     // console.log ("ERROR!")
            //     // console.log (err)
            //     alert ("There is an error sending the form. Please use the email instead.")
            // })

            // window.location.reload()
        }



        //let validateCondition = this.checkBlank(this.state.name)


    }
   
    // detectWakeUp(){

    //     const api = '/1.0'
    //     const header = '/therapy'
    //     const url = process.env.REACT_APP_CONTACTFORM_URL

    //     axios.get(url +api+header + '/wakeup' ).then ((info)=>{

    //         // console.log ("wake up ")
    //         //alert ("wake up! + " + JSON.stringify(info))
    //         // console.log (info)
    //         this.setState({api_wakeup:true})

    //         return true
    //     }).catch((error)=>{

    //         //alert ("ERROR! :" + error)
    //         this.setState({api_wakeup:false})
    //         return false
    //     })

        
    // }

    render (){

        return (

            <div>
                <BasePageTemplate content={this.pageContent()}/>

            </div>
        )

    }


}


export default  ContactForm