import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/fatigue_logo.jpeg'
import img_mark from './resource/fatigue_mark.png'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function PageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source <a href='https://unsplash.com/photos/sxQz2VfoFBE' target='_blank' rel='noreferrer'>Christian Erfurt</a> </p>
                    
                    <p className={titleClass} > Decision Fatigue  </p>

                    <br/><br/><br/>
                    <img src = {img_mark} className = {classes.imgcaption} alt="caption"></img>
                    <p className={creditClass}> Image Credit : Unknown</p>
                   <br/><br/><br/>
                   
                    <p>If you feel this way when choosing your new clothes, not wanting to make decisions 
                        what to wear for the day, or feeling the fatigue to think about, or even making the decision on, the office wardrobe 
                        today, you may have a decision fatigue.</p>
                    <br/>
                    <p>Though Mark Zuckerberg may have a different reason for wearing the same 
                        Tees every day, but for many of us, we struggle to make a decision on many necessities in life, such as what to wear 
                        today. Another example is what is for lunch every day? Sometimes we dread the thought of making the decision.</p>
                    <br/><br/><br/>
                    <p className={subheading}>What is Decision Fatigue ?</p>
                    <p>Decision fatigue is the idea after making many decisions, and a person's ability to make any more additional decisions becomes worse. 
                        The psychological effects of decision fatigue varies, 
                        sometimes it lends to avoiding behavior, impulsive decision (such as shopping), or difficulty in making the right decisions.</p>
                    <br/>
                    <p>We have finite resources in our brain. Visualize our decision capability like a battery. It is finite and limited. Our brain 
                        can get more fatigue after making many decisions. This may impair us further from making the decisions to help us, whether it is 
                        minor or major. 
                        The decision battery energy runs low, and making decisions seem getting harder.</p>
                    <br/><br/><br/>
                    <p className={subheading}>An Example of Decision Fatigue</p>
                    <p>This is a real case study of what happen to a decision of parole officers</p>
                    <br/>
                    <p>Three prisoners in Israel appeared before a parole board consisting of a judge, criminologist and a social worker. All have completed 
                        two-thirds of their sentences, but parole is only granted to only one of them. Guess which one is granted the parole :</p>
                    <br/>
                    <p  className={classes.bulletpoints}>Case 1 (heard at 8:50 a.m.): An Arab Israeli serving a 30-month sentence for fraud.</p> 
                    <p  className={classes.bulletpoints}>Case 2 (heard at 3:10 p.m.): A Jewish Israeli serving a 16-month sentence for assault.</p>  
                    <p  className={classes.bulletpoints}>Case 3 (heard at 4:25 p.m.): An Arab Israeli serving a 30-month sentence for fraud.</p> 
                    <br/>  
                    <p>You may have guess correctly - It is case 1. Although all three have served the same sentence for the same case (fraud), 
                        but only case 1 received parole. Case 2 and 3 are denied - they were asking parole for wrong time of the day.</p>
                    <br/>
                    <p>A closer example at home is looking at your school. You may notice many university professors wear the similar attire 
                        / clothes throughout the week. You may have done it too during a period of your life. Or you may have eaten at 
                        the same cafeteria and even ordering the same meal for many days. The thought of not making the decision send you 
                        into an autopilot - whichever is the convenient choices at that moment with minimum usage of the decision battery.</p>
                    <br/><br/><br/>   
                    <p className={subheading}>Adverse Impact of Decision Fatigue</p> 
                    <p  className={classes.bulletpoints}>When you have decision fatigue, you may</p>
                    <br/>
                    <p  className={classes.bulletpoints}>&#8226;  have brain fog</p>
                    <p  className={classes.bulletpoints}>&#8226;  feel physically tired</p>
                    <p  className={classes.bulletpoints}>&#8226;  neglect to take care of self</p>
                    <p  className={classes.bulletpoints}>&#8226;  increase stress</p>
                    <p  className={classes.bulletpoints}>&#8226;  avoid making decisions, partially or altogether</p>
                    <p  className={classes.bulletpoints}>&#8226;  make impulse or risky decisions</p>
                    <p  className={classes.bulletpoints}>&#8226;  have procrastination, delay in making a decision</p>
                    <p  className={classes.bulletpoints}>&#8226;  not able to make a decision, feeling indecisive or impasse</p>
                    <br/>
                    <p className={classes.italic}>Basically the 4 signs of decisions fatigue are</p>
                    <p className={classes.italic}>Procrastination, avoidance, indecision and impulsiveness</p>
                    <br/><br/><br/>  
                    <p className={subheading}>How to reduce Decision Fatigue</p> 
                    <p className={classes.bulletpoints}>There are few ways we can reduce this drain such as</p>
                    <br/>
                    <p  className={bulletitalicClass}>1. Avoid making decision when we are tired</p>
                    <p  className={classes.bulletpoints}>We tend to make poor decisions when we are tired. Our ability to concentrate is 
                    impaired. When we are too tired, postpone the decision. 
                    When we are too hungry, grab a snack and decide later. Rest well before making a decision.</p>
                    <br/>
                    <p  className={bulletitalicClass}>2. Making important decision early </p>
                    <p  className={classes.bulletpoints}>Once we have already made important decisions, we will be more effective 
                    to take actions and not be bother by those decisions. Make the decision early so that you will not tax your precious brain power.</p>                  
                    <p  className={classes.bulletpoints}>Making decision early in the morning helps to reduce fatigue. An example is I make important decisions during my run in the early morning. 
                        By the end of the run, all of the important decisions for the day will have be done, and I do not need to worry the bigger items.</p>
                    <p  className={classes.bulletpoints}>However, this may not make sense for night owls. Some may find making decisions at night easier. It is 
                        important to set a period where you can make decisions, so that you can get them out of the way.</p>
                    <br/>
                    <p  className={bulletitalicClass}>3. Set a Time Limit</p>
                    <p  className={classes.bulletpoints}>Decide how long you will need to make a decision. If we do not set a time limit, we may 
                    end up procrastination and suffer a decision fatigue along the way. Set a dedicated time to make a decision. Seal the decision 
                    when the time is up. I usually set a clock or tell myself 
                    before dinner at 6pm, I will have to make a decision. Once we are resolute in the time setting, we will not be overwhelm 
                    with decisions after that.</p>
                    <br/>
                    <p  className={bulletitalicClass}>4. Having a process for decision making</p>
                    <p  className={classes.bulletpoints}>Setting a process on how to make decision helps in reducing the fatigue. One way is using 
                    a decision matrix. A decision matrix helps to analyze the choices by listing factors, options and score the importance of decisions 
                    according to weights. Decision 
                    matrix helps to measure the pros and cons. This may sound complicated, but once you tried it out a few times, you will get the hang of it.</p>
                    <p className={classes.bulletpoints}>A decision matrix also helps to prevent impasse. You can have a default decision at each matrix, this helps when you are unable to 
                        come to a decision. A default decision may buy time till you are more ready to make one.</p>
                    <br/>
                    <p  className={bulletitalicClass}>5. Decrease your options</p>
                    <p  className={classes.bulletpoints}>One way to reduce decision fatigue , is to have lesser options. This is 
                    like the first example - Mark Zuckerberg. If we do not have the options to choose between what to wear today, we 
                    will not be having the wariness of thinking what to wear today. Or set a fix menu for the week what to have. 
                    In this way you already have limited your options to this and established a routine (point 6).</p>
                    <br/>
                    <p  className={bulletitalicClass}>6. Routines</p>
                    <p  className={classes.bulletpoints}>Routines are great way to reduce decision fatigue. It is like an autopilot - 
                    it eliminates the need to make decisions. We know what we need to do in a routine without spending brain power in 
                    thinking about it. Routine is a good way to eliminate many of the decisions if one is overwhelmed with decision making.</p>
                    <p className={classes.bulletpoints}>You may consider putting less important decisions as routine. An example if 
                    you find making decision what to eat for lunch during weekday a chore, you may consider setting a routine of what 
                    to eat each day for a week and rotate it every week. You can apply this to other areas such as wardrobe if you find it 
                    a hinderance to think about it.</p>
                    <br/>
                    <p  className={bulletitalicClass}>7. Don't aim for perfect decision</p>
                    <p  className={classes.bulletpoints}>Aiming for the perfect decision will overwhelm us. Instead of going for the perfect 
                    decision, we can work out small incremental decisions. It does not need to be perfect , it needs to be imperfect. 
                    Working on imperfect goals and not worrying too much about wrong decisions gives us a freedom from impasse.</p>
                    <br/><br/><br/>  
                    <p className={subheading}>Conclusion</p> 
                    <p>Not everyone faces decision fatigue. Many enjoys making decisions whether it is a minor or major on a daily basis. 
                        Some look forward to make decisions that like seemingly tough to make such as what to wear today, or what to eat today.</p>
                    <p>Each of us are unique. For a decision that one may look forward to make, the other may find want to avoid making them.</p>
                    <br/>
                    <p>However, if you are struggling in making decision or avoiding them, and this behaviour is adversely impacting your life, 
                        we can have a session together to help you. Contact <a href='/contact'>us</a> for an appointment.</p>
                    
                    
                    




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const DecisionFatigueArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}


export default DecisionFatigueArticle