import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/loneliness.png'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'

function pageContent(){
    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)

    return (

        <div  className={classes.divcontentblock}>
            <div  className = {contentClass}>


                 <br/><br/>
                <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                <p className={creditClass}> Source : Image copyright : Unknown</p>
                <br/>
                <p className={titleClass} > Loneliness </p>
              
                
                <br/><br/><br/>

                <div>
                    <p>We feel lonely from time to time. The feelings of loneliness are personal, and everyone’s experience will differ.</p>
                    <br/>

                    <p>One important aspect of loneliness is that our need for social connectedness and relationships is not met. 
                        However, loneliness is not always the same as being alone.</p>
                    <br/>
                    <p>Sometimes even with social contact, you may feel a sense of loneliness. Some of you may be familiar that when you are in a group of friends or party, this feeling of loneliness still lingers, 
                        even when you are interacting in the crowd.</p>
                    <br/>

                    <p>A common misconception of loneliness is that some may describe it as solitude or being alone. However, it is different. Loneliness is a state of mind; it causes people to feel empty, alone and unwanted. Solitude, on the other hand, is voluntary, where people enjoy spending time alone, maintain positive social relationships, and balance time to be alone or with connections. In summary, loneliness is a feeling of unwanted isolation or lack of connection, whether alone or surrounded by others. 
                        Solitude, on the other hand, is a choice.</p>
                    <br/>
                    
                    <p>Research studies have suggested that loneliness is associated with social isolation, poor social skills, introversion and depression. The cause of loneliness includes several situational variables- not one person will have the same variables. Loneliness can also be a symptom of depression or lead to low self-esteem / confidence. People with low self-esteem may believe that they are unworthy- this can lead to isolation and long-term loneliness. 
                        Sometimes this can be attributed to personality, such as introvert.</p>
                    <br/>
                    
                    <br/><br/>
                    <p className={subheading}>Working through feelings</p>
                    <br/>
                    <p>It is important to acknowledge the loneliness you may feel at the moment. 
                    When you have acknowledged this, helping yourself and getting help from a professional will be the next step.</p>
                    <br/>
                    <p  className={classes.bulletpoints}>1. <span className={classes.bold}>Acknowledge your feelings of loneliness.</span> This will signal you to take action.</p> 
                    <p  className={classes.bulletpoints}>2. <span className={classes.bold}>Make time to connect with friends, families and the community.</span> Take some time to foster a relationship and be proactive about it.</p>  
                    <p  className={classes.bulletpoints}>3. <span className={classes.bold}>Pick up hobbies that you enjoy.</span> Hobbies can reignite your passions and tap into your creativity. It helps to reinforce connections with a social network.</p> 
                    <p  className={classes.bulletpoints}>4. <span className={classes.bold}>Outdoor activities.</span> Go for a walk down the street or in the mall. Take your dog out for a walk, hike and trek.</p> 
                    <p  className={classes.bulletpoints}>5. <span className={classes.bold}>Exercise.</span> Exercise helps to improve mental/physical health, making one feel more energized and connected to communities and friends. Join an exercise group in your gym or running or trails.</p>  
                    <p  className={classes.bulletpoints}>6. <span className={classes.bold}>Treat yourself a bit.</span> Have a delicious meal, a glass of wine, a hot bath, get a message, or do a yoga class. Whatever helps you to relax and be present helps to restore and pull yourself out of the rut.</p> 
                    <p  className={classes.bulletpoints}>7. <span className={classes.bold}>Seek help.</span> Seek help from your local mental health professional. </p> 
                    <br/><br/>
                    <p>Loneliness is widespread. It is associated with other comorbidities, and it can happen to anyone. Looking out for others and ourselves when facing loneliness is crucial. 
                        There are also professional mental help services in your local communities such as ours, whom you can reach out.</p>
                    <br/>
                    
                    
                </div>
            </div>

        </div>
    )
}

const LonelinessArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default LonelinessArticle