import  {Component} from 'react'
//import classes from './youtube.module.css'
import YouTube from 'react-youtube';
import classes from '../../css/main.module.css'
import classNames from 'classnames'

// const ByStanderEffectArticle =()=>{
class YoutubePage extends Component {
   contentClass = classNames (classes.center)
    // youtubeID = 'JcowGVd6GqY'
    // constructor (props){

    //     super(props)

    // }

    state= {

        youtubeID : this.props.id
       }
    render() {
        const opts = {
          height: '390',
          width: '640',
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
          },
        };
     
        return (
          <div className={this.contentClass} data-attribute="youtube">
              <YouTube videoId={this.state.youtubeID} opts={opts} onReady={this._onReady} />
          </div>
          );
      }
     
      _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
}

export default  YoutubePage