import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
// import captionimg from './resource/attentionbasic_logo.jpeg'
import Cards from './Cardbox/Cards'
import CardType from './Cardbox/cardtype'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    return (


            <div  className={classes.divcontentblock}>


  
                <div  className = {contentClass}>
                    <br/><br/>
                
                    <p className={titleClass} data-attribute="title">What changes impact you?</p>
                   
                    <p>Please adhere to additional instructions from your counsellor before you start on this activity.</p>
                   
                    
                    <Cards CardType={CardType.MI}/>
                 

                </div>
                


                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle