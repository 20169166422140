import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'

function pageContent(){
   // var subheading = classNames (classes.subheadingcontent)
    var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var italicBulletPointsTextLeft = classNames(classes.bulletpointsTextLeft, classes.italic)
    var titleClass = classNames(classes.title)

    return (
            <div>
                <br/>
                <div  className={classes.divcontentblock}>
                    
            

                    <div className = {contentClass}>
                        <p className = {titleClass} data-attribute="title">Fees</p>

                        <p className={classes.bulletpointsTextLeft}> Psychotherapy is a process that require time, dedication and effort. Our clients usually return for following-up sessions.  </p>
                        <br/><br/><br/>
                        <div className={subtitle}>
                            <p className={classes.bulletpointsTextLeft}> Fees and Duration</p>
                        </div>
                        <p className={classes.bulletpoints}>Individual online session - SGD $80 </p>
                        <p className={classes.bulletpoints}>Individual in person (face to face) session - SGD $120 </p>
                        <p className={classes.bulletpoints}>Couple online session - SGD $120</p>
                        <p className={classes.bulletpoints}>Text counselling (WhatsApp) session - SGD $50 (first session), subsequent session SGD $40 </p>
                        <p className={classes.bulletpoints}>Couple in person (face to face) session - SGD $180 </p>
                        <p className={classes.bulletpoints}>Walk and Therapy (face to face) session - SGD $140 </p>
                      
                        
                        <br/><br/><br/>
                        <div>
                        <p className={classes.bulletpoints}>6 session packages is a cost effective way and ensuring a more consistent progress. All 6 sessions must be used within 6 months of the first session. Do reach out to us for more details in <a href= "/contact">here</a>. </p>
                        </div>
                        <br/><br/><br/>
                        <p className={classes.bulletpoints}> We have sliding scale if you have financial difficulties (unemployed, lower income, students, retiree etc) which include heavy subsidies. Please <a href= "/contact">email</a> for more details.</p>
                        <br/><br/><br/>
                        <p className={classes.bulletpoints}>Payment of counselling fees must be made within 48 hours from the time of your booking for an appointment to be confirmed. If payment is not received within 48 hours from the time of your booking, the appointment slot will be released.</p>
                  
                        <br/>
                        <p className={classes.bulletpoints}> Face-to-face is only available in Singapore.</p>
                        <br/><br/>
                        <p > Do reach out to us <a href= "/contact">here</a>. </p>
            
                        <br/><br/><br/><br/>    
                        
        
                    </div>
                </div>
            </div>
    )

}

export default function Charges(){

    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>
    )
}