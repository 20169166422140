import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/matchstick.jpeg'
// import YoutubePage from '../../YoutubePage/youtube'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.divcontent, classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent,  classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var quotesClass = classNames(classes.subheadingcontent, classes.italiccontent)
    const BULLET = '\u2022'


    return (


            <div  className={classes.divcontentblock}>


                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source : Unknown</p>
                    <p className={titleClass} > Stepping Away </p>
                    <br/><br/><br/>
                    <p>We have been there. Whether it is moving to a new department or in a unfamiliar role, we have encounter problems that seem impossible to solve.</p>
                    <br/>
                    <p>A <a target="_blank" href="https://www.glassdoor.com/blog/average-employee-takes-earned-vacation-time-glassdoor-employment-confidence-survey-q1-2014/" rel="noreferrer">Glassdoor survey</a> shows that only half (51%) of his or her eligible vacation time/paid time off, and 61% of them admit doing some work.</p>
                    <br/>
                    <p>When is your last breakthrough idea ? Were you sitting at your desk while bashing your laptop or twittering ? Were you trying to see an answer actively or trying to solve your problem?</p>
                    <br/>
                    <p>Or were you somewhere else entirely away from the computer or outdoors , with friends , 
                        enjoying the stroll in the park listening to nature? Or having a daydream and hardly doing anything at all ?</p>
                    <br/>
                    <p>I believe most of you , have your best idea when you are not actively chasing them - when you are away 
                        from your screen , relax and allowed your mind to idle.</p>
                    
                    
                    <br/><br/><br/>
                    <p className={subheading}>Our Mind</p>
                    <p>The human mind is mysterious and we have yet to fully understand - it is very easy to get into tunnel-vision , 
                        but that inhibits our creativity and problem solving skills. Our forehead lies our prefrontal cortex which 
                        is responsible for focusing on the task and searching for existing information with different combinations 
                        from other memories. It is huge workload on the prefrontal cortex. 
                        And that is one of the reason why you struggle to come up with new ideas to solve your problems.</p>
                    <br/><br/><br/>
                    <p className={subheading}>Downtime</p>        
                    <p>We need to plan to idle to bring forth our creativity. Research have shown that even when we are 
                        relaxing or daydreaming , our brain do not shut down. It replenishes attention ,
                         motivation and hence improves productivity AND creativity.</p>
                    <br/>
                    <p className={quotesClass}>"Down time is where we become ourselves ... a hiatus that passes for boredom 
                        but is really the quiet moving of the wheels inside that fuel creativity" - Anna Quindlen</p>

                    <br/>
                    <p>Leaving the comfort of your daily rituals occasionally will help you to regain your inner self and recharge it. 
                        You will regain your cheerfulness and be more mentally healthier, which you haven't allow yourself previously</p>

                    <br/><br/><br/>
                    <p className={subheading}>Stepping Away</p>
                    <p>To get back our strength , motivation and creativity , we need a real vacation.</p>
                    <br/>
                    <p>Stepping away allows the mind to take a break from the current tasks at hand, and focus on something else or relax (idle). 
                        It is good to take a rest as many of the senses that is stressing out by doing this activities</p>
               
                    <br/>
                    <p className={classes.bulletpoints}>Some of the activities that you can do to take a break from the routines and recharge yourselves are :</p>
                    <p  className={classes.bulletpoints}> {BULLET} Meditation or Yoga</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Exercising </p> 
                    <p  className={classes.bulletpoints}> {BULLET} Nap </p> 
                    <p  className={classes.bulletpoints}> {BULLET} Breathing with nature - walking in the park </p> 
                    <p  className={classes.bulletpoints}> {BULLET} Volunteering at a local charities </p> 
                    <p  className={classes.bulletpoints}> {BULLET} Doing a totally non related tasks at different location </p> 

                    <br/><br/><br/>
                    <p className={subheading}>Good Habits To Recharge At Work</p>  
                    <p>If you are at work , the following might help</p>  
                    <p  className={classes.bulletpoints}> {BULLET} Off your work social communications when it is possible. If not , state the time where you have your "personal" time and you are away at this time slot.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Do not chat about work during lunch.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Reduce working on weekend. If needed , keep a day (out of Saturday and Sunday) for a time off from work to recharge.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Work on meaningful project , like your company sponsored charity.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Take a short break and walk to the pantry or washroom. Grab a cookie or do something like swinging your arms / legs for a 5 minute stretch.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Take a 5 minutes break for every 1 hour screen time. Look elsewhere like a plant on your desk.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Put your cell phones at the desk and walk to a space devoid of technology.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} A 15 minutes nap during lunch time.</p> 
                    <p  className={classes.bulletpoints}> {BULLET} Break your work routine occasionally - instead of the usual way of doing task A , why not try alternative method and see if it works?</p> 

                    <br/><br/><br/>

                    <p>Stepping away is a valuable skillset. Able to do this enable us to take a true vacation and recharge fully. A mind that is fully recharge and relax have the highest capacity 
                        for creativity and motivated to spring back to perform the task more efficiently.</p>
                    <br/><br/><br/>

                    <p>If you are struggling with your thoughts and emotions, do drop us a <a href="/contact">message</a>. 
                        We can have a session together in finding how to cope better with your struggles.</p>
                        


                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle