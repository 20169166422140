import EmailButton from './emailbutton'


export default function Page (){

    var email="johnny@hotmail.com"
    //var email=""
    var name = "Johnny Crabman"
    var content = "I need help. Can you help me?"
    var subject = "Enquiry counselling"
    var message = "Fake Message"

    var successMessage = "Message Send!"
    var errormessage = "Something wrong with the send!"

    content = 'Name :' + name + '\n' + content
    var ContentMessage={

        email:email,
        message:message,
        content:content,
        subject:subject
        
    }

    /*
    var ContentMessage={

        email:email,
        name:name,
        content:content,
        subject:subject
        
    }*/

    function result (events){

        var message_validation=""
        var ErrorSuccessMessage=""

        console.log ("Result email :" + JSON.stringify(events))

        if (events.success){

            

            ErrorSuccessMessage = "Email sent! " + events.message
            alert ("Email sent successfully (FINAL)!!!")

        }else {

            
            if (!events.validation.validation){
               
                if (!events.validation.email)
                    message_validation=message_validation + " \n" + "Email"

                if (!events.validation.message)
                    message_validation= message_validation + " \n" + "Message"

                if (!events.validation.content)
                    message_validation= message_validation + " \n" + "Content"
                
                if (!events.validation.subject)
                    message_validation= message_validation + " \n" + "Subject"
            }

            ErrorSuccessMessage = "Email failed : " + events.message + " \n" + "Failure validation : " + message_validation 
            alert ("Email sent failed (FINAL)!!")
            
        }

        console.log (ErrorSuccessMessage)
    }


    return (

        <div>
                <p>Test Email Button</p>

                {/* <EmailButton title="Susbmit" validation={true} ContentMessage={ContentMessage} type="FORM" message={"Fake message"} email={email} content={'Name :' + name + '\n' + content} subject={subject} successMessage={successMessage} errorMessage={errormessage} sendresult={result.bind(this)}/> */}
                <EmailButton title="Susbmit" validation={true} ContentMessage={ContentMessage} type="FORM" successMessage={successMessage} errorMessage={errormessage} sendresult={result.bind(this)}/>
        </div>
    )
}