
import {Route} from 'react-router-dom'

import BlogPages from '../components/IndividualPage/BlogPage/blogs'
import WhyCounsellingPage from '../components/IndividualPage/WhyCounselling/whycounselling'
import PrivacyPage from '../components/IndividualPage/PrivacyPolicy/privacy'
import ServicesPage from '../components/IndividualPage/Services/services'
import ChargesPage from '../components/IndividualPage/Charges/charges'
import NormalChargesPage from '../components/IndividualPage/Charges/charges_normal'
//import BioCounsellor1 from '../components/IndividualPage/AboutMe/biocounse1'
// import ContactMe from '../components/IndividualPage/Contact/contact'
import FAQ from '../components/IndividualPage/FAQ/faq'
// import ContactMe from '../components/IndividualPage/ContactForm/contactform'
import ContactGoogle from '../components/IndividualPage/ContactForm/contactform_google'
import Processes from '../components/IndividualPage/Intake/process'

import Page from '../components/page/page'

import Article_ExistentialCrisis from '../components/IndividualPage/Articles/existentialcrisis'
import Article_HappyHormones from '../components/IndividualPage/Articles/happyhormones'
import Article_BystanderEffect from '../components/IndividualPage/Articles/bystandereffect'
import Article_DepressionOvercome from '../components/IndividualPage/Articles/depressionovercome'
import Article_JobLost from '../components/IndividualPage/Articles/joblost'
import Article_DentalFear from '../components/IndividualPage/Articles/dentalfear'
import Article_SteppingAway from '../components/IndividualPage/Articles/steppingaway'
import Article_ManBox from '../components/IndividualPage/Articles/manbox'
import Article_DecisionFatigue from '../components/IndividualPage/Articles/decisionfatigue'
import Article_Suicide from '../components/IndividualPage/Articles/suicide'
import Article_ScreenTime from '../components/IndividualPage/Articles/screentime'
import Article_SelfCare from '../components/IndividualPage/Articles/selfcare'
import Article_SayNo from '../components/IndividualPage/Articles/sayno'
import Article_CNY from '../components/IndividualPage/Articles/cny'
import Article_Loneliness from '../components/IndividualPage/Articles/loneliness'

import ServiceGriefPage from '../components/IndividualPage/Services/ServiceType/grief'
import ServiceAnxietyPage from '../components/IndividualPage/Services/ServiceType/anxiety'
import ServiceSuicidePage from '../components/IndividualPage/Services/ServiceType/suicide'
import ServiceStressPage from '../components/IndividualPage/Services/ServiceType/stress'
import ServiceDepressionPage from '../components/IndividualPage/Services/ServiceType/depression'
import ServiceLonelinessPage from '../components/IndividualPage/Services/ServiceType/loneliness'
import ServiceRelationshipPage from '../components/IndividualPage/Services/ServiceType/relationship'
import ServiceConfidencePage from '../components/IndividualPage/Services/ServiceType/confidence'
import ServiceAngerPage from '../components/IndividualPage/Services/ServiceType/anger'
import ServiceTraumaPage from '../components/IndividualPage/Services/ServiceType/trauma'
import ServiceCareerIndecisionPage from '../components/IndividualPage/Services/ServiceType/career'
import ServiceProcrastinationPage from '../components/IndividualPage/Services/ServiceType/procrastination'
import ServiceAssertivenessPage from '../components/IndividualPage/Services/ServiceType/assertiveness'

import Activities_Main from '../components/IndividualPage/Activities/blogs'
import Activities_MuscleRelaxation from '../components/IndividualPage/Activities/musclerelax'
import Activities_LovingKindness from '../components/IndividualPage/Activities/lovingkindness'
import Activities_Strength from '../components/IndividualPage/Activities/strength'
import Activities_Values from '../components/IndividualPage/Activities/valuelife'
import Activities_AttentionBasic from '../components/IndividualPage/Activities/attentionbasic'
import Activities_AttentionAdvance from '../components/IndividualPage/Activities/attentionadvance'
import Activities_FeelingWheel from '../components/IndividualPage/Activities/feelingwheels'
import Activities_AskACT from '../components/IndividualPage/Activities/ask_act'
import Activities_AskMI from '../components/IndividualPage/Activities/ask_mi'

import Suicide from '../components/IndividualPage/Suicide/main_suicide'


import AboutIvan from '../components/IndividualPage/AboutMe/biocounse1'
import AboutChoonBoon from '../components/IndividualPage/AboutMe/biocounse2'
import AboutJonathon from '../components/IndividualPage/AboutMe/biocounse3'
import AboutMarie from '../components/IndividualPage/AboutMe/biocounse4'

//Temp
import TempEmailSend from '../components/EmailButton/page'
import ContactTemp from '../components/IndividualPage/ContactForm/contactform_new'

const RoutePage =(props)=>{
    // console.log (props)
    return (
        
        <div>

                <Route path ="/home" exact render= {()=> <h1> Home</h1>}></Route>
                {/* <Route path ="/home1"  render= {()=> <h1> Home 1</h1>}></Route> */}
                <Route path = "/" exact component={Page} />
                <Route path = "/counsel/register" exact component={Processes} />
                <Route path = "/register" exact component={Processes} />
                <Route path = {"/articles"} exact component={BlogPages} />
                <Route path = "/counsel/reason" exact component={WhyCounsellingPage} />
                <Route path ="/counsel/privacy" exact component={PrivacyPage}/>
                <Route path="/services" exact component={ServicesPage}/>
                <Route path="/counsel/feesprobono" exact component={ChargesPage}/>
                <Route path="/counsel/fees" exact component={NormalChargesPage}/>
                {/* <Route path ="/about/ivan" exact component={BioCounsellor1}/> */}
                {/* <Route path ="/contactold" exact component={ContactMe}/> */}
                {/* <Route path ="/contact" exact component={ContactMeGoogle}/> */}
                <Route path ="/contact" exact component={ContactGoogle}/>
                
                
                <Route path="/faq" exact component={FAQ}/>
                <Route path = "/suicide" exact component={Suicide} />
                {/* <Route path = "/input-procedures" exact component={Processes} /> */}
                {/* <Route path="/contactform" exact component={ContactForm} /> */}


                <Route path='/services/grief' exact component={ServiceGriefPage}/>
                <Route path='/services/anxiety' exact component={ServiceAnxietyPage}/>
                <Route path="/services/suicide" exact component={ServiceSuicidePage}/>
                <Route path="/services/stress" exact component={ServiceStressPage}/>
                <Route path="/services/depression" exact component={ServiceDepressionPage}/>
                <Route path="/services/loneliness" exact component={ServiceLonelinessPage}/>
                <Route path="/services/relationship" exact component={ServiceRelationshipPage}/>
                <Route path="/services/confidence" exact component={ServiceConfidencePage}/>
                <Route path="/services/anger" exact component={ServiceAngerPage}/>
                <Route path="/services/trauma" exact component={ServiceTraumaPage}/>
                <Route path="/services/career" exact component={ServiceCareerIndecisionPage}/>
                <Route path="/services/procrastination" exact component={ServiceProcrastinationPage}/>
                <Route path="/services/assertiveness" exact component={ServiceAssertivenessPage}/>

                
                
                {/* article */}
                <Route path = "/articles/existential" exact component={Article_ExistentialCrisis} />
                <Route path = "/articles/happyhormones" exact component={Article_HappyHormones} />
                <Route path = "/articles/bystandereffect" exact component={Article_BystanderEffect} />
                <Route path = "/articles/depressionovercome" exact component={Article_DepressionOvercome} />
                <Route path = "/articles/joblost" exact component={Article_JobLost} />
                <Route path = "/articles/dentalfear" exact component={Article_DentalFear} />
                <Route path = "/articles/steppingaway" exact component={Article_SteppingAway} />
                <Route path = "/articles/sayno" exact component={Article_SayNo} />
                <Route path = "/articles/manbox" exact component={Article_ManBox} />
                <Route path = "/articles/decisionfatigue" exact component={Article_DecisionFatigue} />
                <Route path = "/articles/suicide" exact component={Article_Suicide} />
                <Route path = "/articles/screentime" exact component={Article_ScreenTime} />
                <Route path = "/articles/selfcare" exact component={Article_SelfCare} />
                <Route path = "/articles/festivecny" exact component={Article_CNY} />
                <Route path = "/articles/loneliness" exact component={Article_Loneliness} />
                
                
                {/* activities */}
                <Route path = {"/activities"} exact component={Activities_Main} />
                <Route path = "/activity/musclerelax" exact component={Activities_MuscleRelaxation} />
                <Route path = "/activity/strength" exact component={Activities_Strength} />
                <Route path = "/activity/values" exact component={Activities_Values} />
                <Route path = "/activity/lovingkindness" exact component={Activities_LovingKindness} />
                <Route path = "/activity/attentionbasic" exact component={Activities_AttentionBasic} />
                <Route path = "/activity/attentionadvance" exact component={Activities_AttentionAdvance} />
                <Route path = "/activity/feelingwheel" exact component={Activities_FeelingWheel} />
                <Route path = "/activity/discussvalue" exact component={Activities_AskACT} />
                <Route path = "/activity/discusschanges" exact component={Activities_AskMI} />
                
                {/* <Route path = "/test" exact component={TempEmailSend} /> */}
                {/* <Route path = "/contacttemp" exact component={ContactTemp} /> */}
                
                 {/* About */}
                 <Route path = {"/about/matt"} exact component={AboutIvan} />
                 <Route path = {"/about/choonboon"} exact component={AboutChoonBoon} />
                 <Route path = {"/about/jonathon"} exact component={AboutJonathon} />
                 <Route path = {"/about/marie"} exact component={AboutMarie} />
                
        </div>
    )
}

export default RoutePage