
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-thought-catalog-2228552.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Suicide is killing yourself, often resulting from mental illness such as depression.</p>
            <br/><br/>
            <p> In Singapore, an average of 1 person lost their life to suicide. 452 lives were lost in 2019. a 13% increase compared to 400 cases in 2019. This is the highest since 2012. 
                Suicide deaths rose to 8.88 per 100,000 Singapore residents, up by 0.88 compared to 2019.</p>
            <br/><br/>
            <p>In United States, there were 48,344 recorded suicides in 2018, up from 42,773 in 2014.</p>
            <br/><br/>
            <p>Please contact your suicide prevention hotline in your local community to see immediate help if you are feeling suicidal or know anyone who is suicidal.  </p>
            <br/>
            <br/>
            <p>  <a href='/suicide'>Worldwide Suicide and Crisis Hotlines</a></p>
        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Suicide'}  content ={pageContent()} urlcredit={'https://www.pexels.com/photo/purple-petaled-flowers-near-black-book-2228552/'} imagelogo={imagelogo} credit={'Thought Catalog'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}