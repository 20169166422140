// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".suicide_ultype__250nb {\n    list-style-type: none;\n}", "",{"version":3,"sources":["webpack://src/components/IndividualPage/Suicide/suicide.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB","sourcesContent":[".ultype {\n    list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ultype": "suicide_ultype__250nb"
};
export default ___CSS_LOADER_EXPORT___;
