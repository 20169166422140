
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-kat-jayne-568027.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Coping with the loss of family members or loved ones may be our hardest challenge. 
                When we have lost someone or a pet, our grief can be particularly intense. Loss is understood as a natural part of life, but we can still be overcome by shock and confusion, leading to prolonged periods of sadness or depression. 
                The sadness typically diminishes in intensity as time passes. Still, grieving is essential to overcome these feelings and continue to embrace the time you had with your loved one.</p>
            <br/><br/>
            <p> Everyone reacts to death and grief differently. Most people can recover from loss on their own over time if they have social support and healthy habits. 
                It can take months or years to come to terms with a loss. There is no “normal” period for someone to grieve. 
                Don’t expect to pass through phases of grief either, as research states that most people do not go through stages as progressive steps.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Grief'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/adult-alone-anxious-black-and-white-568027/'} imagelogo={imagelogo} credit={'Kat Jayne'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}