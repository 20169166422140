import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import TextTime from '../../../containers/TransitText/texttransition'

import CallMe from '../../CallMe/callme'

function pageContent (){

    var testimonialText = classNames(classes.testimonials ,classes.italic)
    var subheading = classNames (classes.bioheading)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    var signature = classNames (classes.signature)
    var testimoniallegend = classNames (classes.legendtext)



    return(

        <div className={classes.divcontentblock}>

            <div className = {contentClass}>
                <br/><br/><br/>
                


                <div>
                    <p className = {titleClass} data-attribute="title">Marie (Intern)</p>
                    <br/>


                    <p>Marie is currently a trainee counsellor pursuing her Master of Counselling. 
                    </p>
                    <br/>
                    <p>
                    Marie uses an integrative approach and draws from evidence-based therapies, including Cognitive Behaviour Therapy (CBT), Acceptance and Commitment Therapy (ACT) and Positive Psychology, to inform her practice. 
                    She views each client as unique and tailors her approach to meet their needs. No matter what therapeutic techniques she employs, she firmly believes in being non-judgmental, accepting, and empathetic while bringing an attitude of respectful curiosity to her work with clients.
                    </p>
                    <br/>
                    <p>
                    During her 6-month internship attachment to eldercare, she worked with seniors with various issues, including anxiety, depression, role adjustments, and existential and interpersonal issues. 
                    She also operated a crisis helpline and provided emotional support to distressed individuals.

                    </p>
                    <br/><br/>
                    <p className={subheading}>Multucultural diversity</p>
                    <p>Marie is from the United Kingdom and is familiar with the stresses of expatriates in Singapore. As a child of Chinese and British parents, she knows the challenges of multicultural and adjustment issues.</p>
                    
                    

                    <div>
                        <p className={signature}>Marie</p>
                        <br/>
                    </div>
                    <div>
                        <br/><br/>
                        <CallMe/>


                    </div>

                    <br/><br/><br/><br/>    

                </div>
            </div>
        </div>
    )

}

export default function bio_counsel1(){


    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}
