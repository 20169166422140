

var statedata = ({

    
    Accepting:false,
    Active:false,
    Adaptable:false,
    Adventuresome:false,

    
    Affectionate:false,
    Affirmative:false,
    Alert:false,
    Alive:false,


    
    Ambitious:false,
    Anchored:false,
    Assertive:false,
    Assured:false,


    
    Attentive:false,
    Bold:false,
    Brave:false,
    Bright:false,


    
    Capable:false,
    Careful:false,
    Cheerful:false,
    Clever:false,


    
    Committed:false,
    Competent:false,
    Concerned:false,
    Confident:false,


    
    Considerate:false,
    Courageous:false,
    Creative:false,
    Decisive:false,


    
    Dedicated:false,
    Determined:false,
    Rigid:false,
    Diligent:false,


    
    Doer:false,
    Eager:false,
    Earnest:false,
    Effective:false,


    
    Energetic:false,
    Experienced:false,
    Faithful:false,
    Fearless:false,


    
    Flexible:false,
    Focused:false,
    Forgiving:false,
    Progressive:false,


    
    Free:false,
    Happy:false,
    Healthy:false,
    Hopeful:false,


    
    Imaginative:false,
    Ingenious:false,
    Intelligent:false,
    Knowledgeable:false,


    
    Loving:false,
    Mature:false,
    Open:false,
    Optimistic:false,


    
    Orderly:false,
    Organized:false,
    Patient:false,
    Perceptive:false,


    
    Persevering:false,
    Persistent:false,
    Positive:false,
    Powerful:false,


    
    Prayerful:false,
    Quick:false,
    Reasonable:false,
    Receptive:false,

    
    Relaxed:false,
    Reliable:false,
    Resourceful:false,
    Responsible:false,

    
    Sensible:false,
    Skillful:false,
    Solid:false,
    Spiritual:false,

    
    Stable:false,
    Steady:false,
    Straight:false,
    Strong:false,

    
    Stubborn:false,
    Thankful:false,
    Thorough:false,
    Thoughtful:false,

    
    Tough:false,
    Trusting:false,
    Trustworthy:false,
    Truthful:false,

    
    Understanding:false,
    Unique:false,
    Unstoppable:false,
    Vigorous:false,

    
    Visionary:false,
    Whole:false,
    Willing:false,
    Winning:false,


    
    Wise:false,
    Worthy:false,
    Zealous:false,
    Zestful:false,

})

module.exports.statedata=statedata