import React, {Component} from 'react'
import RouterPage from '../../Router/router'

class Pages extends Component{

    render() {

        return (

            <div>

                <RouterPage/>

                
            </div>
        )
    }

}

export default Pages