import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/dentallogo.jpeg'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.divcontent, classes.italiccontent )
    // const subheading = classNames (classes.subheadingcontent,  classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    return (


            <div  className={classes.divcontentblock}>


                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Source : Image copyright : Unknown</p>
                    <p className={titleClass} > Dental Fear </p>
                    <p>Dental fear is common, not only in children but in adults too. Many people struggle to maintain a routine of regular visits to dentist during the Covid-19, in favour of social distance. Some felt that the reduced service of medical services are not worth their time to visit. Many dental clinics were shutdown and some patients are unable to find available slots.</p>
                    <br/>
                    <p>There are significant segments of adults who dread visiting dentists. The pandemic gave the patients a relief that they can temporarily postpone their dental appointments. Between 9% to 20% of American adults fear visiting the dentists. 
                        It also affects half British population. This term is called "Dental Phobia"</p>
                    <br/>
                    <p>Dental phobia (or dental anxiety or odontophobia) are significant problems for significant adults in the world. Dental fear is real, and it resulted in anxiety. Dentist like doctors are irrationally mistaken for cold and unfeeling, and the pain that come during the treatment. 
                        The anxiety prevents a dental phobia may result in several dental related health issues in long run.</p>
                    <br/><br/><br/>
                    <p className={classes.subheadingcontent}>What does dental anxiety cause</p>
                    <br/>
                    <p className={classes.bulletpoints}>Some of the symptoms are:</p>
                    
                    <p className={classes.bulletpoints}>1. Sweating</p>
            
                    <p className={classes.bulletpoints}>2. Heart palpitation</p>
                
                    <p className={classes.bulletpoints}>3. Possible fanting</p>
                
                    <p className={classes.bulletpoints}>4. Signs of distress / panic / crying</p>
                
                    <p className={classes.bulletpoints}>5. Numbness around the mouth</p>
                    <br/> <br/><br/>
                    <p className={classes.subheadingcontent}>Cause of dental fear</p>
                    <br/>
                    <p className={classes.bulletpoints}>The causes of dental fear are:</p>
                    
                    <p className={classes.bulletpoints}>1. Childhood trauma</p>
            
                    <p className={classes.bulletpoints}>2. Previous trauma injury of head</p>
                
                    <p className={classes.bulletpoints}>3. Bad experience of dental visit</p>
                
                    <p className={classes.bulletpoints}>4. View mouth is personal space and accessing of mouth is invasion of personal space</p>
                
                    <p className={classes.bulletpoints}>5. Trust issues</p>
                    
                    <p className={classes.bulletpoints}>6. Generalised anxiety (such as agoraphobia), post traumatic stress and depression</p>
                    
                    <br/><br/><br/>
                    <p className={classes.subheadingcontent}>Coping with Dental Anxiety and Medical Prescription</p>
                    <br/>
                    <p className={classes.bulletpoints}>These are some of the few techniques of reducing anxiety of dental and with the help of prescription.</p>
                    
                    <p className={classes.bulletpoints}>1. Relaxation techniques such as deep breathing</p>
            
                    <p className={classes.bulletpoints}>2. Mindfulness exercise such as focusing on your inhaling or counting mentally or acceptance</p>
                
                    <p className={classes.bulletpoints}>3. Emotional Deregulation - positive thinking, attention shifting strategies</p>
                
                    <p className={classes.bulletpoints}>4. Distraction - listening to soothing music, use of screen</p>
                
                    <p className={classes.bulletpoints}>5. Thoughts challenge - Challenge the harmful cognitive thinking</p>
                    
                    <p className={classes.bulletpoints}>6. Anxiety relief medication such as anxiolytic (temazepam) which is prescribed by doctor</p>
                    
                    <p className={classes.bulletpoints}>7. General anaesthesia administers by anaesthetist</p>
                    
                    <p className={classes.bulletpoints}>8. Relative analgesia (happy gas) for relaxation administers by dentist</p>
                    <br/><br/><br/>
                    <p className={classes.subheadingcontent}>Conclusion</p>
                    <p>Sometimes, we are able to overcome the dental phobia by ourselves. However, if it is adversely 
                        impacting our lives such as our oral health, it is important to see a mental health professional. 
                        In psychotherapy sessions, we can help you to cope with your fear, and understand how it comes about. Through a customised uniquely program for each individual, 
                        a psychotherapist can help an individual to overcome their phobia of dentist.</p>
                    <br/>
                  
                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle