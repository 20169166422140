import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import srcimgHeading from './resource/happiness.jpeg'
import hormonesbox from './resource/dosehormones.jpeg'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent (){

    var titleClass = classNames(classes.title, classes.center)
    // var contentClass = classNames (classes.div_articlecontent1 , classes.defaultfont)
    var italiccontentClass = classNames (classes.italiccontent , classes.center)
    const subheading = classNames (classes.subheadingcontent, classes.center)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)

    return (
        <div>


            <div  className={classes.divcontentblock}>

                
                {/* <div className = {classes.textcenter}> */}
                <div className={contentClass}>
                    <br/><br/>
                    <img src = {srcimgHeading} className={classes.imgcaption} alt="" ></img>
                
                    <p className={creditClass}> Source : Nick Youngson CC By-SA 3.0 Alpha Stock Images</p>
                    
                    <p className={titleClass} >Happy Hormones</p>
                    <br/><br/><br/>

                    <img src = {hormonesbox} className={classes.imgcaption} alt="" ></img>
                    <p className={creditClass}> Image Credit : <a href="TheHapppyHealthyCoffee.com" target="_blank" rel="noreferrer">The Happpy Healthy Coffee</a></p>

                    <br/>
                    <p className={subheading}>What makes us happy ? </p>
                    <p className={classes.bulletpoints}>There are 4 happy hormones that make us happy, at least from biological point of view. They are :</p>
                    <p  className={classes.bulletpoints}>1. Endorphins</p>  
                    <p  className={classes.bulletpoints}>2. Serotonin</p>   
                    <p  className={classes.bulletpoints}>3. Dopamine</p>
                    <p  className={classes.bulletpoints}>4. Oxytocin</p>
                    <br/>
                    <p className={classes.bulletpoints}>Let's talk about each of these impacts in our life and how they function.</p>                
                    <br/><br/><br/>
                    <p className={subheading}>Endorphins</p>
                    
                    <p>Endorphins act to diminish the perception of pain. They reduce stress and anxiety.</p>
                    <p>Endorphins created in our brain, spinal cord and several other parts of our bodies through a response to the brain chemicals call neurotransmitters.</p>

                    <br/>
                    <p className={italiccontentClass}>What activities produce endorphins ?</p>
                    <p  className={classes.bulletpoints}>This activities will generally produce endorphins. They are :</p>
                    <p  className={classes.bulletpoints}>1. Exercise</p>  
                    <p  className={classes.bulletpoints}>2. Sex</p>   
                    <p  className={classes.bulletpoints}>3. Drink wine</p>
                    <p  className={classes.bulletpoints}>4. Get a massage or spa</p>
                    <p  className={classes.bulletpoints}>5. Meditate</p>
                    <p  className={classes.bulletpoints}>6. Laugh</p>

                    <br/>
                    <p className={italiccontentClass}>What happens if you do not have enough endorphins? </p>
                    <p  className={classes.bulletpoints}>A person who does not have enough endorphins may experience depression, anxiety and moodiness.</p>

                    <br/><br/><br/>

                    <p className={subheading}>Serotonin</p>
                    <p>Serotonin is an important hormone that helps us stabilize our mood, feelings of well-being and happiness. 
                        It enables brain cells and other nervous systems cells to communicate with each other. 
                        Serotonin helps with sleeping, eating and digestion.</p>

                    <br/>
                    <p className={italiccontentClass}>What will increase serotonin ?</p>
                    <p  className={classes.bulletpoints}>1. Food that contain Tryptophan. They can be chicken, eggs, cheese, fish, milk, turkey, peanuts, pumpkin, sesame seeds</p>  
                    <p  className={classes.bulletpoints}>2. Supplements</p>   
                    <p  className={classes.bulletpoints}>3. Exercise</p>
                    <p  className={classes.bulletpoints}>4. Bright light</p>
                    <p  className={classes.bulletpoints}>5. Spend time in nature</p>
                    <p  className={classes.bulletpoints}>6. Gratitude exercise or practising gratitude</p>
                    <p  className={classes.bulletpoints}>7. Setting goal to achieve</p>


                    <br/><br/><br/>
                    <p className={italiccontentClass}>What happens if you do not have enough serotonin?</p>
                    <p  className={classes.bulletpoints}>A person who does not have sufficient serotonin may suffer from anxiety, depressed mood, aggression, impulsive behavior, insomnia, irritability, low self-esteem or poor appetite.</p>

                    <br/>
                    <p className={subheading}>Dopamine </p>
                    <p>Dopamine belongs to the catecholamine and phenethylamine families. Dopamine functions as a neurotransmitter in the brain as a reward motivated behaviour. It also provides motor control.</p>
                    <br/>

                    <p className={italiccontentClass}>What will increase dopamine ?</p>
                    <p  className={classes.bulletpoints}>1. Eat protein</p>  
                    <p  className={classes.bulletpoints}>2. Consume probiotics</p>   
                    <p  className={classes.bulletpoints}>3. Get enough sleep</p>
                    <p  className={classes.bulletpoints}>4. Listen to music</p>
                    <p  className={classes.bulletpoints}>5. Exercise more</p>
                    <p  className={classes.bulletpoints}>6. Meditate</p>

                    <br/>
                    <p className={italiccontentClass}>What happens if you do not have enough dopamine?</p>
                    <p className={classes.bulletpoints}>A person who does not have sufficient dopamine may suffer from muscle cramps, tremors, aches, 
                        lost of balance, weight lost/ gain , low sex drive, delusion, lack of self-awareness, mood 
                        swings, feeling fatigue, anxiety issues, low self-esteem.</p>


                    <br/><br/><br/>

                    <p className={subheading}>Oxytocin</p>
                    <p>Oxytocin is a peptide hormone and neuropeptide secreted by posterior lobe of the pituitary gland. it is sometimes known as the "cuddle hormone" 
                        or the "love hormone" because it is released when people snuggle up to bond socially.</p>

                    <br/>
                    <p className={italiccontentClass}>What will increase oxytocin ?</p>
                    <p  className={classes.bulletpoints}>1. Listen to music</p>  
                    <p  className={classes.bulletpoints}>2. Share a meal with someone to bond</p>   
                    <p  className={classes.bulletpoints}>3. Meditate</p>
                    <p  className={classes.bulletpoints}>4. Bring the dog out for a walk</p>
                    <p  className={classes.bulletpoints}>5. Say I "love" you to someone genuinely</p>
                    <p  className={classes.bulletpoints}>6. Hugs someone you love</p>

                    <br/>
                    <p className={italiccontentClass}>What happens if you do not have enough oxytocin?</p>
                    <p>A person who has low oxytocin may not feel connected to others or feel as good on themselves. Sometimes the lack of oxytocin may produce anxiety and fear. Oxytocin and cortisol (fight or flight hormone) are opposite of each other. When one goes up, the other will comes down.</p>

                    <br/>

                    <p className={subheading}>Conclusion</p>
                    <p >Although some may say "Happiness is in the state of mind", there are biological components that influence our happiness. 
                        Taking care of our physical health and mental well-being are crucial to our happiness.</p>







                </div>


            </div>


        </div>
    )
}
const HappyHormones=()=>{


    return (
        <div>
            <ArticleTemplate content={pageContent()}/>

        </div>
        

    )

}

export default  HappyHormones
