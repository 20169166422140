import React , {useEffect} from 'react'
import classes from '../../../css/main.module.css'
import srcimg from '../../../resources/image/hope-logo-elongated.jpeg'
import classNames from 'classnames'
import {gsap} from 'gsap'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'

import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'

// import MetaTags from 'react-meta-tags';



function PageContent (){

    var titleHomeClass = classNames (classes.titleHome)
    // var titleClass = classNames(classes.title)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var italiccontentClass = classNames (classes.italiccontent )
    var subheading = classNames (classes.subheadingcontent)

    useEffect(()=>{

        document.title = 'The Talk Therapy - One Stop Counselling / Psychotherapy';
        <meta name='description' content='The one stop counselling service'/>;
        <meta name='keywords' content='counselling psychotherapy mental health'/>;
        gsap.fromTo("#titleanimation", {autoAlpha: 0}, {autoAlpha: 0.9, duration: 4});
    })

   

    return (
        <div>
            {/* <MetaTags>
                <meta name="google-site-verification" content="Psv7VH9oSSPpk1CDIbxCL9wz3cxwZSsvRPYTjHtzsU8" />
            </MetaTags> */}
            
            <div className = {contentClass} >
                <div className={classes.divcontentblock}> 
                    <div id="titleanimation">
                        <p className = {titleHomeClass} data-attribute="hometitle"> The Talk Therapy</p>
                    </div>
                </div>

            </div>
                <img src = {srcimg} className={classes.imghome} alt="caption"  ></img>
            <div>


            </div>
            <div qa-attribute="landingpage"/>
            <div className = {contentClass} >
            

                    <div className={classes.divcontentblock}>
                        <br/>
                        {/* <p className = {titleHomeClass}> The Talk Therapy</p>
                        

                        <img src = {srcimg} className={classes.imghome} alt="caption"  ></img> */}

                        
                        
                        <div >

                        <p className={subheading}> Are you struggling to cope?</p>

                        <br/><br/>
                        
                        <p className = {italiccontentClass}> If you are struggling with painful problems such as relationships, work, anxiety, anger, depression or life transition events like retirement or mid-life crisis, or you need help to develop yourself to have a more fulfilling life. 
                        It is crucial to seek help from a caring and trained mental health professional.</p>
                        <br/>
                        

                    
                        <br/><br/>
                        <p> Reaching out is not a sign of weakness but a significant step toward self-care. 
                            Psychotherapy provides an emotionally safe space to explore many aspects of our life. It can also be an opportunity to express difficult feelings such as anger, resentment, guilt and fear by talking freely in a supportive, non-judgmental environment.</p>
                        <br/><br/>
                        <br/><br/>
                        <p> <span className={classes.bold}>This is what we offer - Counselling / Psychotherapy for Everyone </span></p>
                        <p> If you are stuck in this negative cycle, We are here to help you experience the change and uncover your true potential to lead a life worth celebrating. While we cannot change the past, we can work together to understand your life better. By applying therapy approaches, and techniques and walking with you in this journey,
                             we can reduce the painful experience and unearth new strengths that may help you have a more meaningful and fulfilling life.</p>
                        <br/>
                        <p>As a socially conscious organisation, this service is inclusive for individuals, couples and families from all walks of life. We operate on a level where most individuals can afford this service. 
                            Reach out today and see if this is right for you.</p>
                        <br/>
                        <p>We are all trained mental health professionals. </p>
                        <br/>
                        <p className = {italiccontentClass}> Whether you need a listening ear or to work on long-term issues, do <a href= "/contact">reach out to us</a> for a session. </p>
                

                        <br/><br/><br/><br/>    


                
                    </div>
                </div>

                <br></br>

            </div>
        </div>
    )
}

export default function Home (){

    // constructor (props){

    //     super (props)
    //     // this.titleClass = classNames(classes.title)
    //     // this.contentClass = classNames ('divcontent')
    // }
    // // const titleClass = classNames('classes.title')
    // img_caption_path = "../"

    return (
        <div>
            <BasePageTemplate content={PageContent()}/>

        </div>

    )

}
