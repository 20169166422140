import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/joblost.png'
// import YoutubePage from '../../YoutubePage/youtube'
//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'

// IMPORT YOUTUBE 
// https://www.npmjs.com/package/react-youtube

function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.divcontent, classes.italiccontent )
    // const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt="" ></img>
                    <p className={creditClass}> Image Source : Unknown</p>
                    
                    <p className={titleClass} > The Stress of Job Search  </p>
                    <p>Several research have shown the negative impact on mental health from losing a job or struggling to gain employment. 
                        Some of the long term unemployment effects are depressive symptoms, anxiety, lack of confidence which can compound not only the stress of the individual, 
                        but to the family and society.</p>
                    <br/>
                    <p>Relationship between unemployment and poor health have been well documented. 
                        This may ultimately lead to break down in physical health in long term. Example is chronic disease (Cardiovascular, hypertension, musculoskeletal disorder) and premature mortality. </p>
                    <br/>
                    <p>Individuals who found a job may exhibit negative psychological symptoms after long term unemployment. The psychological impact last much longer than the job itself for several job seekers. 
                        Example is that depression or anxiety for individuals who gained employment after a long period of joblessness. There is a casual relationship between depression , anxiety and unemployment. Unemployment is also associated with unhealthy behaviours for many individuals 
                        - increase alcohol, tobacco consumption and decrease of exercise.</p>
                    <br/>
                    <p>My point is ; If you have family members, friends who are struggling seeking employment, 
                        be kind to them. Give them a listening ear. Be there for them.</p>
                    <br/>
                    <p>Their situation is already stressful, and we do not need to aggravate them further.</p>
                    <br/><br/>
                    <p className={classes.bulletpoints}>If you are struggling in finding a job,</p>
                    <p  className={classes.bulletpoints}>1. Consider what is your pain and worry first, communicate with your family</p> 
                    <p  className={classes.bulletpoints}>2. File for unemployment (if this is applicable) for benefits</p> 
                    <p  className={classes.bulletpoints}>3. Seek a local employment agency as soon as possible</p> 
                    <p  className={classes.bulletpoints}>4. Check on health insurance options</p> 
                    <p  className={classes.bulletpoints}>5. Work out a personal budget and finances</p> 
                    <p  className={classes.bulletpoints}>6. Design a job search strategy, seek a career guidance from a career coach</p> 
                    <p  className={classes.bulletpoints}>7. Maintain an active lifestyle (exercise, network), and keep a routine</p> 
                    <p  className={classes.bulletpoints}>8. Seek other forms of support, such as emotional, financial and expand your network</p> 
                    <br/><br/>
                    <p>If you are facing anxiety over career, job lost, 
                        and need professional help to journey with you on your thoughts or worries, you may <a href="/contact">reach out </a> to me for a counselling session and we can work from there.</p>
                   
                   




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle