import React, {useEffect,useLayoutEffect, useState} from 'react'
// import axios from 'axios'
import classes from '../../../css/main.module.css'
import Input from '../../Input/input'
import Button from '../../Button/button'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import CallMe from '../../CallMe/callme'
import { Redirect } from 'react-router-dom';
import classNames from 'classnames'

import SMTP from '../../../containers/server/smtp'

import * as dataTypes from '../../../containers/inputFormType'

export default  function ContactForm (){

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    var titleClass = classNames(classes.title)
    var subheading = classNames (classes.bioheading)
    var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var signature = classNames (classes.signature)
    var contactClass = classNames (classes.bulletpoints,classes.contactContent)
    
    var successMessage = "Thank you. I will contact you soon. "
    var errorNotStatus200="There is an error sending the form. Please use the email instead."
    var errorMessage = "There is an error sending the form. Please email directly to enquiry@thetalktherapy.com ."



    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
     function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useLayoutEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
      }

    //   useEffect=(()=>{
    //     console.log (useWindowDimensions)

    //   },[])

    function setGoogleFrameSize (width,height){
        var returnHeight = 0;
        var returnWidth = 0


        //Height
        if (height>700)
            returnHeight = height * 1.8
        
        else if (height < 500)
            returnHeight = height * 18.8

         else if (height >= 500 && height < 800)
            returnHeight = height * 2.5

        else 
            returnHeight = height

        //Width
        if (width>1000)
            returnWidth = width *  0.9

        else if (width>500 & width<=1000)
            returnWidth = width *  0.9

        else 
            returnWidth = width





        return [returnWidth, returnHeight]
    }


    const { height, width } = useWindowDimensions();
    //var googleFrameWidth = width * 0.9
    //var googleFrameHeight = height * 2.2
    var googleFrameWidth = setGoogleFrameSize(width,height)[0]
    var googleFrameHeight = setGoogleFrameSize(width,height)[1]
    
    function pageContent (){
        
        // const [refresh,setRefresh] = useState(true)
        const refresh = false

        return (

            <div>
                {/* <p>GoogleFrameHeight : {googleFrameHeight}</p>
                <p>Height : {height}</p>
                <p>Width : {width}</p> */}
                <div className={classes.divcontentblock}>
                        <div className = {contentClass}>

                        
                            <div>

                    
                                <br/>
                            
                                <p className = {titleClass} data-attribute="title"> Contact</p>
                                <div>
                                    <p>For <span className={classes.bold}> <a href='/counsel/register'>registration</a></span> or enquiries that contain </p> 
                                    <p><span className={classes.bold}>confidentiality information</span>, </p>

                                    <p>drop us an email / WhatsApp </p>
                                    <br/>
                                    <p><a href= "mailto: enquiry@thetalktherapy.com">enquiry@thetalktherapy.com</a> or <a href="https://wa.me/6594490890" target="_blank" rel="noopener noreferrer"> chat</a> </p>
                                    <br/>
                                    

                                    <div>
                                        <br/><br/>
                                        {/* <p>Or, if you want us to reach out to you instead, you may fill up this form <a href= "https://forms.gle/U9hL25oQWGXkbbcU9" target="_blank" rel="noopener noreferrer">here</a>.</p> */}
                                        
                                        <p> Alternatively, you may want to fill up this form so that we can reach out to you instead.</p>
                                        <br/>
                                            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc-xsfpHfiG9Sv6Wc9IOoJxLKctUNOZO2y5RsviAFEUQgBF4g/viewform?embedded=true" title="googleform" width={googleFrameWidth} height={googleFrameHeight} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                     
                                        
                                        

                                    </div>

                                    <div>
                                        <p className={classes.creditcontent}>Please note we will <span className={classes.freebold}>only</span> respond to valid email address.</p>
                                        
                                    </div>
                                </div>
                                
                            



                            </div>
                        

                    


                            
                        </div>
                    
                </div>
            
            </div>
        )

        }

        return (

            <div>
                <BasePageTemplate content={pageContent()}/>

            </div>
        )
}