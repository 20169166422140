
// import './temp.css'
import './style.css'
import CardType from './cardtype.js'





import img1 from './resources/default/1.jpeg'
import img2 from './resources/default/2.jpeg'
import img3 from './resources/default/3.jpeg'
import img4 from './resources/default/4.jpeg'
import img5 from './resources/default/5.jpeg'
import img6 from './resources/default/6.jpeg'
import img7 from './resources/default/7.jpeg'
import img8 from './resources/default/8.jpeg'
import img9 from './resources/default/9.jpeg'
import img10 from './resources/default/10.jpeg'



import imgMI1 from './resources/MI/1.jpeg'
import imgMI2 from './resources/MI/2.jpeg'
import imgMI3 from './resources/MI/3.jpeg'
import imgMI4 from './resources/MI/4.jpeg'
import imgMI5 from './resources/MI/5.jpeg'
import imgMI6 from './resources/MI/6.jpeg'
import imgMI7 from './resources/MI/7.jpeg'
import imgMI8 from './resources/MI/8.jpeg'
import imgMI9 from './resources/MI/9.jpeg'
import imgMI10 from './resources/MI/10.jpeg'

var CardsRead = require ('./readcards.js')

export default function Cards (props){

   

    function GetBackgroundImage (number){

        var BackgroundImage , fontColor
        if (props.CardType===CardType.ACT){
            
            // alert("ACT!")

            if (number===0){
                BackgroundImage =img1
                fontColor="white"
    
    
            }else if (number===1){
                BackgroundImage =img2
                fontColor="#F7DD1C"
    
            }else if (number===2){
                BackgroundImage =img3
                fontColor="black"
    
            }else if (number===3){
                BackgroundImage =img4
                fontColor="black"
    
            }else if (number===4){
                BackgroundImage =img5
                fontColor="black"
    
            }else if (number===5){
                BackgroundImage =img6
                fontColor="white"
    
            }else if (number===6){
                BackgroundImage =img7
                fontColor="black"
    
            }else if (number===7){
                BackgroundImage =img8
                fontColor="white"
    
            }else if (number===8){
                BackgroundImage =img9
                fontColor="white"
    
            }else if (number===9){
                BackgroundImage =img10
                fontColor="black"
    
            }else {
                BackgroundImage =img1
                fontColor="black"
            }

        }else if (props.CardType===CardType.MI){

            if (number===0){
                BackgroundImage =imgMI1
                fontColor="white"
    
    
            }else if (number===1){
                BackgroundImage =imgMI2
                fontColor="#F7DD1C"
    
            }else if (number===2){
                BackgroundImage =imgMI3
                fontColor="black"
    
            }else if (number===3){
                BackgroundImage =imgMI4
                fontColor="black"
    
            }else if (number===4){
                BackgroundImage =imgMI5
                fontColor="black"
    
            }else if (number===5){
                BackgroundImage =imgMI6
                fontColor="white"
    
            }else if (number===6){
                BackgroundImage =imgMI7
                fontColor="white"
    
            }else if (number===7){
                BackgroundImage =imgMI8
                fontColor="white"
    
            }else if (number===8){
                BackgroundImage =imgMI9
                fontColor="black"
    
            }else if (number===9){
                BackgroundImage =imgMI10
                fontColor="white"
    
            }else {
                BackgroundImage =imgMI1
                fontColor="white"
            }

        }
        
        var sectionStyle = {
            // width: "100%",
            // height: "400px",
            color : fontColor,
            background: "url(" + BackgroundImage + ")",
            backgroundColor: "#cccccc",
            backgroundSize: "300px 100px",
            backgroundRepeat: "repeat",
          };


          return sectionStyle
    }
      /*
    return (

        <div>



            <div className='some-page-wrapper'>
                <div className='row'>
                    <div className='column'>
                        <div className='blue-column'>
                            
                                <div className="span" style={GetBackgroundImage(0)}>
                                    {cards[0]}
                                </div>
                            
                        </div>
                    </div>

                    <div className='column'>
                        <div className='green-column'>
                            <div className="span" style={GetBackgroundImage(1)}>
                                {cards[1]}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='column'>
                        <div className='blue-column'>
                            <div className="span"  style={GetBackgroundImage(2)}>
                                {cards[2]}
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='green-column'>
                            <div className="span"  style={GetBackgroundImage(3)}>
                                {cards[3]}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='column'>
                        <div className='blue-column'>
                            <div className="span"  style={GetBackgroundImage(4)}>
                                {cards[4]}
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='green-column'>
                            <div className="span"  style={GetBackgroundImage(5)}>
                                {cards[5]}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='column'>
                        <div className='blue-column'>
                            <div className="span"  style={GetBackgroundImage(6)}>
                                {cards[6]}
                            </div>
                        </div>
                    </div>

                    <div className='column'>
                        <div className='green-column' >
                            <div className="span" style={GetBackgroundImage(7)}>
                                {cards[7]}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='column'>
                        <div className='blue-column'>
                            <div className="span" style={GetBackgroundImage(8)}>
                                {cards[8]}
                            </div>
                        </div>
                    </div>

                    <div className='column'>
                        <div className='green-column'>
                            <div className="span" style={GetBackgroundImage(9)}>
                                {cards[9]}
                            </div>
                        </div>
                    </div>
                    </div>
                
                </div>
            
        </div>
    )*/


    function GetDoubleRow (card1, card2, imageIndex1, imageIndex2){

        return (
            
            <div  key={imageIndex1}  className='row' data-attribute="row">
                <div className='column'>
                    <div className='blue-column'  data-attribute="column1">
                    
                        <div className="span" style={GetBackgroundImage(imageIndex1)}>
                            {card1}
                        </div>
                    
                    </div>
                </div>

                <div className='column'>
                    <div className='green-column' data-attribute="column2">
                        <div className="span" style={GetBackgroundImage(imageIndex2)}>
                            {card2}
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    function CreateCards (){
        var cards = CardsRead.readData(props.CardType)
        var data = []
        for (var i=0; i<cards.length; i+=2){

            
            
            data.push (GetDoubleRow (cards[i], cards[i+1], i , i+1))
            // console.log (i)
            
        }

        return data
    }

    return (

        <div>
            <div className='some-page-wrapper'>
                 {CreateCards()}
            </div>


            
        </div>
    )

}