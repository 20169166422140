import Button from '../Button/button'


export default function CallMe(){

    function submit(){
        var url = 'https://calendly.com/thetalktherapy'
        
        window.open(url,"_blank")
    }

    return (

        <div>
            <Button title="Book A 20 min Call"   click = {submit.bind(this)} />
        </div>
    )
}