/*
Under Construction

EMAIL / SMTP and this class

To make a standard button for email

*/
import Button from '../Button/button'
import Email from '../../containers/server/email'

import SMTP from '../../containers/server/smtp'

export default function EmailButton (props){
   
    function checkBlank (text){

        // alert ("text :" + text)

        if (typeof (text)==='undefined')
            return false 

        if ((text.length ===0))
            return false
        else 
            return true
    }

    function validateEmailString(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validateEmail(){
        /*
            main validation of email
        */

       
        var validateEmailAddress = false
        var validateMessage=false
        var validateSubject=false
        var validateContent=false
        var validateSummary=false

        var ContentMessage = props.ContentMessage
        // alert (JSON.stringify(ContentMessage))

        if (!checkBlank(ContentMessage.message))
            validateMessage=false
        else
            validateMessage=true


        if (!checkBlank(ContentMessage.content))
            validateContent=false
        else
            validateContent=true

        if (!checkBlank(ContentMessage.subject))
            validateSubject=false
        else
            validateSubject=true

        if (!checkBlank(ContentMessage.email))
            validateEmailAddress=false
        else {

            validateEmailAddress=validateEmailString(ContentMessage.email)

            //validateEmailAddress=true

        }
       // alert ("validateName :" + validateName)
        //alert ("validateSubject :" + validateSubject)
        // alert ("validateEmailAddress :" + validateEmailAddress)    
        
        if (validateEmailAddress && validateMessage && validateContent && validateSubject )
            validateSummary=true
        else
            validateSummary=false

        var validateResult = {

            email:validateEmailAddress,
            message:validateMessage,
            content:validateContent,
            subject:validateSubject,
            validation:validateSummary
        }

        // alert ("Validate Result : " + JSON.stringify(validateResult))
        return validateResult


    }
/*
    function validation (){

        var validation=props.validation
        // alert ("validation : " + validation)

        if (typeof (validation)==='undefined'){

            // alert ('undefined')
            return true
        }else {

            // alert ('defined')
            if (!validation){

                return false
            }else
            {
                return true
            }
        }

    }
    */
   
    function SendResult (event){
        // console.log ("SendResult :" + JSON.stringify(event))
        props.sendresult(event)
        // console.log ("result :" + props.sendresult)

    }

    function ReturnCode(success, message, validation){


        var codeReturn = {

            success: success,
            message : message,
            validation : validation
        }
        
        return codeReturn

    }
    function submit (){

        // alert ("Email Button!")
        var validateResult= validateEmail()
        // alert (JSON.stringify(validateResult))
        if (validateResult.validation){

            // SendResult(ReturnCode(true,'Success', validateResult))

            Email(props.ContentMessage.message,props.ContentMessage.email,props.ContentMessage.content,props.ContentMessage.subject).then ((data)=>{

                // alert ("Success!")
                alert (props.successMessage)
                SendResult(ReturnCode(true,'success',validateResult))
                // this.setState({refresh: true})
            }).catch ((error)=>{
                // alert ("Error!!")
                alert (props.errorMessage + " : " + error)
                SendResult(ReturnCode(false,error, validateResult))
                // console.log ("erroir " + error)
            })
        }else {

            SendResult(ReturnCode(false,'Validation issue', validateResult))

        }
        /*
        if (validation()){
            SendResult(ReturnCode(true,'success ttee',validation()))
            // alert ("Validation result :" + validation())
            
            Email(props.message,props.email,props.content,props.subject).then ((data)=>{

                alert ("Success!")
                alert (props.successMessage)
                SendResult(ReturnCode(true,'success',validation()))
                // this.setState({refresh: true})
            }).catch ((error)=>{
                alert ("Error!!")
                alert (props.errorMessage + " : " + error)
                SendResult(ReturnCode(false,error, validation()))
                // console.log ("erroir " + error)
            })
        }else {
           
        
           SendResult(ReturnCode(false,'Validation issue', validation()))
           
          
        }
        */


        /*
        Email(props.message,props.email,props.content,props.subject).then ((data)=>{

            alert ("Success!")
            alert (props.successMessage)
            SendResult("success")
            // this.setState({refresh: true})
        }).catch ((error)=>{
            alert ("Error!!")
            alert (props.errorMessage + " : " + error)
            SendResult("fail")
            // console.log ("erroir " + error)
        })*/

        /*
        var errorMessage ={

            success : true,
            message : 'Incorrect Email Error'
        }

        SendResult (errorMessage)
        */

    }

    async function Wakeup(){

        // SMTP.detectWakeUp().then((data)=>{

        // })}
        // alert ("wake up!")
        await SMTP.detectWakeUp()
    }
    

    // Wakeup()
    

    return (

        <div>
            <Button title={props.title} type={props.type} click = {submit.bind(this)} />
        </div>
    )


} 