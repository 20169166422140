import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function pageContent (){

    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var italicBulletPointsTextLeft = classNames(classes.bulletpointsTextLeft, classes.italic)
    var titleClass = classNames(classes.title)

    
    return(

        <div>
            <br/>
            <div  className={classes.divcontentblock}>
                
        

                <div className = {contentClass}>
                    <p className = {titleClass} data-attribute="title">Pro bono fees</p>

                    <p className={classes.bulletpointsTextLeft}>We are doing counselling solely out of <span className={classes.underline}>goodwill</span>.</p>
                    <br/>
                    <p className={classes.bulletpointsTextLeft}>There is a charge of SGD $10 per online session to encourage your commitment. However, if you have financial difficulties, are a student, are jobless, or elderly, we can waive the fee. 
                    </p>
                    <p className={italicBulletPointsTextLeft}>Alternatively, you may donate the amount to the charity of your choice and show us the receipt. We can waive the SGD $10 off.</p>
                    <br/>
                    <p  className={classes.bulletpointsTextLeft}>Please note that there are charges for below: </p>                        
                    
                    <p className={classes.bulletpoints}>1. If you need us to travel to your place for counselling, there will be a nominal transport fee charge of SGD $20.</p>
                    <p className={classes.bulletpoints}>2. If you prefer a face-to-face session in a counselling room, please refer to fee rate in <a href= "/counsel/fees">here</a>.  
                   <br/>
                   
                     </p>
                     <br/>
                     <p className={classes.bulletpoints}> Face-to-face is only available in Singapore.</p>
                    <br/><br/>
                    <p > Do reach out to us for details <a href= "/contact">here</a>. </p>
        
                    <br/><br/><br/><br/>    
                    
      
                </div>
            </div>
        </div>
    )
}

export default function Charges(){
    

    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )
}

