import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/selfcare_logo.jpeg'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function PageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source : Unknown </p>
                    
                    <p className={titleClass} > Self-Care </p>

                    <br/><br/><br/>
                  
                   
                    <p>Self-care is not about only eating and sleeping. It is much more than that. It is a <span className={classes.bold}>continuous process</span> of proactively considering and tending to your needs and maintaining your wellness.</p>

                    <br/>
    

                    <br/>
                    <p className={classes.bulletpoints}>Without self-care, we may have</p>
                    <p  className={classes.bulletpoints}>1. Anxiety</p>  
                    <p  className={classes.bulletpoints}>2. Anger</p>   
                    <p  className={classes.bulletpoints}>3. Fatigue</p>
                    <p  className={classes.bulletpoints}>4. Sleeplessness</p>
                    <p  className={classes.bulletpoints}>5. Low self-esteem</p>
                    <p  className={classes.bulletpoints}>6. Impact relationship</p>

                    <br/><br/><br/>  

                    <p>Self-care is not a magic pill, there is none that will have a lasting effects with a sole effort. What works for us to rejuvenate is subjective and unique, this is where we need to explore ourselves.</p>
                    <br/>
  
                    <p className={classes.bulletpoints}>There are several types of self-care. Some of it namely</p>
                    <p  className={classes.bulletpoints}>1. Physical</p>  
                    <p  className={classes.bulletpoints}>2. Emotional</p>   
                    <p  className={classes.bulletpoints}>3. Psychological</p>
                    <p  className={classes.bulletpoints}>4. Spiritual</p>


                    <p>Consider your own self-care activities in these areas of life. How do you nourish your mind, body, heart, and spirit? How do you actively reduce and manage stress in both your personal and professional life?</p>
 
                    <br/>
                    <p>How do you honour your needs first ? What are you proud of this week ? What is the one self-care routine that you will commit today ?</p>
                    <br/>
                    <p>Self-care is part of self-love, which is being kind towards yourself, fostering your growth.</p>
                    <br/><br/><br/>
                    <p className={classes.bold}>Be kind to yourself. Be enough for yourself, the rest of the world can wait.</p>
                  
                    




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const DecisionFatigueArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}


export default DecisionFatigueArticle