
import React , {useState, useEffect} from 'react'

import classTable from './table.module.css'
import classNames from 'classnames'
// import ArticleTemplate from '../../../../containers/PageTemplate/basetemplate_spa'
// import classes from '../../../../css/main.module.css'
import Button from '../../../Button/button'
// import axios from 'axios'
import ReactTooltip from 'react-tooltip'
import Random from '../../../../containers/utils/random_utils'

import SMTP from '../../../../containers/server/smtp'

export default  function PageContent (props){


    var successful_click_count=0 //Prevent spam on email
    const max_allowable_successful_click = 3 //maximum successful email to prevent spam

    var serverAwake = false;

    var successMessage = "Thank you for submiting the cards."
    // var errorNotStatus200="There is an error sending the form. Please use the email instead."
    var errorMessage = "There is an error sending the form. Please inform the counsellor your cards instead."

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    // const API_KEY = process.env.REACT_APP_CONTACTFORM_KEY
    // const [tableRefresh, setTableRefresh] = React.useState(false)

    const [hideBUtton, setHideButton] = useState(false)


    var evenCellColor = classNames(classTable.cell, classTable.celleven)
    var oddCellColor = classNames(classTable.cell, classTable.cellodd)
    var selectCellColor =classNames(classTable.cell,classTable.cellselect)

    // var divcontentClass=classNames(classes.divcontent)
    // var titleClass = classNames (classes.title)




    const tabledata=props.datasource
    const descriptiondata = props.datatooltip
    var valuestate=props.datastate


    useEffect(()=>{

        ReactTooltip.rebuild()
    })
    

    function SelectCell(celldata){

     

        valuestate[celldata]=!valuestate[celldata]

        forceUpdate()

    }

    function Mod5Row (currentRow){

        if (currentRow%5===0){
            return true
        }else 
            return false

    

    }

    // function Mod2Row (currentRow){

    //     if (currentRow%2===0){
    //         return true
    //     }else 
    //         return false
    // }

    // function Mod4Row (currentRow){
    //     if (currentRow%4===0){
    //         return true
    //     }else 
    //         return false
    

    // }

    function getContentResult (){

        var contentResult ="" 

        let valuesArray =Object.entries(valuestate)
        for (let value of valuesArray){
            
            if (value[1]===true){
                contentResult = contentResult  + "\n"+ value[0]
            }
        }

        return contentResult 
    }
    function btnSubmit (){
        var contentResult ="" 
        
        setHideButton(true)



        contentResult = getContentResult()

        if (contentResult==="")
        contentResult="None"

        // console.log ("result : "  +  contentResult)

        submit(contentResult)

    }

    function submit(contentResult){

        // this.setState ({inputDisplayState:true})

        // const api = '/1.0'
        // const header = '/therapy'
        // const url = process.env.REACT_APP_CONTACTFORM_URL

      

        // let data = {
        //     message: 'test fake email from react',
        //     fromaddr : "notification@thetalktherapy.com",
        //     content : contentResult,
        //     subject : "Characteristic Strength Cards",
        //     key : API_KEY
            
        // }


        var message= 'general enquiry to internal email'
        var fromaddr = "enquiry@listening-ears.org"
        var content = contentResult
        var subject = props.emailsubject

        
        SMTP.detectWakeUp().then((data)=>{

            // console.log ("success :"+ data)
            // this.setState({api_wakeup:true})
            serverAwake=true
            // console.log ("serverAwake :" + serverAwake )
            if (successful_click_count<max_allowable_successful_click){
                SMTP.sendEmail(message,fromaddr,content,subject).then ((data)=>{

                    // console.log(message)
                    successful_click_count++
                    alert (successMessage)
                    
                    
                }).catch ((error)=>{

                    alert (errorMessage)
                    // console.log ("erroir " + error)
                })
            }else {

                alert (errorMessage)
            }
        }).catch ((error)=>{
            // console.log ("success :"+ error)
            // this.setState({api_wakeup:false})
            serverAwake=false
            // console.log ("serverAwake :" + serverAwake )
        })

        

        // axios.post (url +api+header + '/sendemailpost', {},{params:data}).then((infor)=>{

        //     if (infor.data.status!=200){
        //         alert ("There is an error sending the form. Please use the email instead. - " + infor.data.status)
        //     }else{

        //         alert ("Thank you for submiting the cards.")
               
        //     }

         
        // }).catch ((err)=>{

        //     alert ("There is an error sending the form. Please inform the counsellor your cards instead." )
        // })

       

    }
    
    /*
    function detectServer(){

        SMTP.detectWakeUp().then((data)=>{

            // console.log ("success :"+ data)
            // this.setState({api_wakeup:true})
            serverAwake=true
            // console.log ("serverAwake :" + serverAwake )
        }).catch ((error)=>{
            // console.log ("success :"+ error)
            // this.setState({api_wakeup:false})
            serverAwake=false
            // console.log ("serverAwake :" + serverAwake )
        })
    }
    */
   
    // function   detectWakeUp(){

    //     const api = '/1.0'
    //     const header = '/therapy'
    //     const url = process.env.REACT_APP_CONTACTFORM_URL

    //     axios.get(url +api+header + '/wakeup' ).then ((info)=>{



    //         return true
    //     }).catch((error)=>{

   
    //         return false
    //     })

        
    // }

    function checkUser (){

        
        var contentResult = getContentResult()
        var displayButton=false

        if (contentResult===""){
            displayButton=false

        }else {

            displayButton=true
        }

        return displayButton
    }

    function CreateTable (){

        /*
            5 X 20 
        */

     
        
        var mod5=0;
        var mod4=0;
        var colorLayer = 0
        var lastcellcolor="ODD" ;

        var index = 0;

        
        var table = tabledata.map((celldata)=>{
            
            var tempdata=""
            
            if (Mod5Row(mod5++)){
                tempdata = (
                    
                    <tr key={mod5}>
                       
                    </tr>
                )


            }
            else {

                var cellColor ;

   
                if (valuestate[celldata]){
                    
                    cellColor=selectCellColor
                    mod4++

                    if ((colorLayer>0) && (colorLayer<5)){
                        colorLayer++
                    }else if (Mod5Row(mod5)){
                        colorLayer=1
                    }
                }else {

                    mod4++

                    if ((colorLayer>0) && (colorLayer<5)){
                        cellColor = evenCellColor
                        colorLayer++
                    }else if (Mod5Row(mod5)){

                        cellColor = oddCellColor
  
                        

                        colorLayer=1
                    }else {
                        cellColor = oddCellColor
                    }

                }


               tempdata =  (

                    

                    
                    <td className={cellColor}  data-attribute={celldata} key={celldata + Random.randomInteger()} onClick={()=>{SelectCell(celldata)}}><p data-tip={descriptiondata[index]}>{celldata} </p></td>
                    
                )
            }
            
            index++
            return tempdata
        })

        return (
            <div>
                <table className={classTable.table} id="characteristic" data-attribute="table">
                    <tbody data-attribute="body">
                        {table}
                    </tbody>
                </table>
                <ReactTooltip/>
                

            </div>
            
        )


    }

    return (

        <div >

            

            <div className={classTable.centertable}  >
                {CreateTable()}
                
            </div>
            <br/><br/><br/><br/>

            <div>
                {(checkUser() && !hideBUtton)?
                        <div data-attribute="button">
                        <Button title="Submit" type="FORM" click = {btnSubmit.bind(this)} />
                        
                        </div>
                    :

                        null

                }
                
            </div>


            <br/><br/><br/>
         
            {/* {detectServer()} */}
        </div>
    )
}

