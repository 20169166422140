
import TableGenerate from './generatetable/generatetable'


import Values from './data/value_data'


import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import classNames from 'classnames'


import classes from '../../../css/main.module.css'


function PageContent(){

    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title , classes.center)
    const subheading = classNames (classes.subheadingcontent,  classes.defaultfont, classes.center)
    var emailsubject= "Value Life"
    return(

        <div className={classes.divcontentblock}>

            <div className={contentClass}>

                <p className={titleClass} data-attribute="title">Your Values </p>
                <br/><br/>
                <p className={subheading} data-attribute="subheading">Please adhere to instruction on how to use this page.</p>
                <TableGenerate datasource={Values.listValueText} title={"hello"} datastate={Values.statedata} datatooltip={Values.listDescText} emailsubject={emailsubject}/>

            </div>

        </div>
    )

}


const Strength =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}

export default Strength