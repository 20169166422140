import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/sayno_logo.jpeg'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function PageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt=""></img>
                    <p className={creditClass}> Image Source : Unknown </p>
                    
                    <p className={titleClass} > Why And How We Should Learn To Say No</p>

                    <br/><br/><br/>
                  
                   
                    <p>Are you a people pleaser ? Do you find it difficult to say “No” or reject an invitation, task or someone’s request ?</p>
                    <br/>
                    <p>The inability to say no often stems from our childhood where we did not feel that we could get love simply by being ourselves, despite our best intentions, such as from our parents, 
                        grandparents or caretakers. 
                        We develop a feeling that we had to conform or perform to earn their affections. However, parenting is only one of the factors. Other factor may include social and culture.</p>
                    <br/>
                    <p>We look at these 2 influences on how they impact us.</p>
                    <br/>
                    <p className={subheading}>A. Upbringing Influences</p>
                    <br/>
                    <p>Here may be some of the parenting styles that may influence a child to be a people pleaser when he/she grows up:</p>
                    <p  className={classes.bulletpoints}>1. Strict parenting to ensure the child conforms to parents’ wishes. The child learns to say “Yes” only.</p>  
                    <p  className={classes.bulletpoints}>2. Mixed parenting messages, like one moment is lenient and the next is conformity, confuse the child and the child yearns more attention from his/her parents.</p> 
                    <p  className={classes.bulletpoints}>3. Uninvolved parenting where there is a problem with the work, relationship or certain mental health issues such as depression. This impacts the child in being the “solution” in the family where the parents are lacking of.</p> 
                    <p  className={classes.bulletpoints}>4. Insecure parenting where parents do not love themselves and use the child to make them feel good. The child is pressurised to make the parent feel good.</p>  
                    <br/>
                    <p className={subheading}>B. Social and Cultural Influences</p>
                    <br/>
                    <p>Beside upbringing, we may also be influenced by social and cultural factors. They are</p>
                    <p  className={classes.bulletpoints}>1. You want to demonstrate commitment or your worth in a task.</p>  
                    <p  className={classes.bulletpoints}>2. Saying ‘No’ in your culture is consider rude.</p> 
                    <p  className={classes.bulletpoints}>3. You want to be acceptable as a team player, not to be seen as defiant in a group.</p> 
                    <p  className={classes.bulletpoints}>4. You are worried that it may impact our impressions or prospects within the organisation or relationship. You do not want to be seen as a difficult person.</p>  
                    <p  className={classes.bulletpoints}>5. You do not want to feel guilty by rejecting someone.</p>  
                    <p  className={classes.bulletpoints}>6. Saying ‘No’ in your culture is consider rude.</p> 
                    <p  className={classes.bulletpoints}>7. You are concern that you may be hurting the person’s feelings.</p> 
                    <p  className={classes.bulletpoints}>8. Saying ‘Yes’ comes naturally to you.</p>  
                    <p  className={classes.bulletpoints}>9. You want to return a favour or committed to return one.</p> 
                    <p  className={classes.bulletpoints}>10. You are afraid of bigger uncertainty when you say “No”. The uncertainty of conflict.</p> 
                    <br/>
                    <p>There are many other reasons why saying 'No' tends to be difficult for some.</p>
                    <br/><br/><br/>
                    <p className={subheading}>Importance of able to say 'No'</p>
                    <p>Saying No helps us to establish healthy boundaries what others expect from us. It also enable others to have better clarity of what they can expect from us.</p>
                    <br/>
                    <p>Able to say 'No' empower us to make decision while maintaining strong relationships. It also let others know that you may need space and think about the decision carefully.</p>
                    <br/>
                    <p>There are 5 reasons why you should learn to be able to say 'No' confidently</p>
                    <br/>
                    <p  className={classes.bulletpoints}><span className={classes.italic}>1. You need to take care of yourself before taking care of others. </span>- If you continue not to set a healthy boundary, you may face a burnout. Taking care of yourselves first enable you to take care of others in longer term.</p> 
                    <p  className={classes.bulletpoints}><span className={classes.italic}>2. Your feelings and needs are important </span>- This is not to say that you can be selfish and harm other people. However, when we do not place our needs first, we will feel in conflict. It may sound selfish, but in the act of self-caring, you will be a better person.</p>  
                    <p  className={classes.bulletpoints}><span className={classes.italic}>3. By saying no, you are authentic and respectful </span>- If you are forced to say yes, you will be building resentment. By being able to say 'No' when able to, you build a healthy boundary and relationship with respect. This is also an important leadership quality where others may be inspired to model after you.</p> 
                    <p  className={classes.bulletpoints}><span className={classes.italic}>4. By giving in to demands constantly, we risk mental health </span>- When we keep giving in to demands, we will feel bad about ourselves and develop low self-esteem. This may lead to development of burnout, anxiety and depression as our personal identity erodes. Saying 'Yes' may seem to improve the relationship at first, but persistently giving in is one of the reasons that lead to fights , and breakup. This is one of the reasons for divorce and separation.</p> 
                    <p  className={classes.bulletpoints}><span className={classes.italic}>5. When saying "Yes" but you do not mean it, it will hurt relationship more </span>- When we say "Yes" but we do not mean it, our non verbal cues will say it clearly. Our behaviour such as lack of enthusiasm in working on the task, our body language showing disinterest or frustration, our facial expression indicates our displeasure. This is because we know that we are lying to ourselves.</p> 
                    <br/>
                    
                    
                    <br/><br/><br/>  
                    <p className={subheading}>Learning how to say 'No'</p>
                    <p>One common way of overcoming saying 'No' is try on low stakes situation. You can try with salesperson. Another way is to do a role play with a friend.</p>
                    <br/>
                    <p>Challenge the thinking such as asking how guilty, anxious and disappointed you may be if you do something that you do not want to do. 
                        Are you able to tolerate these negative feelings of not being yourselves? Is it worth doing the task and have those feelings?</p>
                    <br/>
                    <p>Been mindful of our feeling is crucial. One way is to stop what we are doing, and do breathing exercise. This will help to calm our mind down, regain our energies and thoughts. 
                        This need practice so that we will not be overwhelmed with the pressure of saying yes.</p>
                    <br/><br/><br/>   
                    <p className={subheading}>Is it worth to say "No" all the time ?</p> 
                    <p>This article does not insist that we should say "No" to all situations. It is not possible to do that. There are many situations that we do not have a perfect answer - a clear "Yes" or "No". 
                        We need to assess the fallout, and getting backup. We also need to look into our thoughts, 
                        feelings, and our values. It is important to talk to your friends and loved ones about the situation.</p>

                    <br/>
                    <p>And sometimes we have to say "Yes" in this aspect. It is important to observe if our thoughts and behaviours are detrimental to our health, 
                        life and choices. Moreover, choices in life seldom dictate a binary answer.</p>
                    <br/>
                    <p>If you are still struggling with the ability to say "No", and it is adversely affecting your life, you may want to speak with your local therapist. I can walk through with you.</p>
                   
                    <br/>
                    <p>I can be reach out from <a href='/contact'>here</a> if you need a counselling session. </p>
                    
                    
                    




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const DecisionFatigueArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={PageContent()}/>
        </div>
    )

}


export default DecisionFatigueArticle