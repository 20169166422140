
import React from "react";
import classes from '../../css/main.module.css'
import classNames from 'classnames'

import captionimg from './resource/instagram-160.png'



export default function SocialMedia (){

    const link_instagram = 'https://www.instagram.com/the.talk.therapy/'

    var textfont = classNames (classes.socialmediatext, classes.verticalcenter)

    function Content (){

       return (

        <div>
            
            <div className={classes.socialmediadiv}>
                <div>
                    <p className={textfont}>Follow us in</p>
                </div>
                
                <ul className={classes.ulsocialmedia}>
                    <li>
                    <a href={link_instagram} target='_blank' rel="noreferrer"><img src = {captionimg} className = {classes.imgsocialmedia} alt="" ></img></a>
                    </li>
                </ul>
            </div>
        </div>
       )

    }
    return (

        <div  data-attribute="copyright">
            {Content()}
        </div>
    )

}