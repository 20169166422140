import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'

import RegistrationForm from '../../../files/doc/Intake_Agreement_v1.2.docx'


function pageContent (){

    var subheading = classNames (classes.bioheading)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    // var signature = classNames (classes.signature)
    var countrysupport = classNames (classes.legendtext, classes.italic)

    let URL_Intake= "https://u.pcloud.link/publink/show?code=kZOkw4VZ2jBFxE3FXwblafvXpzFn3JmYdlxV"

    return(

        <div>

            <div className={classes.divcontentblock}>


                <div className = {contentClass}>

                    <br/><br/><br/>
                    
                    <p className = {titleClass} data-attribute="title">Registering For A Session</p>
                    <br/>


                    <p>The steps to register for your first session are easy. We ensure that the process is as inclusive with minimum hassles. 
                        You will only need to complete the Intake Confidentiality Agreement Form. </p>
                
                    <br/><br/>
                    <p className={subheading} data-attribute="subheading">Registration Form</p>
                    <p>All potential clients will need to complete the Intake Confidentiality Agreement Form.</p>
                    <p>This form helps us to understand your problem better before our first session. You may describe the issues and the expectations from the sessions. 
                        It helps us to assess how we can assist you.
                    </p>
                    <p>The Confidentiality Agreement is explained under <a href='/counsel/privacy'>Privacy Policy</a>. The Agreement acknowledges all discussion in sessions
                    is kept confidential. Your employer, school, partner, spouse or anyone will not know what is discussed here or even know that you have been to sessions with us.</p>
                    <br/>
                    <p> However, in return, we asked you to promise us that you <span className={classes.bold}>will not 
                    harm yourself and others </span> while you engage in the services.  </p>
                    <br/>
                    <p>Please inform us when you cannot make it for a scheduled appointment with us so that we can release the session slots for others who need these services.</p>
                    
                    <br/><br/>
                    
                    
           
                    <br/><br/>
                    <p className={subheading} data-attribute="subheading">During the Session</p>
                    <p>The camera needs to <span className={classes.underline}>turn on</span> for every online session. Non-verbal communication is essential for us to assess in every session. </p>
                    <br/>
                    <p>The sessions are usually conducted in the evenings and during lunch hours on weekdays and on Saturdays (Singapore time).</p>
                    <br/><br/>
                    <p className={subheading} data-attribute="subheading">Drop Us An Email For An Appointment</p>
                    {/* <p>To take up the session, please fill up the <a href={RegistrationForm} target="_blank" rel="noreferrer">Intake Confidentiality Agreement Form</a> and email us the form under <a href='/contact'>Contact</a>. We will reach out to you to schedule an appointment. You may refer to the <a href='/faq'>FAQ</a> for commonly asked questions.</p> */}
                    <p>To take up the session, please fill up the <a href={URL_Intake} target="_blank" rel="noreferrer">Intake Confidentiality Agreement Form</a> and email us the form under <a href='/contact'>Contact</a>. We will reach out to you to schedule an appointment. You may refer to the <a href='/faq'>FAQ</a> for commonly asked questions.</p>
                    <br/>
                    <br/>
                    <p className={countrysupport}>Residents of Singapore are given priority in this service. However, we accept clients from other countries. We operate in GMT+8 time (Western Australia, Malaysia, Hong Kong, China, Singapore, Philippine time). If you are too far from this timezone, it may not be possible to support you. Please seek help first in your local communities if you are not residing in Singapore. 
                    Our resources are limited as this is run by volunteers.</p>
                   
                    <br/>
                    
                    <br/><br/><br/><br/>    
                    {/* <p>Download <a href={RegistrationForm} target="_blank" rel="noreferrer">Intake Confidentiality Agreement Form</a></p> */}
                    <p>Download <a href={URL_Intake} target="_blank" rel="noreferrer">Intake Confidentiality Agreement Form</a></p>
                    
                    <br/><br/><br/><br/>   
                </div>
            </div>
        </div>
    )

}

export default function bio_counsel1(){


    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}
