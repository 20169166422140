
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-jeswin-thomas-1280162.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Loneliness is an unpleasant emotional response to perceived isolation. Loneliness is also described as social pain—a psychological 
                mechanism which motivates individuals to seek social connections. 
                It is often associated with an unwanted lack of connection and intimacy. </p>
            <br/><br/>
            <p> Research has shown that loneliness is common throughout society, including among people in marriages, other strong relationships, 
                and those with successful careers. Most people experience loneliness, and some feel it very often. 
                As a short-term emotion, loneliness can be beneficial; it encourages strengthening relationships. 
                On the other hand, chronic loneliness is widely considered harmful, with numerous reviews and meta-studies concluding it is a significant risk factor for poor mental and physical health outcomes.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Loneliness'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/person-sitting-on-bench-under-tree-1280162/'} imagelogo={imagelogo} credit={'Jeswin Thomas'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}