import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import srcimg from './resource/headinsand.png'

//import ArticleTemplate from '../../../containers/PageTemplate/articletemplate'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'

function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.divcontent, classes.italiccontent )
    // const subheading = classNames (classes.subheadingcontent, classes.divcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    return (

        <div  className={classes.divcontentblock}>


            <br/><br/><br/>
            {/* <div className = {classes.textcenter}> */}
            <div className={contentClass}>

                <br/><br/>
                <img src = {srcimg} className={classes.imgcaption} alt="" ></img>
                <p className={titleClass} > Existential Crisis </p>


                <p className={classes.bulletpoints} >Everyone has gone through a crisis in their life. There are three main types of crisis according to psychology studies.</p>
        
                <p  className={classes.bulletpoints}>1. Situational crisis (Example car accident , a loved one passed away, lost a job, divorce )</p>
                <p className={classes.bulletpoints} >2. Existential crisis (Example spiritual, mid life , career crisis)</p>
                <p  className={classes.bulletpoints}>3. Developmental crisis (Example from child to teenager, new parenthood, married life)</p>
                <br/>
                <p className={classes.bulletpointsTextLeft}>In this article, I will be talking about <span className = {classes.italiccontent}>Existential Crisis.</span></p>
                <br/>
                <p >Existential crises are moments when individuals question whether their lives have meaning, purpose, or value. It may be commonly, 
                    but not necessarily, tied to depression or inevitably negative speculations on purpose in life. Life seem inherently pointless, 
                    that our existence has no meaning because there are limits or boundaries on it, and that we all must die someday.</p>
                <br/>
                <p className = {classes.italiccontent}>Almost everyone goes through existential crisis at some point of their life.</p>
                <br/>

                <p className={classes.subheadingcontent}>Causes of existential crisis</p>
                <br/>
                <p className={classes.bulletpoints}>&#8226; Death of a parent or other loved one</p>
        
                <p className={classes.bulletpoints}>&#8226; Illness or health related</p>
            
                <p className={classes.bulletpoints}>&#8226; Career crisis — stuck in a career</p>
            
                <p className={classes.bulletpoints}>&#8226; Mid-life crisis</p>
            
                <p className={classes.bulletpoints}>&#8226; Age-related health or life change, such as the kids leaving home</p>
                <br/>
                <p>There are some of the symptoms of existential crisis. They are not inclusive and the symptoms may not appear all together. They may be</p>
                <br/>
                <p className={classes.bulletpoints}>&#8226; Anxiety</p>
        
                <p className={classes.bulletpoints}>&#8226; Depression</p>
            
                <p className={classes.bulletpoints}>&#8226; Despair and helplessness</p>
            
                <p className={classes.bulletpoints}>&#8226; Feel emptiness</p>

                <p className={classes.bulletpoints}>&#8226; Unfulfilling</p>
            
                <p className={classes.bulletpoints}>&#8226; Lonely / isolated</p>
                <br/>

                <p>One of the causes in existential crisis is that we have too many choices . It may stem from one’s new perception of life and existence. 
                    In existentialist philosophy, the term ‘existential crisis’ specifically relates to the crisis of the individual when they realize 
                    that they must always define their own lives through the choices they make. The existential crisis occurs when one recognises that even 
                    the decision to either refrain from action or withhold assent to a particular choice is, in itself, a choice. Existential themes largely hover around death, 
                    meaninglessness, freedom (responsibility) and isolation, which cause psychological problems and have no ultimate answers.</p>
                <br/>

                <p>Leisure is about having the freedom to choose what to do with time at one’s disposal. Many of us are not very good at making choices. 
                    We may think we are good with our time, however in many of studies shown, many of us struggle with extra time. 
                    There are exceptions — some of our fellow friends are able to find a meaningful purposes in their life with their free time. 
                    But for others, it is a problem that it gets worse, without them been aware of it. Some uses social media to engage, 
                    giving them a false sense of purposes. Those who 
                    thought that such engagements are worthwhile, begin to feel lonely and development anxieties and fears — 
                    like engrossing how many will “like” their post. </p>
                <br/>
                <p className = {classes.italiccontent}>Leisure with loneliness will result in misery and mental agony. It may lead to questioning purposes of life.</p> 
                <br/>       
                <p className={classes.subheadingcontent}>Reducing Existential Crisis Symptoms</p>
                <br/>
                <p className={classes.bulletpointsTextLeft}> There are some ways to reduce this. Namely :</p>
                <br/>
                <p className={classes.bulletpoints}>&#8226; Adjusting your view on life</p>
        
                <p className={classes.bulletpoints}>&#8226; Talking to someone</p>
            
                <p className={classes.bulletpoints}>&#8226; Practise mindfulness</p>
            
                <p className={classes.bulletpoints}>&#8226; Write a gratitude journal</p>
            
                <p className={classes.bulletpoints}>&#8226; Expand your social network</p>

                <p className={classes.bulletpoints}>&#8226; Focus your energy on your hobbies, volunteering and other matters</p>

                <br/>
                <p>The symptoms of existential crisis sometimes do go away on its own when you find ways to manage it. 
                    But if symptoms don’t go away, or if they worsen, see a psychiatrist, psychologist, or therapist. 
                    It is important that anyone have symptoms address them soon enough by seeking professional help or their support network.</p>
                <br/>


            
            </div>

            <br/><br/><br/><br/>    

            
 
    </div>
    )
}

const ExistentialArticle =()=>{


    return (
        <div>
          
            <ArticleTemplate content={pageContent()}/>
  
        </div>
    )
}

export default ExistentialArticle

