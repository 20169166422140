import React  from 'react'
import className from 'classnames'
//import classes from './input.module.css'
// import classes from '../Page/page.module.css'
import classes from '../../css/main.module.css'

const Input = props => {
    const myClassNew = className (classes.inputText, 'catchInput')

    // var [inputValue, setInputValue] = useState ({

    //     value : "",

    // })

    /*
    var onChangeInput =(event)=>{
        
        setInputValue({
            value : event.target.value
        })

        console.log ("value " + inputValue.value)

    }
    */

    return (

        <div>
            {/* <input className =  {myClassNew} title = {props.title} onChange={e=>onChangeInput(e)} data={inputValue.value} type="text" placeholder={props.title} /> */}
           {props.content ? 
            <textarea className =  {myClassNew} title = {props.title} onChange={e=>props.data(e)}  type="text" placeholder={props.title}  rows={'8'} data-attribute="textarea"/> :
            <input className =  {myClassNew} title = {props.title} onChange={e=>props.data(e)}  type="text" placeholder={props.title} data-attribute="input" />

        }
            {/* <input className =  {myClassNew} title = {props.title} onChange={e=>onChangeInput(e)} data = {inputValue.value}  type="text" placeholder={props.title} />  */}

        </div>

    )

}

export default Input