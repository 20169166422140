import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'


function pageContent (){

    // var subheading = classNames (classes.subheadingcontent)
    // var subtitle = classNames (classes.bold, classes.subtitle)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)

    return(

        <div>
            <br/>
            {/* <div className = {classes.pagediv_counselling} > */}
            <div  className={classes.divcontentblock}>
                
        
                {/* <div className={classes.divtext} > */}
                <div className = {contentClass}>

                    <p className = {titleClass} data-attribute="title">Services</p>

                    <p className={classes.bulletpointsTextLeft}> Below are the services that are included but not limited to :</p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/grief'>Grief</a> from passing of love ones / pets</p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/anxiety'>Anxiety</a> over work, school or life</p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/suicide'>Suicidal</a> thoughts and attempts</p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/stress'>Stressful</a> events</p>
                    <p className={classes.bulletpoints}>&#8226; Low mood or <a href='/services/depression'>Depression</a></p>
                    <p className={classes.bulletpoints}>&#8226; Unable to cope with <a href='/services/loneliness'>Loneliness</a></p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/relationship'>Relationship</a> issues</p>
                    <p className={classes.bulletpoints}>&#8226; Low <a href='/services/confidence'>self-confidence</a></p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/articles/existential'>Existential</a> challenges such mid life, life transition</p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/anger'>Anger</a> management</p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/trauma'>Trauma</a></p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/career'>Career indecision</a> or professional goals</p>
                    <p className={classes.bulletpoints}>&#8226; <a href='/services/procrastination'>Procrastination</a></p>
                    <p className={classes.bulletpoints}>&#8226; Lack of <a href='/services/assertiveness'>Assertiveness</a></p>
                    <p className={classes.bulletpoints}>&#8226; Sexual/gender identity concerns</p>


                    
                    
                    <br/><br/><br/><br/>    
                    
                    

                </div>
            </div>
        </div>
    )

}

export default function Services(){

    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}
