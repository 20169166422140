
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-kat-jayne-568021.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Trauma is damage to a person's mind as a result of one or more distressing events which cause overwhelming amounts of stress that exceed the person's ability to cope or integrate the emotions involved, 
                eventually leading to serious, long-term negative consequences.</p>
            <br/><br/>
            <p>Trauma differs between individuals; according to their subjective experiences, people will react to similar traumatic events differently. 
                In other words, not all people who experience a potentially traumatic event will become psychologically traumatized.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Trauma'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/adult-alone-black-and-white-blur-568021/'} imagelogo={imagelogo} credit={'Kat Jayne'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}