import React, {  useRef} from 'react'
//import classes from './button.module.css'
//import classes from '../Page/page.module.css'
import classes from '../../css/main.module.css'



const Button = (props)=>{
    const toggleBtnRef = useRef(null);

    

    return (


        <div>
        {props.type==='FORM' ?
                <div className = {classes.formSubmitButton} onClick={props.click} > 
                    <a ref={toggleBtnRef}  > {props.title}</a>
               
        
                </div>
        :
        <div className = {classes.formCallButton} onClick={props.click}> 
            <a ref={toggleBtnRef}   > {props.title}</a>
   

        </div>
    }


        </div>
    )
}

export default Button