


var data = [


    "",
    "Accepting",
    "Active",
    "Adaptable",
    "Adventuresome",

    "",
    "Affectionate",
    "Affirmative",
    "Alert",
    "Alive",


    "",
    "Ambitious",
    "Anchored",
    "Assertive",
    "Assured",


    "",
    "Attentive",
    "Bold",
    "Brave",
    "Bright",


    "",
    "Capable",
    "Careful",
    "Cheerful",
    "Clever",


    "",
    "Committed",
    "Competent",
    "Concerned",
    "Confident",


    "",
    "Considerate",
    "Courageous",
    "Creative",
    "Decisive",


    "",
    "Dedicated",
    "Determined",
    "Rigid",
    "Diligent",


    "",
    "Doer",
    "Eager",
    "Earnest",
    "Effective",


    "",
    "Energetic",
    "Experienced",
    "Faithful",
    "Fearless",


    "",
    "Flexible",
    "Focused",
    "Forgiving",
    "Progressive",


    "",
    "Free",
    "Happy",
    "Healthy",
    "Hopeful",


    "",
    "Imaginative",
    "Ingenious",
    "Intelligent",
    "Knowledgeable",


    "",
    "Loving",
    "Mature",
    "Open",
    "Optimistic",


    "",
    "Orderly",
    "Organized",
    "Patient",
    "Perceptive",


    "",
    "Persevering",
    "Persistent",
    "Positive",
    "Powerful",


    "",
    "Prayerful",
    "Quick",
    "Reasonable",
    "Receptive",

    "",
    "Relaxed",
    "Reliable",
    "Resourceful",
    "Responsible",

    "",
    "Sensible",
    "Skillful",
    "Solid",
    "Spiritual",

    "",
    "Stable",
    "Steady",
    "Straight",
    "Strong",

    "",
    "Stubborn",
    "Thankful",
    "Thorough",
    "Thoughtful",

    "",
    "Tough",
    "Trusting",
    "Trustworthy",
    "Truthful",

    "",
    "Understanding",
    "Unique",
    "Unstoppable",
    "Vigorous",

    "",
    "Visionary",
    "Whole",
    "Willing",
    "Winning",


    "",
    "Wise",
    "Worthy",
    "Zealous",
    "Zestful",

    
]


module.exports.data=data