import SideBarItem from '../menu/menulist'
import ArticleItem from '../menu/articlelist'
import CounselItem from '../menu/counsellinglist'
import AboutItem from '../menu/aboutlist'

import classes from './navbar.module.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import React  from 'react'
import { Navbar,Nav,NavDropdown } from 'react-bootstrap'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
// import './nav.css'

import imglogo from '../../resources/image/logo/talktherapy2.png'


// import FAQ from './faq'
// import ContactUs from './contactform'
// import Home from './Home'

import {
    BrowserRouter as Router,

  } from "react-router-dom";


export default function MenuNavBar () {


    const items = SideBarItem()
    const articles = ArticleItem()
    const couunsel = CounselItem()
    const about= AboutItem()


    /*
    function returnItems(category, label, name, link,){

        let html = null
        items.map(({category,label,name,link,...rest})=>{

            if (category==="home" || category==="about" ||category==="faq" || category==="contact"){
                html = html +  <Nav.Link key={name} className={classes.menu} href={link} > {label}</Nav.Link>
            }

            if (category==="counselling"){
                return (
    
                    <NavDropdown className={classes.menu} title="Counselling" id="basic-nav-dropdown">
    
                    </NavDropdown>
                )
            }
   
        })
       



        // if (category==="counselling"){
        //     return (

        //         <NavDropdown className={classes.menu} title="Counselling" id="basic-nav-dropdown">

        //         </NavDropdown>
        //     )
        // }
    }

    */

    return (

        <div>

            <div className="row">
                <div className="col-md-12">
                    <Router>
                        <Navbar className= {classes.colornav}  variant="light" expand="lg" sticky="top">
                            <img className={classes.imglogo} src={imglogo} alt=""/>
                            <Navbar.Brand className={classes.navtitle} href="/">The Talk Therapy</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">

                                {items.map(({ category, label, name, link, ...rest }) => (
                                    
                                    label==="Articles"?
                                        <NavDropdown key={name} className={classes.menu} title="Articles" id="basic-nav-dropdown">

                                            {articles.map(({label,name,link,...rest})=>(

                                                <NavDropdown.Item key={name} className={classes.submenu} href={link}>{label}</NavDropdown.Item> 
                                            ))}


                                        </NavDropdown>
                                    :
                                     
                                       
                                     (
                                         label==="Pyschotherapy"?
                                            <NavDropdown key={name} className={classes.menu} title="Pyschotherapy" id="basic-nav-dropdown">

                                            {couunsel.map(({label,name,link,...rest})=>(

                                                <NavDropdown.Item key={name} className={classes.submenu} href={link}>{label}</NavDropdown.Item> 

                                            ))}
                                            </NavDropdown>
                                        :
                                            // (<Nav.Link key={name} className={classes.menu} href={link} > {label}</Nav.Link>)
                                            (
                                            label==="About"?
                                            <NavDropdown key={name} className={classes.menu} title="About" id="basic-nav-dropdown">

                                            {about.map(({label,name,link,...rest})=>(

                                                <NavDropdown.Item key={name} className={classes.submenu} href={link}>{label}</NavDropdown.Item> 

                                            ))}
                                            </NavDropdown>

                                            :
                                                (<Nav.Link key={name} className={classes.menu} href={link} > {label}</Nav.Link>)
                                            )

                                     )

            
                                ))}



                                

                                </Nav>
          
                            </Navbar.Collapse>
                        </Navbar>
                        <br />

                    </Router>
                </div>
            </div>
       
        
        
        </div>
    )

}


 // (<Nav.Link className={classes.menu} href={link} > {label}</Nav.Link>)