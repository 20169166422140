
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-nathan-cowley-897817.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Anxiety is a normal human reaction to stressful situations. Everyone will worry or feel nervous from time to time. It is a normal reaction. 
                However, for people with persistent fear and worries, their anxiety lasts much longer and worsens over time. This may lead to anxiety disorders. </p>
            <br/><br/>
            <p> Anxiety disorders can impact a person’s ability to function at school, work or in social situations. This can also interfere with relationships. </p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Anxiety'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/man-in-blue-and-brown-plaid-dress-shirt-touching-his-hair-897817/'} imagelogo={imagelogo} credit={'Nathan Cowley'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}