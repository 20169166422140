
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-git-stephen-gitau-1667849.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>People are in relationships for various reasons. 
                Most of the time, our close relationship causes us the most stress. In some cases, it affects how we feel about ourselves.</p>
            <br/><br/>
            <p> There are two sides to every relationship. 
                Understanding our role in a relationship and learning to sustain a healthy relationship are skills that take time to learn and practice.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Relationship'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/close-up-photo-of-two-person-s-holding-hands-1667849/'} imagelogo={imagelogo} credit={'Git Stephen Gitau'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}