
import React from 'react'
// import classes from '../../../../css/main.module.css'
import imagelogo from '../../../../resources/image/services/pexels-daniel-reche-1556716.jpg'

//import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate'
import ServiceIndividual from '../../../../containers/PageTemplate/servicetemplate_spa'

function pageContent (){

    return (

        <div>
            <p>Depression is a serious and common medical illness that negatively affects how you think, act, and feel. 
                Depression causes the feeling of sadness and loss of interest in activities that you once enjoyed. 
                It can lead to various health problems and decrease your ability to function in your normal activities.</p>
            <br/><br/>
            <p> Depression is different from sadness and grief, although they can co-exist. Sadness and grief are normal human emotions that every person will experience. 
                They usually pass with time. Depression may not, and they have an overpowering effect on a person’s life.</p>

        </div>
    )
}

export default function servicePage(){



    return (

        <div>
            {/* <p className = {titleClass}> My Title</p> */}
            <ServiceIndividual title={'Low Mood / Depression'} content ={pageContent()} urlcredit={'https://www.pexels.com/photo/monochrome-photo-of-man-covering-his-face-1556716/'} imagelogo={imagelogo} credit={'Daniel Reche'}/>
            
            <br/><br/><br/>
            {/* <p >{props.text}</p> */}

        </div>
    )
}