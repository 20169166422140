import React from 'react'
//import classes from './articles.module.css'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
import captionimg from './resource/manboxlogo.jpeg'
import img_mencan from './resource/mencan.jpeg'
import ArticleTemplate from '../../../containers/PageTemplate/basetemplate_spa'



function pageContent (){

    var titleClass = classNames(classes.title , classes.center)
    // var contentClass = classNames (classes.div_articlecontent, classes.defaultfont)
    // var italiccontentClass = classNames (classes.italiccontent )
    const subheading = classNames (classes.subheadingcontent, classes.divcontent, classes.defaultfont)
    var creditClass = classNames(classes.creditcontent)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var bulletitalicClass =classNames(classes.italic, classes.bulletpoints)


    return (


            <div  className={classes.divcontentblock}>

                

                {/* <div className = {classes.textcenter}> */}
                <div  className = {contentClass}>
                    <br/><br/>
                    <img src = {captionimg} className = {classes.imgcaption} alt="caption"></img>
                    <p className={creditClass}> Image Source <a href='https://unsplash.com/photos/8B_DYG7Iaa4' target='_blank' rel='noreferrer'>Craig McLachlan</a> </p>
                    
                    <p className={titleClass} > Man Box  </p>

                    <br/><br/><br/>
                    <img src = {img_mencan} className = {classes.imgcaption} alt="caption"></img>
                    <p className={creditClass}> Image Credit : @gmf.designs</p>
                   <br/><br/><br/>
                   <p className={subheading}>What is a Man Box ?</p>
                    <p>A “Man Box” is a narrow set of traditional rules / expectations from the collective society how a male should 
                        behave in a “manly” behaviour such as being masculine, not crying, bullying or shaming. Young 
                        men in “Man Box” are more likely than other men to suffer from depression and suicides. 
                        Researchers reported in a study that 44% of those inside the box had thoughts of suicide within
                         a 2-week period , compare to 22% outside of it. In an Australian study, those who conform to 
                         traditional definitions of manhood are more likely to commit acts of violence to themselves, 
                         online bullying and sexual harassment. And often women are at the receiving end of this deplorable behaviour. 
                         Sometimes not acknowledging mental illness is an acceptable belief in a “Man Box” as a male “
                         need to tough” it out.</p>
                    <br/>

                    <p className={classes.bulletpoints}>Examples of "Man Box"​ behaviour</p>
                    <p  className={classes.bulletpoints}>1. Women are objects and property of men, and have less value than men</p>  
                    <p  className={classes.bulletpoints}>2. Boys and men should not cry or express emotion openly</p>   
                    <p  className={classes.bulletpoints}>3. Boys and men should not “act like a girl”</p>
                    <p  className={classes.bulletpoints}>4. Boys and men should be decisive and not to ask for, offer or accept help</p>
                    <p  className={classes.bulletpoints}>5. Act tough. Aggression equals to dominance</p>
                    <p  className={classes.bulletpoints}>6. Man brings bacon home, woman is housewife and looks after the children</p>
                    <p  className={classes.bulletpoints}>7. Heterosexuality and homophobia. A gay guy is not a “real man”</p>
                    <p  className={classes.bulletpoints}>8. Self-sufficient - Be sole breadwinner</p>
                    <br/><br/><br/>
                    <p className={bulletitalicClass}>A man, like a woman, </p>
          

                    <p  className={classes.bulletpoints}>1. Can have weakness and can admit he has weakness without fear of being judge by himself</p> 
                    <p  className={classes.bulletpoints}>2. Do not necessarily need to be masculine</p> 
                    <p  className={classes.bulletpoints}>3. Can cry if he feels like </p> 
                    <br/><br/>
                    <p>You do <span className={classes.bold}>NOT</span> need to be trapped in a “Man Box” . We can teach our sons it is not necessarily to be in a "Man Box"</p>
                    <br/><br/><br/>
                    <p className={subheading}>How to break out of “Man Box”</p>
                    <br/>
                    <p>Take first step to acknowledge the existence of Man Box. There are men who can not fit 
                        inside the “Man Box” and suffer emotional pain , as they believe they are failures. 
                        We also have to recognise there are different variance beliefs of “Man Box” and those who 
                        follow unwillingly the rules suffer too.</p>
                    <br/>
                    <p>The second step is to proactively challenge the beliefs that harm our life. An example is taking 
                        women as an object. We need to realise that harmful beliefs which restrict our roles, freedom 
                        and opportunities, and harm others in the way too. We need to redefined what it means when we 
                        break free from the rigid requirements of those beliefs of the “Man Box”</p>
                    <br/>
                    <p>A healthy manhood can be several of the following. These are not extensive and can lead 
                        out of Man Box when man can:</p>
                    <p  className={classes.bulletpoints}>&#8226; Allow to show emotions such as crying or laughing</p>
                    <p  className={classes.bulletpoints}>&#8226; Embrace their emotions fully</p>
                    <p  className={classes.bulletpoints}>&#8226; Validate the feelings of each others and self, that includes males</p>
                    <p  className={classes.bulletpoints}>&#8226; Never use gender base attributes to bully</p>
                    <p  className={classes.bulletpoints}>&#8226; Never use violence on any man, woman, children and non-heterosexual (example LGBTQ+)</p>
                    <p  className={classes.bulletpoints}>&#8226; Never use language to degenerate girls and women</p>
                    <p  className={classes.bulletpoints}>&#8226; Never treat women and girls as objects especially as sex objects</p>
                    <p  className={classes.bulletpoints}>&#8226; Have interest in women and girls outside of sexual conquest</p>
                    <p  className={classes.bulletpoints}>&#8226; Value the lives of women, men, girls, boys and non-heterosexual treating them equally</p>
                    <p  className={classes.bulletpoints}>&#8226; Model after healthy values of men, women and non-heterosexual</p>
                    <p  className={classes.bulletpoints}>&#8226; Be a voice in healthy manhood, using various platforms to positively influence community and family around him</p>
                    <br/><br/>
                    <p>By breaking out of “Man Box”, male-identified people become healthier and help to sustain equitable communities. 
                        By doing so, we benefit as a more resilient individuals.</p>
                    <br/><br/><br/>
                    <p>Help your child or teenager to be mentally healthy and resilient, by correcting these misconceptions. If you are struggling with mental health, feel free 
                        to <a href="/contact">contact</a> us for an appointment.</p>
                    
                    




                </div>

                

                <br/><br/><br/><br/>    

            </div>

            
    )

}

const ByStanderEffectArticle =()=>{



    return (

        <div>

            <ArticleTemplate content={pageContent()}/>
        </div>
    )

}


export default ByStanderEffectArticle