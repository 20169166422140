
function AboutItemList(){

    const items = [
      { name: 'choon boon', label: 'Choon Boon', link : "/about/choonboon" },
      { name: 'jonathon', label: 'Jonathon', link : "/about/jonathon" },
      { name: 'marie', label: 'Marie (Intern)', link : "/about/marie" },
      { name: 'matt', label: 'Matt', link: "/about/matt"},

      

      
    ]
      return items
  }


export default  AboutItemList