import React from 'react'
import classes from '../../../css/main.module.css'
import classNames from 'classnames'
//import BasePageTemplate from '../../../containers/PageTemplate/basetemplate'
import BasePageTemplate from '../../../containers/PageTemplate/basetemplate_spa'
import TextTime from '../../../containers/TransitText/texttransition'

import CallMe from '../../CallMe/callme'

function pageContent (){

    var testimonialText = classNames(classes.testimonials ,classes.italic)
    var subheading = classNames (classes.bioheading)
    var contentClass = classNames (classes.divcontent, classes.defaultfont)
    var titleClass = classNames(classes.title)
    var signature = classNames (classes.signature)
    var testimoniallegend = classNames (classes.legendtext)



    return(

        <div className={classes.divcontentblock}>

            <div className = {contentClass}>
                <br/><br/><br/>
                


                <div>
                    <p className = {titleClass} data-attribute="title">Choon Boon</p>
                    <br/>


                    <p>Having been in the mental health profession for over ten years, Choon Boon has extensive experience working with clients on stress, emotions and relationship issues. 
                        His previous experience as a teacher in Malaysia helps him to empathise with clients non-judgemental. 
                    </p>
                    <br/>
                    <p>
                    He believes that his clients are the true experts of their own 
                    lives and guides them through their strength, wisdom towards self-discovery, meaningful interpersonal relationships and self-acceptance. 

                    </p>
                    <br/>
                    <p>
                    His approach is person-centred and eclectic, and he employs a variety of guided imagery and meditation. Choon Boon utilises tools such as narrative therapy and provides a safe environment for his clients to work with who are struggling with their thoughts and emotions.

                    </p>
                    <br/>
                    <p>
                    Choon Boon has extensively worked with adolescents, adults and children. 
                    He is certified in mindfulness and trained in Acceptance Commitment Therapy.

                    </p>
                    <br/>
                    <p>
                    Choon Boon has been a counsellor since 2015. He has a multifaceted perspective on mental health issues. 
                    He seeks to provide his clients with a non-judgemental narrative session so their stories and feelings are heard and validated.
                    </p>
                    <br/><br/>
                    <p className={subheading}>Personal Life</p>
                    <p>Choon Boon has been happily married for 40 years with two children. He spent his free time with his grandchildren, and he conducted meditation and QiGong.</p>
                   
                    <br/>
                    <div>
                        <p className={signature}>Choon Boon</p>
                        <br/>
                    </div>
                    <div>
                        <br/><br/>
                        <CallMe/>

                    </div>

                    <br/><br/><br/><br/>    

                </div>
            </div>
        </div>
    )

}

export default function bio_counsel1(){


    return (
        <div>
            <BasePageTemplate content={pageContent()}/>

        </div>

    )

}
