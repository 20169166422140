
import {Link} from 'react-router-dom'
import classes from '../../css/main.module.css'
import SideBarItem from '../../containers/menu/footerlist'

const Footer =()=>{

    const items = SideBarItem()
    return (

        <div className={classes.footerwordspace}>
            


            <p className={classes.footerlink}>

            {items.map(({ label, name, link, ...rest }) => (
                
                <Link key = {label} to ={link}>{label}<br/></Link>

          ))}

            </p>

            
        </div>
    )
}


export default  Footer